@import scss/variables

// desktop version
.job
  background-color: $main-black

.boxjob
  padding: 7.97vw 10.53vw 8.44vw 18.54vw

  header
    border-bottom: 0.05vw solid $gray3
    .h1
      text-transform: uppercase
      font-family: $montserrat
      font-size: 3.75vw
      font-weight: 900
      line-height: 3.75vw
      color: $main-white
      text-align: left
      letter-spacing: 0.1vw
      margin-top: 3.2vw
      margin-bottom: 0.75vw
    p
      font-family: $jetBrains
      font-size: 1.04vw
      font-weight: 300
      line-height: 1.25vw
      color: $main-blue
      padding-bottom: 1.56vw
      letter-spacing: 0.05vw

  main
    display: flex
    padding-top: 1.72vw

  .cherishdev p
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    letter-spacing: 0.01em
    line-height: 1.30vw
    color: $gray5
    padding-bottom: 2.08vw

  .cherishdev
    .h2
      font-family: $jetBrains
      font-size: 1.46vw
      font-weight: 500
      line-height: 1.77vw
      color: $main-white
      letter-spacing: 0.05vw
      margin: 0
      margin-bottom: 0.83vw

    .list
      margin-bottom: 2.08vw

    .part
      display: flex
      p
        font-family: $jetBrains
        font-size: 0.97vw
        font-weight: 200
        color: $gray5
        line-height: 1.30vw
        letter-spacing: 0.01em
        padding-bottom: 0.41vw

      .listStyle
        color: $main-blue
        margin-right: 0.83vw

  .requiries
    position: relative
    margin-right: 15.83vw
    ul
      padding-bottom: 1.21vw
    li
      font-family: $jetBrains
      font-size: 0.83vw
      font-weight: 300
      line-height: 1.14vw
      color: $main-white
      letter-spacing: 0.05vw
      width: 8.23vw
      padding-bottom: 0.21vw
  .location li
    &:first-child
      color: $gray4
  .experience li
    &:first-child
      color: $gray4
  .skills li
    &:first-child
      color: $gray4
.form
  display: flex
  padding-top: 8.49vw
  .formTitle
    width: 13.59vw
    margin-right: 10.47vw
    .h3
      font-family: $montserrat
      font-weight: 900
      font-size: 3.75vw
      letter-spacing: 0.10vw
      line-height: 3.75vw
      text-transform: uppercase
      color: $main-black
      text-shadow: $borderTextWhite
      margin: 0
    .h2
      font-family: $montserrat
      font-weight: 900
      color: $main-white
      font-size: 5.73vw
      text-transform: uppercase
      letter-spacing: 0.31vw
      line-height: 5.73vw
      margin-top: 1.25vw

.backbuton
  position: relative

// tablet version 
@media (max-width: $desktop)
  .boxjob
    padding: 14.51vw 5.75vw 9.59vw
    margin: 0
    header
      .h1
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
        margin-top: 8.03vw
        margin-bottom: 1.92vw
      p
        font-size: 2.40vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        padding-bottom: 2.88vw

    main
      padding-top: 3.0vw

      .cherishdev
        p
          font-size: 2.16vw
          line-height: 3.0vw
          padding-bottom: 4.80vw

        .h2
          font-size: 3.36vw
          line-height: 4.08vw
          letter-spacing: 0.12vw
          margin-bottom: 1.92vw

        .list
          margin-bottom: 4.80vw
          p
            font-size: 2.16vw
            line-height: 3.0vw
            padding-bottom: 0.96vw

          .listStyle
            margin-right: 1.92vw

      .requiries
        margin-right: 10.31vw
        ul
          padding-bottom: 2.88vw
          li
            font-size: 1.92vw
            line-height: 2.64vw
            letter-spacing: 0.12vw
            width: 11.39vw
            padding-bottom: 0.48vw

    .form
      flex-direction: column
      padding-top: 9.59vw
      .formTitle
        width: 79.78vw
        margin-right: 0vw
        .h3
          font-size: 8.63vw
          letter-spacing: 0.24vw
          line-height: 8.63vw
        .h2
          font-size: 10.79vw
          letter-spacing: 0.72vw
          line-height: 9.71vw
          margin-top: 2.88vw

// mobile version
@media(max-width: $tablet)
  .boxjob
    padding: 24.8vw 4.27vw 21.33vw 4.8vw
    header
      .h1
        font-size: 8.53vw
        line-height: 8.53vw
        letter-spacing: 0.53vw
        margin-top: 13.07vw
        margin-bottom: 4.27vw
      p
        font-size: 5.33vw
        line-height: 6.4vw
        padding-bottom: 12.8vw
        letter-spacing: 0.27vw

    main
      display: inline-block
      padding-top: 12.8vw

      .cherishdev
        p
          font-size: 4.27vw
          font-weight: 300
          letter-spacing: 0.27vw
          line-height: 5.87vw
          padding-bottom: 10.67vw

        .h2
          font-size: 7.47vw
          line-height: 9.07vw
          letter-spacing: 0.27vw
          margin-bottom: 4.27vw

        .list
          margin-bottom: 10.67vw
          p
            font-size: 4.27vw
            font-weight: 300
            letter-spacing: 0.27vw
            line-height: 5.87vw
            padding-bottom: 2.13vw

          .listStyle
            margin-right: 4.27vw

      .requiries
        margin-right: 0
        ul
          padding-bottom: 6.4vw
          li
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
            width: 48.13vw
            padding-bottom: 1.07vw

    .form
      padding-top: 6.4vw
      .formTitle
        width: 86.93vw
        .h3
          font-size: 8.63vw
          letter-spacing: 0.53vw
          line-height: 10.93vw
        .h2
          font-size: 12.8vw
          letter-spacing: 0.53vw
          line-height: 12.8vw
          margin-top: 2.13vw
          margin-bottom: 12.8vw
