@import ../../../scss/variables

// desktop version
.formAskBox
  display: flex
  flex-direction: column
  background-image: url('/images/imgs/formAskBg.svg')
  background-repeat: no-repeat
  background-position: center right 8.57vw
  margin-bottom: 19.48vw
  background-size: 50vw 20vw
  white-space: pre-line
  .h2
    text-transform: uppercase
    font-size: 5.73vw
    line-height: 5.73vw
    font-weight: 900
    letter-spacing: 0.31vw
    transition: all ease .2s
    color: $main-white
    margin: 1.25vw 0 0 10.52vw
  .formAskText
    display: flex
    align-items: center
    justify-content: flex-start
    padding-left: 10.52vw
    cursor: pointer
    border-top: 0.05vw solid $main-white
    border-bottom: 0.05vw solid $main-white
    transition: all ease .2s
    .h2
      color: $main-blue
      margin: 0
    svg
      margin-left: 1.25vw
      width: 4.17vw
      height: 4.17vw

    path
      transition: all ease .2s
      fill: $main-white
    &:hover
      .h2
        color: $main-white
      path
        fill: $main-blue
  p
    font-size: 2.19vw
    font-family: $jetBrains
    font-weight: 100
    line-height: 2.6vw
    width: 33.85vw
    color: $main-white
    margin: 15.1vw 0 4.58vw 18.65vw

// tablet version
@media (max-width: $desktop)
  .formAskBox
    margin-bottom: 28.06vw
    background-position: center right 17.15vw
    background-size: 40.53vw 25.18vw
    p
      font-size: 2.4vw
      font-weight: 300
      line-height: 2.88vw
      width: 34.21vw
      margin: 15.35vw 0 10.67vw 17.15vw
    .h2
      font-size: 5.87vw
      line-height: 5.87vw
      letter-spacing: 0.32vw
      margin: 0.72vw 0 0 9.67vw
    .formAskText
      padding-left: 9.67vw
      svg
        margin-left: 1.28vw
        width: 4.2vw
        height: 4.2vw

// mobile version
@media (max-width: $tablet)
  .formAskBox
    margin-bottom: 54.93vw
    background-position: center right 4.27vw
    background-size: contain
    p
      font-size: 5.33vw
      line-height: 6.4vw
      width: 91.47vw
      margin: 28vw 0 23.73vw 4.27vw
    .h2
      font-size: 9.07vw
      line-height: 9.07vw
      letter-spacing: 0.61vw
      margin: 2.13vw 0 0 4.27vw
    .formAskText
      padding-left: 4.27vw
      padding-bottom: 2.4vw
      .h2
        margin-top: 3.47vw
        width: 53.8vw
      svg
        width: 6.6vw
        height: 6.6vw
        align-self: flex-end
