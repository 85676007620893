@import ../../../scss/variables

// desktop version
.casesIndustriesTitle
  display: flex
  gap: 1.25vw
  justify-content: center
  text-shadow: $borderTextWhite
  text-transform: uppercase
  border-top: 0.05vw solid $gray3
  border-bottom: 0.05vw solid $gray3
  color: $main-black
  font-size: 5.73vw
  font-weight: 900
  line-height: 5.73vw
  letter-spacing: 0.31vw
  span
    text-shadow: none
    color: $main-white

// tablet version
@media (max-width: $desktop)
  .casesIndustriesTitle
    width: 100%
    display: inline-block
    padding-left: 5.75vw
    font-size: 5.75vw
    line-height: 5.75vw
    letter-spacing: 0.32vw

// tablet version
@media (max-width: $tablet)
  .casesIndustriesTitle
    display: flex
    flex-direction: column
    padding-left: 4.27vw
    font-size: 9.07vw
    line-height: 10.93vw
    letter-spacing: 0.53vw
    gap: 0
