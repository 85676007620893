@import ../../../scss/variables

// desktop version
.nextArrowBox
  display: flex
  flex-direction: column
  position: absolute
  right: 0
  top: 6.67vw
  width: 17.29vw
  &:hover
    .aboveText
      color: $main-blue
  .nextArrowLink
    img
      width: 10.62vw
      height: 0.83vw
  .nextImg
    position: absolute
    top: 3.65vw
    right: -11.56vw
    opacity: .6
    img
      width: 20.83vw
      height: 18.49vw

.aboveText
  font-family: $jetBrains
  font-size: 0.94vw
  font-weight: 500
  line-height: 1.09vw
  color: $main-white
.underText
  text-transform: uppercase
  font-size: 1.04vw
  font-weight: 900
  line-height: 1.04vw
  color: $main-white

.prevArrowBox
  display: flex
  text-align: right
  flex-direction: column
  position: absolute
  left: 0
  bottom: 5.21vw
  width: 17.29vw
  &:hover
    .aboveText
      color: $main-blue
  .prevImg
    position: absolute
    bottom: 0
    left: -11.56vw
    opacity: .6
    img
      width: 20.83vw
      height: 18.49vw
  .prevArrowLink
    img
      width: 10.62vw
      height: 0.83vw

// tablet version
@media (max-width: $desktop)
  .nextArrowBox
    top: 9.87vw
    width: 27.82vw
    .nextArrowLink
      img
        width: 17.02vw
        height: 1.91vw
    .nextImg
      top: 10.95vw
      right: -17.56vw
      img
        width: 28.66vw
        height: 25.42vw

  .aboveText
    font-size: 1.5vw
    line-height: 1.8vw

  .prevArrowBox
    left: 8.3vw
    bottom: 2.11vw
    width: 17.29vw
    .prevImg
      bottom: -4.1vw
      left: -27.26vw
      img
        width: 28.66vw
        height: 25.42vw
    .prevArrowLink
      img
        width: 17.02vw
        height: 1.91vw
