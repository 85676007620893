@import ../../../../scss/variables

// desktop version  
.visitBtn
  display: flex
  flex-direction: row
  color: #4EB5FF
  font-size: 1.04vw
  font-weight: 900
  line-height: 1.04vw
  margin-left: 7.18vw
  text-transform: uppercase
  transition: all ease .3s
  svg
    align-self: center
    margin-left: 0.83vw
    width: 1.25vw
    height: 1.25vw
  path
    transition: all ease .3s
  &:hover
    color: $main-white
    path
      fill: $main-white

// tablet version
@media (max-width: $desktop)
  .visitBtn
    font-size: 2.4vw
    line-height: 2.4vw
    svg
      margin-left: 1.92vw
      width: 2.88vw
      height: 2.88vw

// mobile version
@media (max-width: $tablet)
  .visitBtn
    font-size: 5.33vw
    line-height: 5.33vw
    svg
      margin-left: 4.27vw
      width: 6.4vw
      height: 6.4vw
