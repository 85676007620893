@import ../../../scss/variables

// desktop version  
.accordion__item
  padding: 0
  .h3
    font-family: $jetBrains
    font-size: 1.46vw
    font-weight: 500
    line-height: 1.77vw
    color: $main-white
    letter-spacing: 0.05vw
    margin: 0 0 0 1.25vw
  p
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    color: $gray5
    line-height: 1.30vw
    letter-spacing: 0.05vw
    margin: 0 0 1.25vw 2.26vw
    width: 65.47vw
  .accordion__title
    display: flex
    align-items: center
    border-top: 0.05vw solid $gray3
    margin: 2.92vw 0
    padding-top: 2.92vw
    cursor: pointer
    &:hover
      .accordion__plus
        .plus
          rect
            fill: $main-white

  .accordion__num
    color: $gray3
    font-size: 0.83vw
    line-height: 1.15vw
    font-weight: 300
  .accordion__plus
    margin-left: auto
    .plus
      width: 1.25vw
      height: 1.25vw

// tablet version
@media (max-width: $desktop)
  .accordion__item
    .h3
      font-size: 3.36vw
      line-height: 4.08vw
      letter-spacing: 0.12vw
      margin: 0 0 0 2.88vw
      width: 76.26vw
    p
      font-size: 2.16vw
      line-height: 2.99vw
      letter-spacing: 0.01em
      margin: 0 0 2.96vw 5.36vw
      width: 76.26vw
    .accordion__title
      align-items: flex-start
      margin: 6.71vw 0
      padding-top: 6.71vw
    .accordion__num
      font-size: 1.92vw
      line-height: 2.64vw
      padding-top: 1.1vw
    .accordion__plus
      margin-left: auto
      .plus
        width: 3.72vw
        height: 3.72vw

// mobile version
@media (max-width: $tablet)
  .accordion__item
    .h3
      font-size: 5.33vw
      font-weight: 300
      line-height: 6.4vw
      letter-spacing: 0.27vw
      margin: 0 0 0 6.4vw
      width: 64.27vw
    p
      font-size: 4.8vw
      line-height: 6.67vw
      margin: 0 0 6.27vw 11.36vw
      width: 64.27vw
    .accordion__title
      margin: 10.67vw 0
      padding-top: 10.67vw
    .accordion__num
      font-size: 4.27vw
      line-height: 5.87vw
      padding-top: 0.25vw
      letter-spacing: 0.27vw
    .accordion__plus
      margin-left: auto
      .plus
        width: 8.27vw
        height: 8.27vw

// mobile version for footer accordion
@media (max-width: $tablet)
  .accordionTitleMobile
    display: flex
    width: 91.47vw
    color: $main-blackDark
    text-shadow: $borderTextWhite
    font-family: $montserrat
    font-weight: 900
    font-size: 8vw
    line-height: 9.6vw
    letter-spacing: 0.53vw
    text-transform: uppercase
    padding-top: 13.07vw

    .accordionArrowMobile
      display: flex
      align-self: center
      margin-left: auto
      position: relative
      transition: all ease .2s
      img
        width: 11.73vw
        height: 11.73vw

  .accordionBodyMobile
    ul
      li
        list-style: none
        font-family: $jetBrains
        color: $main-white
        font-weight: 400
        font-size: 6.4vw
        line-height: 6.4vw
        letter-spacing: 0.27vw
        padding-bottom: 12.8vw
        &:last-child
          padding-bottom: 0
