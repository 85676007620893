@import ../../../../scss/variables

// desktop version
.singleTitle
  text-align: right
  text-transform: uppercase
  margin: 0
  font-size: 5.73vw
  font-weight: 900
  line-height: 5.73vw
  letter-spacing: 0.3vw
  color: $main-black
  text-shadow: $borderTextWhite

// tablet version
@media (max-width: $desktop)
  .singleTitle
    font-size: 8.63vw
    line-height: 8.63vw
    letter-spacing: 0.24vw

// mobile version
@media (max-width: $tablet)
  .singleTitle
    font-size: 11.2vw
    line-height: 13.33vw
    letter-spacing: 0.53vw
