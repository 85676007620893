@import ../../../../scss/variables

// desktop version  
.modal
  position: fixed
  overflow-y: scroll
  width: 100%
  height: 100vh
  z-index: 10
  background-color: $main-black
  padding: 3.85vw 3.85vw 10.21vw 10.52vw
  top: 0
  transform: translateX(100%)
  visibility: hidden
  transition: all ease .3s
  &::-webkit-scrollbar
    width: 0
    height: 0
  .buttonClose
    background: none
    border: none
    cursor: pointer
    margin-left: 82.60vw
    rect
      transition: all ease .2s
      width: 2px
      height: 80px
    svg
      width: 3.02vw
      height: 3.07vw
      &:hover
        rect
          fill: $main-blue
  .modalText
    h1
      font-family: $montserrat
      font-weight: 900
      font-size: 3.75vw
      line-height: 3.75vw
      letter-spacing: 0.10vw
      text-transform: uppercase
      color: $main-white
      span
        color: $main-black
        text-shadow: $borderTextWhite
    p
      font-family: $jetBrains
      font-weight: 500
      font-size: 1.46vw
      line-height: 1.72vw
      letter-spacing: 0.05vw
      color: $gray4
      width: 73.02vw
      margin: 0
  .modalBlock
    display: flex
    margin-top: 3.75vw

.modalActive
  visibility: visible
  transform: translate(0)

// tablet version
@media (max-width: $desktop)
  .modal
    padding: 7.79vw 5.75vw 14.39vw 5.75vw
    height: 100vh
    .buttonClose
      svg
        width: 4.56vw
        height: 4.56vw
    .modalText
      h1
        font-size: 6.24vw
        line-height: 6.24vw
        letter-spacing: 0.17vw
        margin-bottom: 6.24vw
        width: 68.84vw
      p
        font-size: 2.4vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        margin-bottom: 8.63vw

    .modalBlock
      display: flex
      flex-direction: column-reverse

  .modalActive
    visibility: visible
    transform: translateX(0)

// mobile version
@media (max-width: $tablet)
  .modal
    padding: 6.67vw 4.27vw 30vw
    height: 100vh
    .buttonClose
      margin-left: 41.47vw
      svg
        width: 8.53vw
        height: 8.53vw
    .modalText
      h1
        font-size: 9.6vw
        line-height: 9.6vw
        letter-spacing: 0.53vw
        margin-bottom: 6.4vw
        width: 88.84vw
      p
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
        margin-bottom: 12.8vw
  .modalActive
    visibility: visible
    transform: translateX(0)
