@import ../../../scss/variables

// desktop version
.homeContainer
  padding-top: 10.42vw
  margin-bottom: 7.29vw

.mainBox
  position: relative
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  transition: all ease-out .6s

.backImg
  position: absolute
  z-index: 1
  left: calc(50% - 38.90vw/2 + 0.16vw)
  top: calc(50% - 42.7vw/2 - 1.57vw)
  img
    width: 38.33vw
    height: 33.33vw

.hiddenBackImg
  position: absolute
  z-index: 1
  left: calc(50% - 38.10vw/2 + 0.16vw)
  top: calc(50% - 42.7vw/2 - 1.57vw)
  img
    width: 38.33vw
    height: 33.33vw

.mainLinks
  h2
    position: relative
    z-index: 2
    padding: 1.15vw 0
    margin: 0
    font-size: 3.33vw
    font-weight: 900
    line-height: 3.96vw
    text-transform: uppercase
    letter-spacing: 0.16vw
    text-shadow: $borderTextWhite
    color: $main-black
    &:hover
      color: $main-white
    a
      transition: all ease-out .3s
    &:first-child
      margin-top: 8.33vw
  button
    margin: 0

.linksContent
  display: flex
  flex-direction: row

.linksTitles
  display: flex
  margin-left: 7.81vw
  flex-direction: column
  position: relative
  h2
    white-space: pre-line
  .subscriptionCard
    background-image: url('/images/imgs/CardSubscription.svg')
    background-repeat: no-repeat
    background-size: 13.54vw 14.88vw
    transform: rotate(18.6deg)
    position: absolute
    top: 27.5vw
    left: -13.51vw
    z-index: 2
    cursor: pointer
    border-radius: 20px
    &:hover
      box-shadow: 0 64px 78px rgba(0,0,0,0.25)
    .text
      font-family: $jetBrains
      color: $main-blackDark
      font-size: 0.9vw
      font-weight: 600
      line-height: 0.99vw
      letter-spacing: 0.04vw
      padding: 2.81vw 1.69vw 1.98vw 2.03vw
    .title
      font-family: $montserrat
      color: $main-blackDark
      font-size: 1.82vw
      font-weight: 800
      line-height: 2vw
      letter-spacing: 0.06vw
      text-transform: uppercase
      text-shadow: none
      padding: 0
      padding-left: 1.93vw
    .subTitle
      margin: 0
      font-family: Montserrat
      color: $main-white
      font-size: 1.04vw
      font-weight: 800
      line-height: 1.15vw
      letter-spacing: 0.04vw
      text-transform: uppercase
      padding: 2.62vw 0 1.13vw 1.82vw
      white-space: pre-line
  .subscriptionCardRu
    @extend .subscriptionCard
    top: 35.34vw
    left: -13.51vw

.btnBox
  display: flex
  max-width: 52.08vw
  align-content: center
  align-self: center
  justify-content: flex-start
  margin: 0 auto 0 49vw
  padding-top: 8vw

.mainSlogan
  visibility: visible
  transition: all ease-out .6s
  margin: 0 0 0 75.6vw
  position: absolute
  bottom: 41.5vw

.mainSloganHidden
  transition: all ease-out .6s
  opacity: 0
  margin: 0 0 0 75.6vw
  position: absolute
  bottom: 41.5vw
  visibility: hidden

.changebleText
  display: flex
  flex-direction: column
  align-items: center
  align-self: center
  font-size: 1.46vw
  font-weight: 300
  font-family: $jetBrains
  line-height: 1.72vw
  color: $main-white
  width: 8.38vw
  p
    white-space: pre-line
  .mainText
    position: absolute
    max-width: 9.27vw
    top: 16.4%
    animation: visible .6s
.mainTextRu
  @extend .mainText
  min-width: 14vw

.hiddenMainText
  position: absolute
  opacity: 0
  visibility: hidden
  top: 40%
  max-width: 9.27vw
  animation: hidden .6s

.changableListBox
  display: flex
  position: relative
  text-align: left
  width: 14.79vw
  flex-direction: column
  justify-content: center
  color: $main-white
  font-size: 0.94vw
  font-weight: 200
  font-family: $jetBrains
  line-height: 1.3vw
  margin-top: 9.6vw
  margin-left: 6.25vw
  ul
    li
      margin-bottom: 4.17vw
      &:last-child
        margin-bottom: 0
  .servicesList
    position: absolute
    top: 0
    animation: visible .6s
    li
      white-space: pre-line

.changableListBoxRu
  @extend .changableListBox
  margin-left: 7.25vw

.hiddenList
  visibility: hidden
  top: 0
  animation: hidden .6s

.hiddenText
  position: absolute
  top: 20%
  visibility: hidden
  opacity: 0
  animation: hidden .6s

@keyframes visible
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes hidden
  0%
    opacity: 1
  100%
    opacity: 0
