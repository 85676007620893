@import ../../../scss/variables

// desktop version  
.descrBox
  padding: 9.53vw 0 18.96vw 0
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: top
  background-size: contain
  .descrMainBox
    display: flex
    position: relative
    margin: 0 10.94vw 0 18.54vw
  .descrImg
    position: absolute
    left: 11%
    top: -18%
    width: 38.90vw
    height: 29.32vw
  .descrTitle
    position: relative
    z-index: 3
    text-transform: uppercase
    font-size: 5.21vw
    font-weight: 900
    line-height: 5.73vw
    letter-spacing: 0.31vw
    color: $main-white
    margin: 0
    span
      text-shadow: $borderTextWhite
      color: $main-black
  .descrContent
    position: relative
    z-index: 3
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.46vw
    color: $main-white
    line-height: 1.72vw
    letter-spacing: 0.05vw
    margin-left: 3.59vw
    margin-top: 6.25vw
    .descrText
      margin-bottom: 5.52vw
    .descr
      color: $main-blue

// tablet version
@media (max-width: $desktop)
  .descrBox
    padding: 23.74vw 0 24.38vw 0
    .descrMainBox
      margin: 0 5.87vw 0 5.75vw
      .descrImg
        position: absolute
        left: 11%
        top: -18%
        width: 70.38vw
        height: 53vw
      .descrTitle
        font-size: 7.67vw
        line-height: 9.23vw
        letter-spacing: 0.36vw
      .descrContent
        font-size: 2.4vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        margin-left: 6.95vw
        margin-top: 9.25vw
        .descrText
          margin-bottom: 5.75vw
        .descr
          width: 34.29vw

// mobile version
@media (max-width: $tablet)
  .descrBox
    padding: 45.4vw 0 21.33vw 0
    .descrMainBox
      margin: 0 4.27vw
      flex-direction: column
      .descrImg
        top: -26%
        width: 91.47vw
        height: 68.93vw
        left: 4.5%
      .descrTitle
        font-size: 11.2vw
        line-height: 11.2vw
        letter-spacing: 1.6vw
      .descrContent
        font-weight: 400
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
        margin-left: 0
        margin-top: 12.8vw
        .descrText
          margin-bottom: 12.8vw
        .descr
          width: 91.47vw
