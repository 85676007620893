@import ../../scss/variables

// desktop version    
.nav
    position: fixed
    z-index: 10
    left: 2.5vw
    top: 2.5vw
    line-height: 0.83vw
    letter-spacing: 0.05vw
    .navButton
        cursor: pointer
        font-size: 0.83vw
        font-weight: 400
        font-family: $jetBrains
        color: $main-blue
        width: fit-content
        background-color: $main-black
        text-align: center
        padding: 0.42vw 0.83vw
        border: 0.05vw solid #fff
        border-radius: 1.04vw
        transition: all ease .3s
        &:hover
            border: 0.05vw solid $main-blue
    .navMenuList
        color: $main-white
        font-size: 0.83vw
        font-weight: 400
        font-family: $jetBrains
        letter-spacing: 0.05vw
        span
            margin-right: 0.52vw
        li
            position: relative
            width: fit-content
            cursor: pointer
            margin-top: 2.08vw
            transition: all ease .3s
            &:hover
                color: $main-blueDark
    .navSubMenuList
        li
            margin: 0.83vw 0
            &:last-child
                margin-bottom: 2.08vw
.arrow
    width: 0.85vw
    height: 0.65vw
    transition: .2s ease all

// tablet version
@media (max-width: $desktop)
    .nav
        .navButton
            font-size: 1.92vw
            line-height: 1.92vw
            padding: 0.96vw 1.92vw
            border: 0.05vw solid #fff
            border-radius: 20px
