@import scss/variables

// desktop version  
.followModalForm
  display: flex
  position: relative
  flex-direction: column
  width: 46.87vw
  z-index: 1
  padding-bottom: 7.65vw

  .textarea
    width: 30.68vw
    max-width: 30.68vw

  .button
    padding-left: 33.08vw
    margin-top: 0
    z-index: -1

  .checkboxes
    display: flex
    position: absolute
    top: 18.5vw
    left: -32.1vw
    .subscribe
      margin-right: 9.13vw

  a
    color: $main-blue
    margin-left: 0.46vw
    transition: all ease .2s
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 0.73vw
    letter-spacing: 0.02vw

    &:hover
      text-decoration: underline

  span
    color: $main-white

// tablet version
@media (max-width: $desktop)
  .followModalForm
    width: 88.49vw
    padding-bottom: 11.99vw

    .textarea
      width: 51.2vw
      max-width: 51.2vw

    .button
      padding-left: 68.98vw
      margin-top: -2.04vw

    .checkboxes
      display: inline
      position: static
      .subscribe
        margin-right: 0

    a
      margin-left: 0.96vw
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw

// mobile version
@media (max-width: $tablet)
  .followModalForm
    width: 91.47vw
    padding-bottom: 45.87vw

    .textarea
      width: 91.47vw
      max-width: 91.47vw

    .button
      position: absolute
      padding: 0
      bottom: 1.56vw
      left: 23.47vw

    a
      margin-left: 2.13vw
      font-size: 3.73vw
      line-height: 5.33vw
      letter-spacing: 0.13vw
