@import ../../../../scss/variables

// desktop  version  
.card
  display: flex
  img
    align-self: flex-end
  .cardBox
    border: 0.05vw solid $main-white
    border-radius: 8%
    background-color: transparent
    min-width: 17.35vw
    height: fit-content
    position: relative
    backdrop-filter: blur(1.04vw)
    p
      font-family: $jetBrains
      font-weight: 300
      font-size: 0.81vw
      line-height: 1.15vw
      letter-spacing: 0.05vw
      color: $main-white
      padding: 1.47vw 1.2vw 1.47vw 1.47vw
      span
        color: $main-blue

// tablet version
@media (max-width: $desktop)
  .card
    .cardBox
      min-width: 24.1vw
      p
        font-size: 1.2vw
        line-height: 1.68vw
        letter-spacing: 0.03vw
        padding: 2.28vw 1.56vw 2.28vw 2.28vw

// mobile version
@media (max-width: $tablet)
  .card
    .cardBox
      min-width: 47.73vw
      width: 47.73vw
