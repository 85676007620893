@import ../../scss/variables
// desktop version
.contactsBox
  .contactBoxTitle
    position: absolute
    top: 9.76vw
    left: 53.43vw

  .contactsMainBox
    margin-left: 18.54vw
    display: flex
    flex-direction: column
    margin-top: 11.25vw

    .contactsEmail
      margin: 0
      color: $main-blue
      font-size: 3.33vw
      font-weight: 900
      font-family: $montserrat
      line-height: 3.96vw
      letter-spacing: 0.15vw
      text-transform: uppercase
      transition: all ease .3s
      border-bottom: 0.05vw solid transparent
      width: fit-content

      &:hover
        border-bottom: 0.05vw solid $main-blue

    .contactsContent
      display: flex
      flex-direction: row
      justify-content: space-between

      .contactsMainContacts
        font-family: $jetBrains
        font-size: 1.45vw
        font-weight: 500
        line-height: 1.72vw
        letter-spacing: 0.05vw
        color: $gray3

        ul
          li
            margin-bottom: 1.25vw

            span
              transition: all ease .3s
              cursor: pointer

              &:hover
                color: $main-blue

        span
          color: $main-white

        p
          margin-bottom: 1.25vw
          font-size: 0.94vw
          font-weight: 400
          line-height: 1.09vw
          letter-spacing: 0.02vw

  .contactsSubContent
    margin-top: 12.65vw

    ul
      margin-left: 68.18vw

    li
      display: inline-block
      font-size: 0.83vw
      font-weight: 400
      line-height: 0.83vw
      color: $gray3
      font-family: $jetBrains
      margin-right: 2.5vw
      letter-spacing: 0.05vw

  .underContentCaption
    margin-left: 2.5vw
    margin-top: 1.66vw
    font-size: 5.73vw
    text-transform: uppercase
    font-family: $montserrat
    font-weight: 900
    line-height: 5.73vw
    letter-spacing: 0.3vw
    color: $main-black
    text-shadow: $borderTextGray

  .contactsFormContainer
    position: relative
    display: block
    margin-top: 5.41vw
    padding-top: 9.67vw
    padding-left: 42.6vw
    overflow: hidden

    &::after
      content: ''
      display: block
      left: 14.01vw
      top: 13.18vw
      width: 34.89vw
      height: 34.89vw
      position: absolute
      background: #FFFFFF
      opacity: 0.08
      filter: blur(5.2vw)
      border-radius: 50%

    &::before
      content: ''
      display: block
      position: absolute
      background-image: url('/images/imgs/camera.png')
      background-size: 102%
      background-repeat: no-repeat
      left: 0
      top: 0
      width: 38.4vw
      height: 100%

// tablet version
@media (max-width: $desktop)
  .contactsBox
    .contactBoxTitle
      position: static
      margin: 15.35vw 5.75vw 0 0

    .contactsMainBox
      margin-left: 5.75vw
      margin-top: 5.75vw

      .contactsEmail
        font-size: 3.84vw
        line-height: 4.68vw
        letter-spacing: 0.18vw

      .contactsContent
        .contactsMainContacts
          font-size: 3.36vw
          line-height: 4.08vw
          letter-spacing: 0.12vw

          ul
            li
              margin-bottom: 2.88vw

          p
            margin-bottom: 2.88vw
            font-size: 2.16vw
            line-height: 2.64vw
            letter-spacing: 0.06vw

    .contactsSubContent
      margin-top: 16.78vw

      ul
        margin-left: 45.08vw

        li
          font-size: 1.92vw
          font-weight: 500
          line-height: 1.92vw
          margin-right: 5.75vw
          letter-spacing: 0.12vw
          &:last-child
            margin-right: 0

    .underContentCaption
      margin-left: 5.75vw
      margin-top: 3.84vw
      font-size: 5.51vw
      line-height: 5.51vw

    .contactsFormContainer
      margin-top: 8.39vw
      padding-top: 11.99vw
      padding-left: 5.75vw

      &::after
        display: none

      &::before
        display: none

// mobile version
@media(max-width: $tablet)
  .contactsBox
    .contactBoxTitle
      margin: 37.33vw 4.27vw 0 0

    .contactsMainBox
      margin-left: 4.27vw
      margin-top: 12.8vw

      .contactsEmail
        font-size: 5.87vw
        line-height: 6.93vw
        font-weight: 400
        letter-spacing: 0.27vw
        font-family: $jetBrains
        text-transform: lowercase

      .contactsContent
        .contactsMainContacts
          font-size: 7.47vw
          line-height: 9.07vw
          letter-spacing: 0.27vw

          ul
            li
              margin-bottom: 8.53vw

          p
            margin-bottom: 12.8vw
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw

    .contactsSubContent
      margin-top: 12.8vw

      ul
        margin-left: 50.4vw
        li
          display: flex
          font-size: 3.2vw
          line-height: 4.27vw
          margin-right: 0
          letter-spacing: 0.27vw
          &:last-child
            padding-left: 15.3vw

    .underContentCaption
      margin-left: 4.27vw
      margin-top: 20.8vw
      font-size: 9.6vw
      line-height: 12.57vw
      letter-spacing: 0.53vw

    .contactsFormContainer
      padding-bottom: 20vw
      padding-left: 4.27vw
