@import ../../../scss/variables

// desktop version  
.sharesBox
  display: flex
  justify-content: center
  margin: 2.5vw 0
  .sharesBoxContent
    display: flex
    flex-direction: column
    align-items: center
    .sharesArrows
      position: relative
      display: flex
      justify-content: space-between
      align-items: center
      .sharesCircle
        position: absolute
        z-index: 0
        height: 11.87vw
        width: 11.87vw
        border: 0.1vw solid $gray4
        border-radius: 50%
        top: 1.25vw
        left: 26.5%
      .sharesHr
        height: 14.37vw
        border: 0.05vw solid $gray4
        margin: 0 2.08vw
        width: 0.1vw
      .sharesArr
        position: relative
        z-index: 1

// tablet version
@media (max-width: $desktop)
  .sharesBox
    margin: 3.36vw 0
    .sharesBoxContent
      .sharesArrows
        .sharesCircle
          height: 19.66vw
          width: 19.66vw
          top: 1.85vw
          left: 27%
        .sharesHr
          height: 23.74vw
          margin: 0 1.59vw

// mobile version
@media (max-width: $tablet)
  .sharesBox
    margin: 9.6vw 0 21.33vw
    .sharesBoxContent
      .sharesArrows
        .sharesCircle
          height: 41.87vw
          width: 41.87vw
          top: 4.05vw
        .sharesHr
          height: 50.67vw
          margin: 0 3.2vw
