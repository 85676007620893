@import ../../../scss/variables

// desktop version
.benefitsBox
  display: flex
  flex-direction: column
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  margin: 0 5.52vw 11.46vw 7.92vw
  padding-top: 11.98vw
  .benefitsTitle
    h6
      text-align: left
      margin: 0 0 0 10.62vw
      font-weight: 900
      font-size: 1.87vw
      line-height: 2.24vw
      letter-spacing: 0.10vw
  .benefits
    background-image: url('/images/imgs/benefits.svg')
    height: 30.99vw
    background-size: contain
    background-repeat: no-repeat
    display: flex
    p
      min-width: 17.5vw
      max-width: 17.5vw
      font-family: $jetBrains
      font-weight: 300
      font-size: 0.83vw
      line-height: 1.15vw
      letter-spacing: 0.05vw
      color: $main-white
      margin-top: 11.56vw
      white-space: pre-line
      &:nth-child(1)
        padding-top: 3.49vw
        padding-left: 8.44vw
      &:nth-child(2)
        padding-top: 1.3vw
        padding-left: 8.44vw
      &:nth-child(3)
        padding-top: 4.95vw
        padding-left: 7.44vw
      &:nth-child(4)
        padding-left: 4.44vw
      &:nth-child(5)
        padding-top: 6.0vw
        padding-left: 2.9vw

//tablet version
@media( max-width: $desktop)
  .benefitsBox
    margin: 0 0 3.16vw -7vw
    padding-top: 24.58vw
    .benefitsTitle
      h6
        margin: 0 0 0 10.43vw
        font-size: 3vw
        line-height: 3.6vw
        letter-spacing: 0.16vw
    .benefits
      height: 57.36vw
      background-size: 109.65vw 67.36vw
      background-position-y: -9vw
      min-width: 110vw
      max-width: 110vw
      p
        max-width: 22vw
        min-width: 22vw
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.05vw
        margin-top: 16.78vw
        white-space: pre-line
        &:nth-child(1)
          padding-top: 7.64vw
          padding-left: 10.43vw
        &:nth-child(2)
          padding-top: 3.3vw
        &:nth-child(3)
          padding-top: 9.8vw
        &:nth-child(4)
          margin-left: 1vw
        &:nth-child(5)
          padding-top: 11.69vw

// mobile version
@media (max-width: $tablet)
  .benefitsBox
    margin: 0 0 21.33vw 0
    padding-top: 27.73vw
    .benefitsTitle
      h6
        margin-left: 4.27vw
        margin-bottom: 3.75vw
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
    .benefitsSlider
      width: 100%
      position: relative
      .benefitsContainer
        :global(.swiper-pagination-bullet)
          width: 2.13vw
          height: 2.13vw
        .processPaginationActive
          color: $main-blue
          background-color: $main-blue
          opacity: 1
          width: 3.2vw
          height: 3.2vw
        .parallaxBg
          position: absolute
          left: 0
          top: 0
          width: 410%
          height: 100%
          background-image: url('/images/imgs/benefits.svg')
          background-size: 480vw 149vw
          background-position-x: -40vw
          background-repeat: no-repeat
        .slide
          height: 164vw
          p
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
            margin-top: 11.56vw
            color: $main-white
            font-family: $jetBrains
            font-weight: 300
            text-align: center
            padding-top: 58.67vw
            transform: none
          .periodText
            padding-top: 50.67vw
          .stackText
            padding-top: 72vw
            white-space: pre-line
