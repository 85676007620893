//colors
$main-white: #FFF
$main-black: #252c33
$main-blackDark: #18202B
$main-blue: #4EB5FF
$main-blueDark: #179EFF

$gray0: #1F1F1F
$gray1: #373B43
$gray2: #434B54
$gray3: #687380
$gray4: #97A2B4
$gray5: #D2D9E1
$gray6: #EAEEF2

$services-mint: #63E3E3
$services-purple: #769DFF
$services-purple2: #AA8CFF

$indicators-green: #4BD37B
$indicators-red: #FB5757

//fonts
$jetBrains: 'JetBrains Mono', monospace
$montserrat: 'Montserrat', sans-serif
$roboto: 'Roboto', sans-serif

//sizes
$maxW: 70.94vw

//border text { text-shadow }
$borderTextWhite: 1px 0 1px $main-white, 0 1px 1px $main-white, -1px 0 1px $main-white, 0 -1px 1px $main-white
$borderTextBlue: 1px 0 1px $main-blue, 0 1px 1px $main-blue, -1px 0 1px $main-blue, 0 -1px 1px $main-blue
$borderTextGray: 1px 0 1px $gray3, 0 1px 1px $gray3, -1px 0 1px $gray3, 0 -1px 1px $gray3

$borderTextWhiteMobile: 0.25vw 0 0.25vw $main-white, 0 0.25vw 0.25vw $main-white, -0.25vw 0 0.25vw $main-white, 0 0.25vw 0.25vw $main-white

// breakpoints
$mobile: 375px
$tablet: 834px
$desktop: 1194px
