@import ../../../scss/variables

// desktop version  
.projectBox
  display: flex
  max-width: $maxW
  position: relative
  margin: 3.96vw auto 10.42vw auto
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: 44.27vw
  white-space: pre-line
  .projectToolsImg
    position: absolute
    right: 0
    top: 20%
    img
      width: 33.07vw
      height: 30.21vw
  .projectContent
    display: flex
    flex-direction: column
    justify-content: center
    max-width: $maxW
    h2
      text-transform: uppercase
      font-size: 3.75vw
      font-weight: 900
      line-height: 3.75vw
      letter-spacing: 0.1vw
      color: $main-white
      margin: 0 0 4.58vw 0
      span
        color: $main-black
        text-shadow: $borderTextWhite
    .projectProfile
      h3
        text-transform: uppercase
        color: $main-white
        font-size: 1.04vw
        line-height: 1.04vw
        font-weight: 900
        letter-spacing: 0.05vw
      p
        width: 31.83vw
        color: $main-white
        font-family: $jetBrains
        font-weight: 300
        font-size: 1.04vw
        line-height: 1.25vw
        letter-spacing: 0.05vw
        margin-bottom: 4.17vw
      .projectProfileList
        display: flex
        gap: 4.95vw
        ul
          li
            color: $gray4
            font-size: 0.83vw
            font-weight: 300
            font-family: $jetBrains
            line-height: 1.15vw
            margin-bottom: 1.25vw
            span
              color: $main-white
        .projectList
          display: flex
          flex-direction: row
          gap: 2.34vw
          width: 14.79vw
          .projectListFirst
            width: 6.77vw
          .projectListSecond
            width: 3.45vw
        .projectGoals
          width: 21.87vw
          li
            display: flex
            font-weight: 300
            font-size: 1.04vw
            line-height: 1.25vw
            color: $main-white
            margin: 0 0 0.42vw 0
            width: 21.09vw
            span
              color: $main-blue
              margin-right: 0.42vw
            &:first-child
              font-family: $montserrat
              text-transform: uppercase
              color: $main-white
              font-weight: 900
              font-size: 1.04vw
              line-height: 1.04vw
              margin-bottom: 0.83vw

// tablet version
@media (max-width: $desktop)
  .projectBox
    max-width: 100vw
    margin: 8.03vw 5.75vw 20.42vw
    background-size: 56.59vw
    .projectToolsImg
      position: absolute
      top: 46%
      img
        width: 56.35vw
        height: 51.44vw
    .projectContent
      h2
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
        margin: 0 0 5.99vw 0
      .projectProfile
        h3
          font-size: 2.4vw
          line-height: 2.4vw
          letter-spacing: 0.12vw
          margin: 0
          margin-bottom: 1.92vw
        p
          width: 76.74vw
          font-size: 2.4vw
          line-height: 2.88vw
          letter-spacing: 0.12vw
          margin-bottom: 5.75vw
        .projectProfileList
          gap: 3.65vw
          flex-direction: column
          ul
            li
              font-size: 1.92vw
              line-height: 2.64vw
              margin-bottom: 2.88vw
          .projectList
            gap: 2.34vw
            .projectListFirst
              width: 15.59vw
            .projectListSecond
              width: 7.09vw
          .projectGoals
            li
              font-size: 2.4vw
              line-height: 2.88vw
              margin: 0 0 0.96vw 0
              width: 28.09vw
              span
                margin-right: 0.96vw
              &:first-child
                font-size: 2.4vw
                line-height: 2.4vw
                margin-bottom: 1.92vw

// mobile version
@media (max-width: $tablet)
  .projectBox
    margin: 6.4vw 4.27vw 28.8vw
    background-size: 115.73vw
    background-position: center bottom
    display: block
    .projectToolsImg
      position: static
      img
        width: 91.2vw
        height: 83.47vw
    .projectContent
      h2
        font-size: 8.53vw
        line-height: 10.53vw
        letter-spacing: 0.53vw
        margin: 0 0 12.8vw 0
        display: flex
        flex-direction: column
      .projectProfile
        h3
          font-size: 5.33vw
          line-height: 5.33vw
          letter-spacing: 0.27vw
          margin-bottom: 4.27vw
        p
          width: 90.93vw
          font-size: 4.27vw
          line-height: 5.87vw
          letter-spacing: 0.27vw
          margin-bottom: 12.8vw
        .projectProfileList
          gap: 9.6vw
          ul
            li
              font-size: 4.27vw
              line-height: 5.87vw
              margin-bottom: 6.4vw
          .projectList
            gap: 6.4vw
            width: 52.79vw
            .projectListFirst
              width: 34.67vw
            .projectListSecond
              width: 17.67vw
          .projectGoals
            margin-bottom: 12.8vw
            li
              font-size: 4.27vw
              line-height: 5.87vw
              margin: 0 0 2.13vw 0
              width: 91.09vw
              span
                margin-right: 2.13vw
              &:first-child
                font-size: 5.33vw
                line-height: 5.33vw
                margin-bottom: 4.27vw
