@import ../../../scss/variables

// desktop version  
.main
  background-color: $main-black
  .mainBox
    margin: 0 10.52vw 0 18.54vw
    padding: 7.92vw 0 9.79vw 0
    white-space: pre-line
    p
      font-family: $jetBrains
      font-size: 0.94vw
      font-weight: 200
      color: $gray5
      letter-spacing: 0.01em
      line-height: 1.30vw
      margin-bottom: 1.25vw
      span
        color: $main-blue
        padding-right: 0.83vw
  .h2
    font-family: $montserrat
    font-size: 1.87vw
    font-weight: 900
    color: $main-white
    letter-spacing: 0.1vw
    line-height: 2.24vw
    text-align: left
    text-transform: uppercase
    margin-bottom: 0.83vw
    margin-top: 4.17vw
    padding-top: 5.42vw
    border-top: 0.05vw solid $gray3
  .h3
    color: $main-white
    font-family: $jetBrains
    font-weight: 500
    font-size: 1.46vw
    letter-spacing: 0.05vw
    line-height: 1.77vw
    margin-top: 4.17vw
    padding-top: 4.17vw
    border-top: 0.05vw solid $gray3
  .h4
    color: $main-white
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.04vw
    letter-spacing: 0.05vw
    line-height: 1.25vw
  .list
    display: flex
    p
      margin-bottom: 0
  li
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    letter-spacing: 0.01em
    line-height: 1.30vw
    color: $gray5
    padding-bottom: 0.83vw
  .h5
    color: $main-white
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.04vw
    letter-spacing: 0.05vw
    line-height: 1.25vw
    margin: 0
  .paragraph
    padding-left: 1.32vw
  a
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    letter-spacing: 0.01em
    line-height: 1.30vw
    color: $main-blue
    transition: all ease .3s
    border-bottom: 0.05vw solid transparent
    margin-left: 0.42vw
    &:hover
      border-bottom: 0.05vw solid $main-blue
  .partTitle
    color: $main-white
    font-family: $jetBrains
    font-style: normal
    font-weight: 500
    font-size: 1.46vw
    letter-spacing: 0.05vw
    line-height: 1.77vw
    margin-top: 4.17vw
    padding: 0

// tablet version
@media (max-width: $desktop)
  .main
    .mainBox
      margin: 0 5.75vw 0 5.75vw
      padding: 24.94vw 0 9.59vw
      p
        font-size: 2.16vw
        line-height: 3.0vw
        margin-bottom: 1.98vw
        span
          padding-right: 1.92vw
      .h2
        font-size: 4.32vw
        letter-spacing: 0.24vw
        line-height: 5.16vw
        margin-bottom: 2.88vw
        margin-top: 7.67vw
        padding-top: 7.67vw
      .h3
        font-size: 3.36vw
        letter-spacing: 0.12vw
        line-height: 4.07vw
        margin-top: 7.67vw
        padding-top: 7.67vw
      .h4
        font-size: 2.4vw
        letter-spacing: 0.12vw
        line-height: 2.88vw
      li
        font-size: 2.16vw
        line-height: 3.0vw
        padding-bottom: 1.92vw
      .h5
        font-size: 2.40vw
        letter-spacing: 0.12vw
        line-height: 2.88vw
        padding-bottom: 0.48vw
      a
        font-size: 2.16vw
        line-height: 3.0vw
        margin-left: 1.92vw
      .partTitle
        font-size: 3.36vw
        letter-spacing: 0.12vw
        line-height: 4.08vw
        margin-top: 5.75vw
      ul
        .list
          p
            margin-bottom: 0
        .paragraph
          padding-left: 3.24vw

// mobile version
@media (max-width: $tablet)
  .main
    .mainBox
      margin: 0 4.27vw 0 4.27vw
      padding: 24.8vw 0 27.73vw
      p
        font-size: 4.27vw
        font-weight: 300
        letter-spacing: 0.27vw
        line-height: 6.4vw
        margin-bottom: 12.8vw
        span
          padding-right: 4.27vw
      .h2
        font-size: 8.53vw
        letter-spacing: 0.53vw
        line-height: 10.13vw
        margin-bottom: 4.27vw
        margin-top: 19.47vw
        padding-top: 12.8vw
      .h3
        font-size: 7.47vw
        letter-spacing: 0.27vw
        line-height: 9.07vw
        margin-top: 12.8vw
        padding-top: 12.8vw
      .h4
        font-size: 5.33vw
        letter-spacing: 0.27vw
        line-height: 6.4vw
      li
        font-size: 4.27vw
        font-weight: 300
        letter-spacing: 0.27vw
        line-height: 5.87vw
        padding-bottom: 4.27vw
      .h5
        font-size: 5.33vw
        letter-spacing: 0.27vw
        line-height: 6.4vw
        padding-bottom: 1.07vw
      a
        font-size: 4.27vw
        font-weight: 300
        letter-spacing: 0.27vw
        line-height: 5.87vw
        margin-left: 2.13vw
      .partTitle
        font-size: 7.47vw
        letter-spacing: 0.27vw
        line-height: 9.07vw
        margin-top: 12.8vw
      ul
        .list
          p
            margin-bottom: 0
        .paragraph
          padding-left: 7.2vw

// page differences cookie policy
// desktop version  
.CookieParagraph
  .h3
    font-weight: 100
    font-size: 2.19vw
    letter-spacing: 0.05vw
    margin-top: 4.17vw
    margin-bottom: 5.42vw
    border: none
    padding: 0
  .h4
    font-weight: 500
    font-size: 1.46vw
  .h5
    margin-top: 2.5vw
    margin-bottom: 0.83vw
  .section
    margin-bottom: 5.42vw
  .cookieLinks
    li
      &::before
        content: '*'
        color: $main-blue
        padding-right: 0.83vw

// tablet version
@media (max-width: $desktop)
  .CookieParagraph
    .h3
      font-size: 3.84vw
      letter-spacing: 0.09vw
      margin-top: 7.67vw
      margin-bottom: 7.67vw
    .h4
      font-size: 3.36vw
    .h5
      margin-top: 2.88vw
      margin-bottom: 1.92vw
    .section
      margin-bottom: 6.86vw
    .cookieLinks
      li
        &::before
          padding-right: 1.92vw

// mobile version
@media (max-width: $tablet)
  .CookieParagraph
    .h3
      font-size: 8.8vw
      letter-spacing: 0.27vw
      margin-top: 12.8vw
      margin-bottom: 12.8vw
    .h4
      font-size: 7.47vw
    .h5
      margin-top: 6.4vw
      margin-bottom: 4.27vw
    .section
      margin-bottom: 15.27vw
    .cookieLinks
      li
        &::before
          padding-right: 4.27vw

// page differences terms & conditions
// desktop version  
.content
  span
    font-weight: 300
    font-size: 0.83vw
    letter-spacing: 0.05vw
    color: $gray3
    line-height: 1.15vw
    padding-right: 1.25vw
  p
    padding-left: 2.34vw
.contactMail
  font-family: $jetBrains
  font-size: 0.94vw
  font-weight: 200
  color: $gray5
  letter-spacing: 0.01em
  line-height: 1.3vw
  margin-bottom: 1.25vw
  padding-left: 2.34vw

// tablet version
@media (max-width: $desktop)
  .content
    span
      font-size: 1.92vw
      letter-spacing: 0.12vw
      line-height: 2.68vw
      padding-right: 2.88vw
    p
      padding-left: 5.39vw
  .contactMail
    font-size: 2.16vw
    line-height: 3.0vw
    margin-bottom: 1.98vw
    padding-left: 5.39vw

// mobile version
@media (max-width: $tablet)
  .content
    .h3
      display: flex
      flex-direction: column
    span
      font-size: 4.27vw
      letter-spacing: 0.27vw
      line-height: 5.87vw
      padding-bottom: 6.4vw
    p
      padding-left: 0
  .contactMail
    font-size: 4.27vw
    font-weight: 300
    letter-spacing: 0.27vw
    line-height: 6.4vw
    margin-bottom: 12.8vw
    padding-left: 0
