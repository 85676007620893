@import ../../../scss/variables

.header
  position: absolute
  z-index: 1
  right: 49.17vw
  top: 2.5vw

// mobile version
@media (max-width: $tablet)
  .header
    right: 0
    left: 4.27vw
    top: 4.27vw
