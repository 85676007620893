@import ../../../scss/variables

// desktop version
.servicesTitle
  text-transform: uppercase
  word-break: break-word
  color: $main-black
  font-weight: 900
  line-height: 5.73vw
  font-size: 5.73vw
  letter-spacing: 0.31vw
  text-shadow: $borderTextWhite
  width: 41.87vw
  margin: 0
  padding-left: 0.5vw
  span
    color: $main-white
    text-shadow: none

// tablet version
@media (max-width: $desktop)
  .servicesTitle
    line-height: 5.75vw
    font-size: 5.75vw
    letter-spacing: 0.28vw
    width: 48.87vw

// mobile version
@media (max-width: $tablet)
  .servicesTitle
    line-height: 11.2vw
    font-size: 11.2vw
    letter-spacing: 1.6vw
    text-shadow: $borderTextWhiteMobile
    width: 82.57vw
