@import ../../../../scss/variables

// desktop version  
.sloganList
  li
    display: flex
    justify-content: flex-end
    margin-left: 1.41vw
    color: $gray4
    font-size: 0.83vw
    font-weight: 300
    font-family: $jetBrains
    letter-spacing: 0.05vw
    line-height: 1.15vw

// tablet version    
@media (max-width: $desktop)
  .sloganList
    li
      margin: 0
      margin-left: 2.02vw
      font-size: 1.20vw
      letter-spacing: 0.07vw
      line-height: 1.68vw

// mobile version
@media (max-width: $tablet)
  .sloganList
    display: flex
    justify-content: right
    li
      font-family: $jetBrains
      font-weight: 300
      color: $gray4
      font-size: 4.27vw
      line-height: 5.87vw
      letter-spacing: 0.27vw
      margin: 0
      margin-right: 2.02vw
      &:last-child
        margin-right: 0
