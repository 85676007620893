@import ../../../scss/variables

// desktop version
.linksList
    position: fixed
    display: flex
    flex-direction: column
    align-items: flex-end
    bottom: 2.71vw
    right: 2.24vw
    text-align: right
    li
        margin-bottom: 1.25vw
        width: fit-content
        font-size: 0.73vw
        font-weight: 400
        line-height: 1.04vw
        letter-spacing: 0.03vw
        font-family: $jetBrains
        color: $main-blue
        border-bottom: 0.05vw solid transparent
        transition: all ease .3s
        &:hover
            border-bottom: 0.05vw solid $main-blue

// tablet version
@media (max-width: $desktop)
    .linksList
        position: absolute
        bottom: 51.53vw
        right: 0
        li
            margin-bottom: 2.88vw
            font-size: 1.68vw
            line-height: 2.4vw
            letter-spacing: 0.06vw

// mobile version
@media (max-width: $tablet)
    .linksList
        position: relative
        align-items: center
        margin-bottom: 24vw
        bottom: 2.71vw
        li
            margin-bottom: 10.67vw
            font-size: 5.33vw
            line-height: 6.4vw
            letter-spacing: 0.27vw
