@import ../../../scss/variables

// desktop version
.formBookBox
  display: flex
  flex-direction: column
  justify-content: center
  background-color: $main-blue
  background-image: url('/images/imgs/FormBookBg.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  height: 56.25vw
  .formBookText
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    border-top: 0.05vw solid $main-white
    border-bottom: 0.05vw solid $main-white
    transition: all ease .2s
    width: 100%
    &:hover
      background-color: $main-white
    h2
      text-transform: uppercase
      font-size: 5.73vw
      line-height: 5.73vw
      font-weight: 900
      letter-spacing: 0.31vw
      color: $main-white
      margin: 0
      transition: all ease .2s
    svg
      margin-left: 1.25vw
      width: 4.17vw
      height: 4.17vw
    path
      transition: all ease .2s
      fill: $main-black
    &:hover
      h2
        color: $main-blue
      path
        fill: $main-blue
  p
    font-size: 2.19vw
    font-family: $jetBrains
    font-weight: 100
    line-height: 2.60vw
    width: 17.71vw
    color: $main-white
    margin: -13.28vw 0 5.79vw 18.54vw
.formBookBoxRu
  @extend .formBookBox
  background-image: url('/images/imgs/FormBookBgRu.svg')
  .formBookText
    h2
      white-space: pre-line
// tablet version
@media (max-width: $desktop)
  .formBookBox
    height: 71.94vw
    background-size: 122.94vw 71.94vw
    .formBookText
      .h2
        font-size: 5.84vw
        line-height: 5.84vw
        letter-spacing: 0.32vw
        width: 53.12vw
      svg
        width: 4.2vw
        height: 4.2vw
        align-self: flex-end
        margin-bottom: 0.35vw
    p
      font-size: 2.4vw
      font-weight: 300
      line-height: 2.88vw
      width: 19.71vw
      margin: 0.28vw 0 9.91vw 18.54vw
  .formBookBoxRu
    .formBookText
      .h2
        font-size: 5.2vw

// mobile  version
@media (max-width: $tablet)
  .formBookBox
    height: 169.33vw
    background-size: 192.94vw 111.2vw
    .formBookText
      .h2
        font-size: 9.07vw
        line-height: 9.07vw
        letter-spacing: 0.5vw
        width: 82.67vw
      svg
        width: 6.62vw
        height: 6.62vw
        margin-bottom: 1.13vw
    p
      font-size: 5.33vw
      line-height: 6.4vw
      width: 45.46vw
      margin: -53.28vw 0 27.33vw 4.27vw
  .formBookBoxRu
    @extend .formBookBox
    .formBookText
      .h2
        font-size: 8.2vw
