@import ../../../../scss/variables

// desktop version  
.mainPrimaryTitle
  text-align: left
  text-transform: uppercase
  font-size: 5.73vw
  font-weight: 900
  line-height: 5.73vw
  letter-spacing: 0.31vw
  color: $main-white
  hyphens: none
  .mainSubTitle
    color: $main-black
    text-shadow: $borderTextWhite

// tablet version
@media (max-width: $desktop)
  .mainPrimaryTitle
    font-size: 7.67vw
    line-height: 9.23vw
    letter-spacing: 0.36vw

// mobile version
@media (max-width: $tablet)
  .mainPrimaryTitle
    font-size: 11.2vw
    line-height: 11.67vw
    letter-spacing: 1.6vw
    hyphens: manual

.singleTitle
  text-align: right
  text-transform: uppercase
  margin: 0
  font-size: 110px
  font-weight: 900
  line-height: 110px
  letter-spacing: 6px
  color: $main-black
  text-shadow: $borderTextWhite
  @media (max-width: 1400px)
    font-size: 90px
    line-height: 81px
  @media (max-width: 1024px)
    font-size: 72px
    line-height: 72px
  @media (max-width: 635px)
    font-size: 42px
    line-height: 50px
    letter-spacing: 2px
    margin-bottom: 48px
