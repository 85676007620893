@import ../../../scss/variables

// desktop version
.docTitleBox
  display: flex
  position: relative
  flex-direction: row
  text-transform: uppercase
  margin-bottom: 4.16vw
  align-items: baseline
  .h1
    color: $main-white
    font-size: 3.75vw
    font-weight: 900
    line-height: 3.75vw
    letter-spacing: 0.01vw
    margin: 0
    margin-left: 18.65vw
  span
    color: $main-black
    text-shadow: $borderTextWhite

.docBox
  font-size: 0.94vw
  font-weight: 200
  font-family: $jetBrains
  color: $gray5
  line-height: 1.30vw
  letter-spacing: 0.01em
  .docLink
    width: fit-content
    transition: all ease .3s
    border-bottom: 0.05vw solid transparent
    &:hover
      border-bottom: 0.05vw solid $main-blue
  .h1
    font-size: 1.87vw
    font-weight: 900
    font-family: $montserrat
    color: $main-white
    line-height: 2.24vw
    letter-spacing: 0.1vw
  p
    margin: 0
    margin-bottom: 1.25vw
    padding: 0
  a
    color: $main-blue
    font-size: 0.83vw
    font-weight: 300
    line-height: 1.15vw
    letter-spacing: 0.05vw
    align-items: center

.docFooterBox
  margin-top: 5.42vw
  border-top: 0.05vw solid $gray3
  padding-top: 4.17vw
  .docFooterSection
    color: $gray4
    margin: 0
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    letter-spacing: 0.01em
    line-height: 1.30vw
    a
      color: $main-blue
      width: fit-content
      padding: 0
      margin: 0 0.42vw
      &:first-child
        margin-left: 0

// tablet version
@media (max-width: $desktop)
  .docTitleBox
    display: inline-block
    margin-bottom: 7.67vw
    .h1
      font-size: 7.55vw
      line-height: 6.35vw
      letter-spacing: 0.12vw
      margin: 0
      margin-top: 8.03vw

  .docBox
    font-size: 2.16vw
    line-height: 3.0vw
    letter-spacing: 0.12vw
    .h1
      font-size: 4.32vw
      line-height: 5.16vw
      letter-spacing: 0.24vw
    p
      margin: 0
      margin-bottom: 2.88vw
      padding: 0
    a
      font-size: 2.16vw
      line-height: 3.0vw
      letter-spacing: 0.12vw

  .docFooterBox
    margin-top: 7.67vw
    padding-top: 7.67vw
    .docFooterSection
      font-size: 2.16vw
      line-height: 3.0vw
      a
        margin: 0 0.96vw

// mobile version
@media (max-width: $tablet)
  .docTitleBox
    margin-bottom: 12.8vw
    .h1
      font-size: 11.2vw
      line-height: 12.72vw
      letter-spacing: 0.53vw
      max-width: 88vw
      word-wrap: break-word
      margin-top: 13.07vw

  .docBox
    font-size: 4.27vw
    font-weight: 300
    line-height: 6.4vw
    letter-spacing: 0.27vw
    .h1
      font-size: 8.53vw
      line-height: 10.13vw
      letter-spacing: 0.35vw
    p
      margin: 0
      margin-bottom: 12.8vw
      padding: 0
    a
      font-size: 4.27vw
      line-height: 6.4vw
      letter-spacing: 0.27vw

  .docFooterBox
    margin-top: 5.42vw
    border-top: 0.05vw solid $gray3
    padding-top: 4.17vw
    .docFooterSection
      display: inline
      font-size: 4.27vw
      font-weight: 300
      line-height: 6.4vw
      letter-spacing: 0.27vw
      a
        font-family: $jetBrains
        padding: 0
        margin: 0 1.07vw
