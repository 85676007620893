@import ../../../scss/variables

// desktop version  
.menuBox
  display: flex
  flex-direction: column
  justify-content: space-between
  position: fixed
  overflow-y: scroll
  width: 100%
  height: 100vh
  z-index: 10
  background-color: $main-black
  padding: 4.17vw 2.5vw 4.53vw 2.5vw
  top: 0
  left: 0
  transform: translateX(100%)
  visibility: hidden
  transition: all ease .3s
  &::-webkit-scrollbar
    width: 0
    height: 0
  .menu
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin: 0 0 14.06vw 0
    .button
      background: none
      border: none
      cursor: pointer
      rect
        transition: all ease .2s
        width: 2px
        height: 80px
      svg
        width: 3.02vw
        height: 3.02vw
        &:hover
          rect
            fill: $main-blue
    .menuServices
      font-weight: 900
      letter-spacing: 0.10vw
      line-height: 2.24vw
      font-size: 1.87vw
      text-transform: uppercase
      .h5
        color: $main-black
        text-shadow: $borderTextWhite
        margin: 0 0 1.25vw 0
        font-weight: 900
        letter-spacing: 0.10vw
        line-height: 2.24vw
        font-size: 1.87vw
      ul
        li
          color: $main-white
          transition: all ease .3s
          margin-bottom: 0.83vw
          &:last-child
            margin-bottom: 0
          &:hover
            color: $main-blue
  .navData
    color: $main-white
    font-weight: 900
    letter-spacing: 0.10vw
    line-height: 2.24vw
    font-size: 1.87vw
    text-transform: uppercase
    margin: 0 0 17.29vw 0
    ul
      flex-wrap: wrap
      width: 100%
      display: inline-flex
      gap: 5.16vw
      li
        transition: all ease .3s
        &:hover
          color: $main-blue
  .navDataRu
    @extend .navData
    margin-bottom: 14.71vw
    ul
      gap: 3.2vw
  .menuContacts
    display: flex
    justify-content: space-between
    .menuMail
      a
        font-weight: 500
        font-size: 1.46vw
        color: $main-blue
        font-family: $jetBrains
        line-height: 1.72vw
        letter-spacing: 0.10vw
        border-bottom: 0.10vw solid transparent
        transition: all ease .3s
        &:hover
          border-bottom: 0.10vw solid $main-blue
    .links
      display: inline-flex
      font-family: $jetBrains
      align-items: center
      color: $main-blue
      font-size: 1.36vw
      font-weight: 400
      line-height: 1.68vw
      letter-spacing: 0.10vw
      li
        transition: all ease .3s
        border-bottom: 0.10vw solid transparent
        margin: 0 2.28vw 0 0
        &:hover
          border-bottom: 0.10vw solid $main-blue
        &:last-child
          margin: 0

.menuActive
  transform: translateX(0)
  visibility: visible

// tablet version
@media (max-width: $desktop)
  .menuBox
    padding: 7.67vw 5.75vw 6.71vw
    .menu
      margin: 0 0 31.17vw 0
      .button
        svg
          width: 4.56vw
          height: 4.56vw
      .menuServices
        letter-spacing: 0.15vw
        line-height: 3.12vw
        font-size: 2.64vw
        .h5
          margin-bottom: 1.08vw
          letter-spacing: 0.15vw
          line-height: 3.12vw
          font-size: 2.64vw
        ul
          li
            margin-bottom: 1.08vw
    .navData
      letter-spacing: 0.15vw
      line-height: 3.12vw
      font-size: 2.64vw
      margin: 0 0 52.88vw 0
      ul
        gap: 3.96vw
        li
          margin-right: 8.63vw
    .menuContacts
      .menuMail
        a
          font-weight: 400
          font-size: 2.64vw
          line-height: 3.12vw
          letter-spacing: 0.12vw
      .links
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw
        li
          margin: 0 2.88vw 0 0
