@import ../../../scss/variables

// desktop version
.servicesMainBox
  display: flex
  position: relative
  flex-direction: column
  overflow: hidden
  height: 56.25vw
  white-space: pre-line

.servicesMainAutBg
  background-image: url('/images/imgs/AutomationLogo.svg')
  background-position: right top
  background-repeat: no-repeat
  background-size: contain

.servicesMainManualBg
  background-image: url('/images/imgs/ManualLogo.svg')
  background-position: right top
  background-repeat: no-repeat
  background-size: contain

.servicesMainConsultingBg
  background-image: url('/images/imgs/ConsultingLogo.svg')
  background-position: right top
  background-repeat: no-repeat
  background-size: contain

.servicesQaSubscriptionBg
  background-image: url('/images/imgs/SubscriptionLogo.svg')
  background-position: right top
  background-repeat: no-repeat
  background-size: contain

.servicesMain
  display: flex
  overflow: hidden
  flex-direction: column
  width: 66.2vw
  height: 36.98vw
  margin: 13.7vw auto 0 auto
  .servicesTitle
    margin: 1.98vw 0 3.96vw 0
    white-space: pre-line
  .servicesSlogan
    margin: 0 0 0 (34.58vw - 18.54vw)

.servicesPoints
  margin: 9.17vw 0 0 0
  p
    font-family: $jetBrains
    font-style: normal
    font-weight: 300
    font-size: 1.04vw
    line-height: 1.25vw
    letter-spacing: 0.05vw
    color: $main-white
  .benefitsManual
    margin-left: 5.52vw
    margin-right: 3.26vw
    background-image: url('/images/services/ManualBenefits.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 39.9vw
    display: flex
    max-width: 100vw
    p
      margin-top: 16.15vw
      max-width: 21vw
      min-width: 21vw
      white-space: pre-line
      &:nth-child(1)
        padding-left: 8.2vw
        padding-top: 1vw
      &:nth-child(2)
        padding-top: 6.07vw
        padding-left: 8.5vw
      &:nth-child(3)
        padding-left: 8.9vw
      &:nth-child(4)
        padding-top: 7.16vw
        padding-left: 10.3vw
  .benefitsConsulting
    margin-left: 5.52vw
    margin-right: 3.28vw
    background-image: url('/images/services/ConsultingBenefits.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 38.96vw
    display: flex
    max-width: 100vw
    p
      margin-top: 13.15vw
      max-width: 21vw
      min-width: 21vw
      &:nth-child(1)
        padding-left: 9.2vw
        padding-top: 3vw
      &:nth-child(2)
        padding-top: 9.07vw
        padding-left: 9.5vw
      &:nth-child(3)
        padding-left: 9.9vw
      &:nth-child(4)
        padding-top: 7.16vw
        padding-left: 10.3vw
  .benefitsAutomation
    background-image: url('/images/services/AutomationBenefits.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 45.05vw
    display: flex
    max-width: 100vw
    p
      margin-top: 16.15vw
      max-width: 20vw
      min-width: 20vw
      white-space: pre-line
      &:nth-child(1)
        padding-left: 8.2vw
      &:nth-child(2)
        padding-top: 6.07vw
        padding-left: 7.0vw
      &:nth-child(3)
        padding-left: 7.9vw
      &:nth-child(4)
        padding-top: 8.16vw
        padding-left: 6.9vw
      &:nth-child(5)
        padding-left: 6.0vw
        max-width: 22vw
  .benefitsSubscription
    margin-left: 5.52vw
    margin-right: 3.28vw
    background-image: url('/images/services/SubscriptionBenefits.svg')
    background-size: contain
    background-repeat: no-repeat
    height: 38.96vw
    display: flex
    max-width: 100vw
    p
      margin-top: 13.15vw
      max-width: 21vw
      min-width: 21vw
      &:nth-child(1)
        padding-left: 9.2vw
        padding-top: 3vw
      &:nth-child(2)
        padding-top: 9.07vw
        padding-left: 9.5vw
      &:nth-child(3)
        padding-left: 9.9vw
      &:nth-child(4)
        padding-top: 8.27vw
        padding-left: 10.3vw

.chooseUsBox
  display: flex
  margin: 0 auto
  width: 75.04vw
  flex-wrap: wrap
  margin: 15.71vw auto 6.26vw auto
  white-space: pre-line
  .chooseTitle
    text-transform: uppercase
    color: $main-black
    font-size: 4.69vw
    font-weight: 900
    line-height: 4.22vw
    letter-spacing: 0.31vw
    width: 33.13vw
    text-shadow: $borderTextWhite
    margin: -3.74vw 15.49vw 7.86vw 0
    span
      color: $main-white
      text-shadow: none
.chooseUsBoxRu
  @extend .chooseUsBox
  .chooseTitle
    width: 44.13vw
    margin: -1.7vw 4.63vw 7.86vw 0

.subscription
  padding: 11.09vw 10.1vw 10.62vw 18.54vw
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-size: 34.9vw 34.9vw
  background-position: left bottom 15.6vw
  .subscriptionTitle
    font-family: $montserrat
    font-size: 4.69vw
    font-weight: 900
    line-height: 4.22vw
    letter-spacing: 0.31vw
    text-transform: uppercase
    color: $main-white
    padding-bottom: 5.42vw
    span
      color: $main-black
      text-shadow: $borderTextWhite
  .subscriptionCards
    display: flex
    justify-content: space-between
    white-space: pre-line
    .subscriptionPrices
      width: 22.81vw
      padding: 2.19vw
      height: fit-content
      position: relative
      border-radius: 50px
      border: 1px solid $main-blue
      background: $main-black
      .star
        width: 4.26vw
        height: 4.46vw
        position: absolute
        top: -2.27vw
        right: 1.84vw
      .h3
        font-family: $montserrat
        font-size: 1.87vw
        font-weight: 900
        line-height: 2.25vw
        letter-spacing: 0.1vw
        text-transform: uppercase
        color: $main-blue
        display: flex
        flex-direction: column
        padding-bottom: 0.83vw
        border-bottom: 1px solid $main-blue
        text-align: center
      .subscriptionPoints
        display: flex
        padding-bottom: 2.5vw
        img
          width: 1.25vw
          height: 1.25vw
          margin-right: 0.83vw
          margin-top: 0.23vw
          align-self: flex-start
        p
          font-family: $jetBrains
          font-size: 1.46vw
          font-weight: 300
          line-height: 1.75vw
          letter-spacing: 0.05vw
          color: $main-white
          display: flex
          flex-direction: column
          span
            color: $main-blue
  .subscriptionRemarks
    margin-top: -2.2vw
    li
      font-family: $jetBrains
      color: $main-white
      font-size: 1.04vw
      font-weight: 300
      line-height: 1.25vw
      letter-spacing: 0.05vw
      &::before
        content: "*"
        color: $main-blue
        margin-right: 0.42vw

// tablet version
@media (max-width: $desktop)
  .servicesMainBox
    height: 70.5vw

  .servicesMainAutBg
    background-size: 46.28vw 40.29vw

  .servicesMainManualBg
    background-size: 46.28vw 40.29vw

  .servicesMainConsultingBg
    background-size: 46.28vw 40.29vw

  .servicesQaSubscriptionBg
    background-size: 46.28vw 40.29vw

  .servicesMain
    width: 68.58vw
    height: 40.29vw
    margin: 18.1vw auto 0 auto
    .servicesTitle
      margin: 8.98vw 0 5.87vw 0

  .servicesPoints
    margin: 23.74vw 0 0 0
    p
      font-size: 1.68vw
      line-height: 2.03vw
      letter-spacing: 0.06vw
    .benefitsAutomation
      margin-left: -5.21vw
      background-size: 109.65vw 67.36vw
      height: 67.36vw
      max-width: 110vw
      min-width: 110vw
      p
        margin-top: 30.15vw
        max-width: 21vw
        min-width: 21vw
        font-size: 1.6vw
        &:nth-child(1)
          padding-left: 10.2vw
        &:nth-child(2)
          padding-top: 6.07vw
          padding-left: 9.5vw
        &:nth-child(3)
          padding-left: 9.6vw
        &:nth-child(4)
          padding-top: 8.16vw
          padding-left: 9.9vw
        &:nth-child(5)
          padding-left: 8.9vw
    .benefitsConsulting
      background-size: 109.65vw 67.36vw
      height: 67.36vw
      margin-left: -3.2vw
      min-width: 110vw
      p
        margin-top: 25.15vw
        max-width: 26vw
        min-width: 26vw
        font-size: 1.7vw
        &:nth-child(1)
          padding-top: 4vw
          padding-left: 10.2vw
        &:nth-child(2)
          padding-top: 7.9vw
          padding-left: 9.4vw
        &:nth-child(3)
          padding-left: 9.0vw
        &:nth-child(4)
          padding-top: 10.16vw
          padding-left: 8.9vw
    .benefitsManual
      margin-left: -3.2vw
      margin-right: 0
      background-size: 109.65vw 67.36vw
      height: 67.36vw
      min-width: 110vw
      p
        margin-top: 25.15vw
        max-width: 26vw
        min-width: 26vw
        font-size: 1.7vw
        &:nth-child(1)
          padding-top: 4vw
          padding-left: 10.2vw
        &:nth-child(2)
          padding-top: 7.9vw
          padding-left: 9.4vw
        &:nth-child(3)
          padding-left: 9.0vw
        &:nth-child(4)
          padding-top: 10.16vw
          padding-left: 8.9vw
    .benefitsSubscription
      background-size: 109.65vw 67.36vw
      height: 67.36vw
      margin-left: -3.2vw
      min-width: 110vw
      p
        margin-top: 25.15vw
        max-width: 26vw
        min-width: 26vw
        font-size: 1.7vw
        &:nth-child(1)
          padding-top: 4vw
          padding-left: 10.2vw
        &:nth-child(2)
          padding-top: 9.9vw
          padding-left: 9.4vw
        &:nth-child(3)
          padding-left: 9.0vw
        &:nth-child(4)
          padding-top: 10.16vw
          padding-left: 8.9vw
  .chooseUsBox
    width: 100vw
    margin: 10.67vw 5.75vw 6.26vw
    .chooseTitle
      font-size: 5.75vw
      line-height: 6.35vw
      letter-spacing: 0.12vw
      width: 38.53vw
      margin: 0 16.02vw 9.71vw 0
  .subscription
    padding: 11.99vw 5.75vw 15.83vw
    background-size: 53.72vw 53.72vw
    background-position: left -7vw top 11vw
    .subscriptionTitle
      font-size: 5.75vw
      line-height: 5.75vw
      letter-spacing: 0.36vw
      padding-bottom: 8.48vw
    .subscriptionCards
      .subscriptionPrices
        width: 28.46vw
        padding: 2.73vw
        border-radius: 27px
        .star
          width: 5.56vw
          height: 5.56vw
          top: -2.8vw
          right: 2.64vw
        .h3
          font-size: 2.34vw
          line-height: 2.76vw
          letter-spacing: 0.12vw
          padding-bottom: 1.04vw
        .subscriptionPoints
          padding-bottom: 3.12vw
          img
            width: 1.56vw
            height: 1.56vw
            margin-right: 1.04vw
          p
            font-size: 1.8vw
            line-height: 2.16vw
            letter-spacing: 0.06vw
    .subscriptionRemarks
      margin-top: 0
      li
        font-size: 2.4vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        padding-bottom: 1vw
        &::before
          margin-right: 1vw

// mobile version
@media (max-width: $tablet)
  .servicesMainBox
    height: 158.13vw

  .servicesMainAutBg
    background-size: 76vw 66.13vw
    background-position: top center

  .servicesMainManualBg
    background-size: 76vw 66.13vw
    background-position: top center

  .servicesMainConsultingBg
    background-size: 76vw 66.13vw
    background-position: top center

  .servicesQaSubscriptionBg
    background-size: 76vw 66.13vw
    background-position: top center

  .servicesMain
    width: 91.47vw
    height: 136.8vw
    margin: 19.2vw auto 0 auto
    .servicesTitle
      margin: 53.98vw 0 12.8vw 0
    .servicesSlogan
      margin: 0
  .servicesPoints
    margin-top: 33.87vw
    .benefitsSlider
      width: 100%
      position: relative
      .benefitsContainer
        :global(.swiper-pagination-bullet)
          width: 2.13vw
          height: 2.13vw
        .processPaginationActive
          color: $main-blue
          background-color: $main-blue
          opacity: 1
          width: 3.2vw
          height: 3.2vw
        .parallaxBgAutomation
          position: absolute
          left: 0
          top: 0
          width: 410%
          height: 100%
          background-image: url('/images/services/AutomationBenefits.svg')
          background-size: 480vw 149vw
          background-position-x: -42vw
          background-repeat: no-repeat
        .parallaxBgConsulting
          position: absolute
          left: 0
          top: 0
          width: 410%
          height: 100%
          background-image: url('/images/services/ConsultingBenefits.svg')
          background-size: 383.2vw 156.2vw
          background-position-x: -12vw
          background-repeat: no-repeat
        .parallaxBgManual
          position: absolute
          left: 0
          top: 0
          width: 410%
          height: 100%
          background-image: url('/images/services/ManualBenefits.svg')
          background-size: 383.2vw 156.2vw
          background-position-x: -16.07vw
          background-repeat: no-repeat
        .parallaxBgSubscription
          position: absolute
          left: 0
          top: 0
          width: 410%
          height: 100%
          background-image: url('/images/services/SubscriptionBenefits.svg')
          background-size: 383.2vw 156.2vw
          background-position-x: -12vw
          background-repeat: no-repeat
        .slide
          height: 164vw
          margin-bottom: 9.67vw
          p
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
            margin-top: 11.56vw
            color: $main-white
            font-family: $jetBrains
            font-weight: 300
            text-align: center
            padding-top: 54.67vw
            transform: none
            white-space: pre-line
          .text
            padding-top: 64.29vw
          .textResources
            padding-top: 40.58vw

  .chooseUsBox
    flex-direction: column
    max-width: 90vw
    margin-top: 26.67vw
    .chooseTitle
      font-size: 9.07vw
      line-height: 9.87vw
      letter-spacing: 0.27vw
      width: 68.53vw
      margin: 0
      margin-bottom: 13.07vw
      text-shadow: $borderTextWhiteMobile

  .subscription
    padding: 21.33vw 4.27vw
    background-size: 139.2vw 139.2vw
    background-position: left -52vw bottom
    .subscriptionTitle
      display: flex
      flex-direction: column
      font-size: 10.13vw
      line-height: 10.13vw
      letter-spacing: 0.73vw
      padding-bottom: 21.33vw
    .subscriptionCards
      flex-wrap: wrap
      .subscriptionPrices
        width: 91.47vw
        padding: 8.77vw
        border-radius: 39px
        margin-bottom: 11.2vw
        .star
          width: 17.87vw
          height: 17.87vw
          top: -9.13vw
          right: 8.64vw
        .h3
          font-size: 7.47vw
          line-height: 9.02vw
          letter-spacing: 0.42vw
          padding-bottom: 3.34vw
        .subscriptionPoints
          padding-bottom: 10.24vw
          img
            width: 5.01vw
            height: 5.01vw
            margin-right: 3.34vw
          p
            font-size: 5.87vw
            line-height: 6.93vw
            letter-spacing: 0.21vw
    .subscriptionRemarks
      margin-top: 1.6vw
      li
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.27vw
        padding-bottom: 2.13vw
        &::before
          margin-right: 2.13vw
