@import ../../../../scss/variables

// desktop version
.orderButton
  border-radius: 100px
  border: 1px solid $main-blue
  background-color: transparent
  padding: 1.25vw 5.94vw
  font-family: $montserrat
  color: $main-white
  font-size: 1.04vw
  font-weight: 900
  line-height: 1.15vw
  letter-spacing: 0.05vw
  text-transform: uppercase
  cursor: pointer
  display: flex
  &:hover
    color: $main-blue
    background-color: $main-white
  img
    align-self: center
    margin-left: 0.83vw
    width: 1.25vw
    height: 1.25vw
.orderButtonRu
  @extend .orderButton
  padding: 1.25vw 4.74vw

// tablet version
@media (max-width: $desktop)
  .orderButton
    padding: 1.56vw 7.37vw
    font-size: 1.3vw
    line-height: 1.44vw
    letter-spacing: 0.06vw
    img
      margin-left: 1.04vw
      width: 1.56vw
      height: 1.56vw

// mobile version
@media (max-width: $tablet)
  .orderButton
    padding: 5.12vw 23.7vw
    font-size: 4.18vw
    line-height: 4.59vw
    letter-spacing: 0.21vw
    img
      margin-left: 3.34vw
      width: 5.01vw
      height: 5.01vw
