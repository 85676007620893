@import ../../../scss/variables

// desktop version  
.casesLineContent
  margin-bottom: 6.67vw
  width: 100vw
  ul
    display: flex
    justify-content: center
    gap: 2.08vw
    transition: all ease .3s
    margin: 0 0 2.5vw 0
    animation: scroll 6s ease-in-out infinite
    transform: translate(-10%, 0)
    &:last-child
      li
        margin: 0
    li
      width: fit-content
      padding: 1.25vw 2.5vw
      border: 0.03vw solid $main-white
      border-radius: 2.6vw
      background-color: $gray1
      color: $main-white
      font-size: 1.46vw
      font-weight: 500
      font-family: $jetBrains
      line-height: 1.77vw
      letter-spacing: 0.05vw
      white-space: nowrap
.casesLineContentRu
  @extend .casesLineContent
  ul
    li
      font-size: 1.25vw

@keyframes scroll
  0%
    transform: translate(-10%, 0)
  50%
    transform: translate(10%, 0)
  100%
    transform: translate(-10%, 0)

// tablet version
@media (max-width: $desktop)
  .casesLineContent
    margin-bottom: 8.85vw
    ul
      gap: 3.24vw
      margin: 0 0 3.24vw 0
      li
        padding: 1.8vw 3.6vw
        border: 0.32px solid $main-white
        border-radius: 3.84vw
        font-size: 2.16vw
        line-height: 2.64vw
        letter-spacing: 0.08vw

// mobile version
@media (max-width: $tablet)
  .casesLineContent
    margin-bottom: 15.68vw
    ul
      gap: 5.06vw
      margin: 0 0 6.13vw 0
      li
        padding: 2.93vw 6.03vw
        border: 0.23px solid $main-white
        border-radius: 6.13vw
        font-size: 3.47vw
        line-height: 4.27vw
        letter-spacing: 0.13vw

  @keyframes scroll
    0%
      transform: translate(-30%, 0)
    50%
      transform: translate(30%, 0)
    100%
      transform: translate(-30%, 0)
