@import ../../../scss/variables

// desktop version
.strongPointsBox
  display: flex
  flex-direction: column
  width: 100%
  justify-content: center

.strongPointsTitle
  width: 18.54vw
  text-transform: uppercase
  font-size: 1.87vw
  font-weight: 900
  line-height: 2.24vw
  letter-spacing: 0.1vw
  color: $main-white
  margin-left: 18.54vw
  span
    color: $main-black
    text-shadow: $borderTextWhite

// tablet version
@media (max-width: $desktop)
  .strongPointsTitle
    width: 28.55vw
    font-size: 3vw
    line-height: 3.6vw
    letter-spacing: 0.16vw
    margin: 0
    margin-left: 10.43vw

// mobile version
@media (max-width: $tablet)
  .strongPointsTitle
    width: 55.41vw
    font-size: 5.33vw
    line-height: 7.4vw
    letter-spacing: 0.53vw
    margin-left: 4.27vw
