@import ../../scss/variables

// desktop version
.follow
  height: 56.25vw
  background-image: url('/images/imgs/bg.svg'), url('/images/imgs/bgArticle.svg')
  background-repeat: no-repeat
  background-position: center
  background-size: contain
  .followForm
    padding-top: 19.06vw
    padding-left: 18.54vw
    background-image: url('/images/imgs/FormFollow.svg')
    background-repeat: no-repeat
    background-position: top 15.6vw right 9.17vw
    background-size: 79.06vw 19.17vw
    padding-bottom: 8.44vw
    .h2
      width: 48.19vw
      font-family: $montserrat
      font-weight: 900
      font-size: 5.73vw
      line-height: 5.73vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      color: $main-blue
      cursor: pointer
      margin: 0
      white-space: pre-line
      span
        color: $main-white
        font-weight: 700
      &:hover
        color: $main-white
      .arrow
        margin-left: 0.83vw
        width: 4.17vw
        height: 4.17vw
      &:hover
        path
          fill: $main-blue
  p
    font-family: $jetBrains
    font-weight: 100
    font-size: 2.19vw
    line-height: 2.6vw
    letter-spacing: 0.05vw
    color: $main-white
    width: 25.6vw
    margin-left: 58.7vw
.followRu
  @extend .follow
  background-image: url('/images/imgs/bg.svg'), url('/images/imgs/bgArticleRu.svg')
  h2
    white-space: pre-line

// tablet version
@media (max-width: $desktop)
  .follow
    height: 71.94vw
    background-size: 204.08vw 50.55vw
    .followForm
      padding-top: 15.47vw
      padding-left: 3.6vw
      background-position: top 23.1vw right 6.8vw
      background-size: 88.83vw 21.53vw
      padding-bottom: 8.35vw
      .h2
        width: 48.19vw
        font-size: 5.76vw
        line-height: 5.76vw
        letter-spacing: 0.31vw
        margin: 12.6vw 0 0 14.2vw
        .arrow
          margin-left: 1.27vw
          width: 4.2vw
          height: 4.2vw
    p
      font-weight: 300
      font-size: 2.28vw
      line-height: 2.76vw
      letter-spacing: 0.12vw
      margin-left: 58.7vw

// mobile version
@media (max-width: $tablet)
  .follow
    height: 169.33vw
    background-size: 449.87vw 111.2vw
    .followForm
      padding-top: 15.47vw
      padding-left: 3.6vw
      background-position: top 55.3vw right -19.9vw
      background-size: 124.5vw 30.13vw
      padding-bottom: 23.55vw
      .h2
        width: 56.19vw
        font-size: 9.07vw
        line-height: 9.07vw
        letter-spacing: 0.61vw
        margin: 41.1vw 0 0 13.34vw
        span
          font-weight: 900
        .arrow
          margin-left: 1.87vw
          width: 6.6vw
          height: 6.6vw
    p
      font-size: 5.33vw
      line-height: 6.4vw
      letter-spacing: 0.27vw
      margin-left: 37.47vw
      width: 60.6vw
