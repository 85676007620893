@import ../../../scss/variables

// desktop version
.processBox
  width: 100%
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-size: 39.24vw
  background-position: center
  padding-top: 4.17vw
  .processTitle
    margin-right: 10.52vw
    h6
      font-weight: 900
      font-size: 1.87vw
      line-height: 2.24vw
      text-align: right
      letter-spacing: 0.10vw
      margin-bottom: 0
  .processRunningLine
    overflow: hidden
    display: flex
    justify-content: space-between
    align-items: center
    font-family: $jetBrains
    font-size: 1.46vw
    font-weight: 500
    line-height: 1.72vw
    letter-spacing: 0.05vw
    color: $gray3
    margin-top: 2.60vw
    .runningLineStars
      color: $main-blue
      margin: 0 1.25vw

  .processStepsBox
    overflow: hidden
    width: 100%
    position: relative
    height: 928px
    .processSteps
      width: 2250px
      overflow: auto
      position: absolute
      left: calc(50% - 2250px/2 )
      :global(.swiper-pagination-bullet)
        width: 0.42vw
        height: 0.42vw
      .processPaginationActive
        color: $main-blue
        background-color: $main-blue
        opacity: 1
        width: 0.62vw
        height: 0.62vw

// tablet version
@media (max-width: $desktop)
  .processBox
    background-size: 101.32vw
    padding-top: 6.42vw
    .processTitle
      margin-right: 5.75vw
      h6
        font-size: 3vw
        line-height: 3.6vw
        letter-spacing: 0.17vw
        margin-bottom: 4.98vw
    .processRunningLine
      font-size: 2.16vw
      font-weight: 200
      line-height: 3vw
      letter-spacing: 0.01em
      margin-top: 2.60vw

      .runningLineStars
        margin: 0 2.88vw

    .processStepsBox
      .processSteps
        :global(.swiper-pagination-bullet)
          width: 0.96vw
          height: 0.96vw
        .processPaginationActive
          width: 1.44vw
          height: 1.44vw

// mobile version
@media (max-width: $tablet)
  .processBox
    background-size: 180.67vw
    padding-top: 9.17vw
    .processTitle
      margin-right: 4.27vw
      h6
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
        margin-bottom: 8.53vw
    .processRunningLine
      font-size: 4.8vw
      line-height: 6.67vw
      margin-top: 13.87vw
      .runningLineStars
        margin: 0 2.13vw

    .processStepsBox
      height: 600px
      .processSteps
        width: 1550px
        left: calc(50% - 1550px/2 )
        :global(.swiper-pagination-bullet)
          width: 2.13vw
          height: 2.13vw
        .processPaginationActive
          width: 3.2vw
          height: 3.2vw
        .slide
          padding-bottom: 8.5vw
