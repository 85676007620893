@import ../../../scss/variables

// desktop version
.aboutBox
  display: flex
  flex-direction: column
  width: 100%
  .aboutTitle
    h6
      text-align: left
      margin: 0 0 0 10.62vw
      font-weight: 900
      font-size: 1.87vw
      line-height: 2.24vw
      letter-spacing: 0.10vw
      margin-bottom: 3.59vw
  .aboutTextBox
    font-family: $jetBrains
    display: flex
    margin: 0 7.08vw 7.48vw 10.52vw
    .utpText
      font-size: 1.67vw
      line-height: 2vw
      letter-spacing: 0.05vw
      font-weight: 100
      color: $main-blue
      width: 21.2vw
      white-space: pre-line
      align-self: flex-start
      margin-right: 11.74vw
    .utp
      .utpTitle
        font-size: 1.67vw
        line-height: 2vw
        letter-spacing: 0.05vw
        font-weight: 100
        color: $main-white
        margin: 0
        width: 34.06vw
        margin-bottom: 4.17vw
      .utpPoints
        width: 49.56vw
        display: flex
        flex-wrap: wrap
  .staticLineBox
    margin: 0 0 2.5vw -10.42vw
    overflow: hidden
    .staticLine
      img
        width: 134.06vw
        height: 8.07vw

.aboutFoundedBox
  background-image: url('/images/imgs/blocks.png') ,url('/images/imgs/foundInBg.svg')
  background-repeat: no-repeat, no-repeat
  background-position: bottom 3.4vw left 21.3vw, center
  background-size: 62.19vw 44.9vw, cover

.aboutFoundedBoxRu
  background-image: url('/images/imgs/blocks.png') ,url('/images/imgs/foundInBgRu.svg')
  background-repeat: no-repeat, no-repeat
  background-position: bottom 3.4vw left 21.3vw, center
  background-size: 62.19vw 44.9vw, cover

.aboutFoundedCards
  display: flex
  flex-direction: row
  justify-content: center
  margin: 13.94vw 17.29vw 13.94vw 20.47vw
  white-space: pre-line
  .card
    display: flex
    align-items: center
    justify-content: center
    color: $main-white
    font-family: $jetBrains
    font-size: 0.94vw
    font-weight: 200
    line-height: 1.30vw
    letter-spacing: 0.01em
    .h3
      font-size: 2.19vw
      font-weight: 100
      line-height: 2.6vw
      letter-spacing: 0.05vw
      display: flex
      flex-direction: row
      width: 13.02vw
      span
        font-size: 1.04vw
        font-weight: 300
        line-height: 1.25vw
        align-self: center
        margin-left: 1.25vw
        color: $gray5
    &:first-child
      .cardContent
        width: 18.07vw
        margin-right: 7.45vw
        margin-bottom: 6.4vw
    &:last-child
      margin: 6.25vw 0 0 2.08vw
      .cardContent
        width: 19.53vw
.arrowCardBtn
  font-family: $montserrat
  width: fit-content
  margin: 2.19vw 0 0 0
  position: relative

// tablet version
@media (max-width: $desktop)
  .aboutBox
    .aboutTitle
      h6
        font-size: 3vw
        line-height: 3.6vw
        letter-spacing: 0.17vw
        margin-bottom: 4.98vw
        margin-left: 5.75vw
    .aboutTextBox
      flex-direction: column
      margin: 0 5.75vw 3.42vw 5.75vw
      .utpText
        font-size: 3.6vw
        line-height: 4.32vw
        letter-spacing: 0.08vw
        width: 46.16vw
        margin-left: 42.33vw
        margin-bottom: 8.02vw
      .utp
        .utpTitle
          font-size: 3.6vw
          line-height: 4.32vw
          letter-spacing: 0.08vw
          margin-bottom: 5.76vw
          width: 88.49vw
        .utpPoints
          width: 88.49vw
    .staticLineBox
      margin: 0 0 9.64vw -44.02vw
      .staticLine
        img
          width: 215.86vw
          height: 12.95vw
    .aboutFoundedBox
      background-position: center, center
      background-size: 109.11vw 78.77vw, contain
    .aboutFoundedBoxRu
      background-position: center, center
      background-size: 109.11vw 78.77vw, contain

  .aboutFoundedCards
    margin: 11.63vw 0 12.32vw
    .card
      width: 43.95vw
      height: 56.95vw
      font-size: 1.68vw
      line-height: 2.28vw
      .h3
        font-size: 3.84vw
        line-height: 4.56vw
        letter-spacing: 0.09vw
        width: 13.02vw
        span
          font-size: 1.8vw
          line-height: 2.16vw
          margin-left: 2.19vw
      &:first-child
        .cardContent
          width: 32.65vw
      &:last-child
        margin: 8.55vw 0 0
        .cardContent
          width: 35.17vw
      .arrowCardBtn
        margin-top: 4.56vw

// mobile version
@media (max-width: $tablet)
  .aboutBox
    .aboutTitle
      h6
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
        margin-bottom: 8.53vw
        margin-left: 4.27vw
    .aboutTextBox
      margin: 0 4.27vw 12.8vw
      .utpText
        font-weight: 300
        margin-right: 0
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.27vw
        width: 70.93vw
        margin-left: 0
        margin-bottom: 6.4vw
      .utp
        .utpTitle
          font-weight: 300
          font-size: 5.33vw
          line-height: 6.4vw
          letter-spacing: 0.27vw
          width: 91.47vw
          margin-bottom: 4.02vw
        .utpPoints
          white-space: pre-line
    .staticLineBox
      margin: 0 0 2.5vw -41.92vw
      .staticLine
        img
          width: 214.06vw
          height: 13.33vw
    .aboutFoundedBox
      background-image: url('/images/imgs/blocksMobile.png'),url('/images/imgs/foundInBg.svg')
      background-repeat: no-repeat
      background-position: center
      background-size: contain
    .aboutFoundedBoxRu
      background-image: url('/images/imgs/blocksMobile.png'),url('/images/imgs/foundInBgRu.svg')
      background-repeat: no-repeat
      background-position: center
      background-size: contain

  .aboutFoundedCards
    margin: 21.63vw 0 29.33vw
    flex-direction: column
    .card
      justify-content: left
      width: 100vw
      height: 135.73vw
      font-weight: 300
      font-size: 4.27vw
      line-height: 5.87vw
      letter-spacing: 0.27vw
      .h3
        font-size: 11.2vw
        line-height: 13.33vw
        letter-spacing: 0.27vw
        width: 13.02vw
        margin-top: 0
        span
          font-size: 5.33vw
          line-height: 6.4vw
          margin-left: 6.4vw
      &:first-child
        .cardContent
          width: 91.2vw
          margin: 0 4.27vw 29.46vw
          padding-top: 20.7vw
      &:last-child
        margin: 0
        .cardContent
          width: 91.47vw
          margin-top: -8.3vw
          margin-left: 7.27vw
      .arrowCardBtn
        margin-top: 11.2vw
        margin-left: 22.3vw
