@import ../../../../scss/variables

// desktop version  
.btnContainer
  display: flex
  flex-direction: column
  width: 21.35vw
  margin: 0 3.02vw 4.27vw 0

.btn
  display: flex
  flex-direction: row
  text-shadow: $borderTextBlue
  color: $main-black
  font-size: 5.21vw
  line-height: 5.21vw
  letter-spacing: 0.31vw
  font-weight: 800
  font-family: $jetBrains
  border-bottom: 0.05vw solid $main-blue
  height: 3.38vw
  overflow: hidden
  margin-bottom: 1.67vw
  span
    color: $main-blue

.text
  color: $gray5
  font-family: $jetBrains
  line-height: 1.77vw
  font-size: 1.46vw
  font-weight: 300
  letter-spacing: 0.05vw
  word-break: normal

// tablet version
@media (max-width: $desktop)
  .btnContainer
    width: 38.1vw
    margin: 0
    margin-bottom: 8.87vw
    margin-right: 11.9vw
    &:nth-child(2)
      margin-right: 0
    &:nth-child(4)
      margin-right: 0
    &:nth-child(6)
      margin-right: 0

    .btn
      font-size: 8.75vw
      line-height: 8.75vw
      letter-spacing: 0.48vw
      height: 5.99vw
      overflow: hidden
      margin-bottom: 2.88vw

    .text
      line-height: 2.88vw
      font-size: 2.4vw
      letter-spacing: 0.08vw

// mobile version
@media (max-width: $tablet)
  .btnContainer
    width: 84.8vw
    margin: 0
    margin-bottom: 21.72vw

    .btn
      font-size: 19.47vw
      line-height: 19.47vw
      letter-spacing: 1.07vw
      height: 13.33vw
      overflow: hidden
      margin-bottom: 6.26vw

    .text
      line-height: 6.4vw
      font-size: 5.33vw
      letter-spacing: 0.19vw
