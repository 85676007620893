@import ../../../scss/variables

// desktop version  
.loading
  font-family: $montserrat
  font-weight: 900
  font-size: 3.73vw
  line-height: 3.73vw
  letter-spacing: 0.31vw
  text-transform: uppercase
  color: $main-white
  margin: 0
  margin-bottom: 2.5vw
