@import ../../../scss/variables

// desktop version
.weDoBox
  width: 100%
  border-top: 0.05vw solid $main-white
  border-bottom: 0.05vw solid $main-white
  margin: 8.44vw 0 3.7vw 0
  .weDoTitle
    display: flex
    justify-content: flex-start
    text-transform: uppercase
    font-size: 3.75vw
    line-height: 3.75vw
    letter-spacing: 0.1vw
    font-weight: 900
    text-shadow: $borderTextWhite
    color: $main-black
    margin: 0 0 0 18.54vw
    span
      margin-left: 0.83vw
      color: $main-white
      text-shadow: none
  .weDoTitleRu
    @extend .weDoTitle
    width: 55vw
    flex-wrap: wrap

// tablet version
@media (max-width: $desktop)
  .weDoBox
    margin: 7.67vw 0 9.59vw 0
    .weDoTitle
      font-size: 5.75vw
      line-height: 6.35vw
      letter-spacing: 0.12vw
      margin: 0 0 0 5.75vw
      span
        margin-left: 1.68vw

// mobile version
@media (max-width: $tablet)
  .weDoBox
    margin: 6.4vw 0 13.07vw 0
    .weDoTitleMobile
      font-size: 9.07vw
      line-height: 10.93vw
      letter-spacing: 0.53vw
      font-weight: 900
      margin: 0 0 0 4.27vw
      text-transform: uppercase
      text-shadow: $borderTextWhite
      color: $main-black
      span
        margin-left: 0
        margin-right: 2.67vw
        color: $main-white
        text-shadow: none
    .weDoTitle
      font-size: 9.07vw
      line-height: 10.93vw
      letter-spacing: 0.53vw
      margin: 0 0 0 4.27vw
      span
        margin-left: 2.67vw
