@import scss/variables

// desktop version  
.faqForm
  display: flex
  position: relative
  flex-direction: column
  width: 46.87vw
  z-index: 1
  padding-bottom: 7.65vw

  .faqFormGroup
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin-top: 0.9vw

    .textarea
      width: 30.68vw
      max-width: 30.68vw

  a
    color: $main-blue
    margin-left: 0.46vw
    transition: all ease .2s
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 0.73vw
    letter-spacing: 0.02vw

    &:hover
      text-decoration: underline

// tablet version
@media (max-width: $desktop)
  .faqForm
    width: 88.49vw
    padding-bottom: 11.99vw

    .faqFormGroup
      justify-content: flex-start
      margin-top: 0

      .textarea
        width: 51.2vw
        max-width: 51.2vw

    a
      margin-left: 0.96vw
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw

// mobile version
@media (max-width: $tablet)
  .faqForm
    width: 91.47vw
    padding-bottom: 68.87vw
    position: relative

    .faqFormGroup
      display: inline-block

      .textarea
        width: 91.47vw
        max-width: 91.47vw
      .button
        position: absolute
        bottom: 24.56vw
        left: 23.47vw

    a
      margin-left: 2.13vw
      font-size: 3.73vw
      line-height: 5.33vw
      letter-spacing: 0.13vw
