@import ../../../scss/variables

// desktop version
.automatedBox
  display: flex
  flex-direction: column
  .automatedToolsTitle
    display: flex
    align-items: center
    margin-top: 9.17vw
    margin-bottom: 3.54vw
    padding-left: 18.65vw
    .h6
      color: $main-white
      text-transform: uppercase
      font-size: 1.87vw
      font-weight: 900
      line-height: 2.24vw
      letter-spacing: 0.1vw
      margin: 0
      margin-right: 25.05vw
      span
        color: $main-black
        text-shadow: $borderTextWhite
    .h2
      font-size: 5.73vw
      font-weight: 900
      line-height: 5.73vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      margin: 0
      color: $main-black
      text-shadow: $borderTextWhite
    .h6Ru
      @extend .h6
      margin-right: 0

  .automatedToolsContent
    display: flex
    flex-direction: column
    overflow: hidden
    margin-bottom: 8.33vw
    ul
      position: relative
      transition: all ease .3s
      width: 120%
      animation: scroll 6s ease-in-out infinite
      &:first-child
        margin-left: 4.17vw
      li
        display: inline-flex
        flex-wrap: nowrap
        align-items: center
        padding: 1.25vw 2.5vw
        border: 0.05vw solid #63E3E3
        border-radius: 50px
        background-color: $gray1
        color: $gray4
        width: fit-content
        height: fit-content
        font-size: 1.46vw
        font-weight: 500
        font-family: $jetBrains
        line-height: 1.72vw
        letter-spacing: 0.05vw
        margin: 0 1.25vw 2.5vw 0
        &:last-child
          margin-right: 0
        img
          margin-left: 0.83vw
          width: 2.45vw
          height: 2.45vw
  .automatedLanguages
    display: flex
    padding-left: 18.65vw
    .h2
      text-transform: uppercase
      text-shadow: $borderTextWhite
      color: $main-black
      letter-spacing: 0.31vw
      font-weight: 900
      font-size: 5.73vw
      line-height: 5.73vw
      height: fit-content
      margin-bottom: 0
      margin-top: 2.6vw
    .automatedToolsImg
      margin-left: -1.04vw
      img
        width: 30.68vw
        height: 19.63vw

@keyframes scroll
  0%
    transform: translate(-10%, 0)
  50%
    transform: translate(1%, 0)
  100%
    transform: translate(-10%, 0)

// tablet version
@media (max-width: $desktop)
  .automatedBox
    .automatedToolsTitle
      margin-top: 6.35vw
      margin-bottom: 5.87vw
      padding-left: 5.75vw
      .h6
        font-size: 3vw
        line-height: 3.6vw
        letter-spacing: 0.1vw
        margin-right: 8.39vw
      .h2
        font-size: 7.91vw
        line-height: 7.91vw
    .automatedToolsContent
      margin-bottom: 12vw
      ul
        width: 130%
        &:first-child
          margin-left: 14.17vw
        &:last-child
          margin-left: 9.67vw
        li
          padding: 2.4vw 3.72vw
          font-size: 2.16vw
          font-weight: 500
          line-height: 2.64vw
          letter-spacing: 0.05vw
          margin: 0 1.25vw 2.5vw 0
          &:last-child
            margin-right: 0
          img
            margin-left: 1.2vw
            width: 3.6vw
            height: 3.6vw
    .automatedLanguages
      padding-left: 5.75vw
      .h2
        font-size: 7.91vw
        line-height: 7.91vw
        margin-top: 2.6vw
      .automatedToolsImg
        margin-left: 1.68vw
        img
          width: 31.41vw
          height: 20.02vw
  @keyframes scroll
    0%
      transform: translate(-20%, 0)
    50%
      transform: translate(1%, 0)
    100%
      transform: translate(-20%, 0)

// mobile version
@media (max-width: $tablet)
  .automatedBox
    .automatedToolsTitle
      flex-direction: column
      align-items: flex-start
      margin-top: 6.4vw
      margin-bottom: 6.4vw
      padding-left: 4.27vw
      .h6
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
        margin-right: 0
        margin-bottom: 12.8vw
      .h2
        font-size: 9.6vw
        line-height: 11.47vw
        letter-spacing: 0.53vw
    .automatedToolsContent
      margin-bottom: 12.8vw
      ul
        width: 150%
        &:first-child
          margin-left: 14.17vw
        &:nth-child(2)
          margin-left: 7.67vw
        &:last-child
          margin-left: 9.67vw
        li
          padding: 3.47vw 5.87vw
          font-size: 3.47vw
          line-height: 4.27vw
          letter-spacing: 0.12vw
          margin: 0 2.93vw 6.4vw 0
          &:last-child
            margin-right: 0
          img
            margin-left: 1.97vw
            width: 5.79vw
            height: 5.79vw
    .automatedLanguages
      flex-direction: column
      padding-left: 4.27vw
      .h2
        font-size: 9.6vw
        line-height: 11.47vw
        letter-spacing: 0.53vw
        margin-top: 0
        margin-bottom: 6.4vw
      .automatedToolsImg
        margin-left: 0
        margin-bottom: 21.33vw
        img
          width: 91.47vw
          height: 58.4vw
  @keyframes scroll
    0%
      transform: translate(-32%, 0)
    50%
      transform: translate(1%, 0)
    100%
      transform: translate(-32%, 0)
