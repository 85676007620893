@import ../../../scss/variables

// desktop version
.faq
  background-color: $main-black
  white-space: pre-line
.boxfaq
  padding: 9.43vw 10.52vw 0 18.54vw
  header
    display: flex
    margin-bottom: 6.4vw
  .h1
    color: $main-black
    text-shadow: $borderTextWhite
    font-family: $montserrat
    font-size: 13.28vw
    font-weight: 900
    line-height: 15.94vw
    letter-spacing: 1.04vw
    margin: 0 4.06vw 0 0
    span
      color: $main-black
      text-shadow: $borderTextBlue
  .h2
    position: relative
    color: $main-black
    text-transform: uppercase
    text-shadow: $borderTextWhite
    font-size: 1.87vw
    font-weight: 900
    line-height: 2.24vw
    letter-spacing: 0.1vw
    margin: 0
    align-self: center
    span
      text-shadow: $borderTextBlue
  .accordion
    margin-bottom: 10.63vw
    a
      font-family: $jetBrains
      color: $main-blue
      margin-left: 2.26vw
      font-weight: 200
      font-size: 0.94vw
      line-height: 1.3vw
      letter-spacing: 0.01em
  .FaqForm
    margin-top: 20.88vw
    margin-bottom: 2.6vw
    display: flex
    .FormTitle
      font-family: $montserrat
      font-weight: 900
      font-size: 2.5vw
      letter-spacing: 0.05vw
      line-height: 2.76vw
      text-transform: uppercase
      color: $main-white
      text-shadow: none
      margin: 0
      padding-right: 4.61vw
      padding-top: 6.46vw
      width: 24.74vw
      span
        font-size: 4.69vw
        letter-spacing: 0.31vw
        line-height: 4.22vw
        color: $main-black
        text-shadow: $borderTextWhite
    .FormTitleRu
      @extend .FormTitle
      span
        font-size: 3.59vw
        line-height: 3.42vw
        letter-spacing: 0.21vw
.boxfaqRu
  @extend .boxfaq
  .h1
    margin: 0 0 0 0
  .h2
    color: $main-black
    text-shadow: $borderTextWhite
    font-family: $montserrat
    font-size: 3.33vw
    font-weight: 900
    line-height: 3.33vw
    letter-spacing: 0.3vw
    margin: 0 1.06vw 0 0
    span
      color: $main-white
      text-shadow: none

// tablet version
@media (max-width: $desktop)
  .boxfaq
    padding: 9.71vw 5.75vw 0 5.75vw
    header
      margin-bottom: 9.71vw
      .h1
        font-size: 19.18vw
        line-height: 23.02vw
        letter-spacing: 2.04vw
        margin: 0 7.07vw 0 0
      .h2
        font-size: 4.32vw
        line-height: 5.16vw
        letter-spacing: 0.24vw
    .accordion
      margin-bottom: 0
      a
        font-size: 2.16vw
        line-height: 2.99vw
        margin-left: 5.36vw
    .FaqForm
      margin-top: 12.47vw
      flex-direction: column
      .FormTitle
        font-size: 5.75vw
        letter-spacing: 0.12vw
        line-height: 6.35vw
        margin: 0
        margin-bottom: 10.19vw
        width: 45.2vw
        span
          letter-spacing: 0.72vw
          font-size: 10.79vw
          line-height: 9.71vw

// mobile version
@media (max-width: $tablet)
  .boxfaq
    padding: 16vw 4.27vw 0
    header
      flex-direction: column
      margin-bottom: 14.93vw
      .h1
        font-size: 36vw
        line-height: 43.2vw
        letter-spacing: 3.73vw
        margin: 0
        margin-bottom: 4.14vw
      .h2
        font-size: 9.6vw
        line-height: 12.67vw
        letter-spacing: 0.53vw
        align-self: flex-start
    .accordion
      a
        display: flex
        font-size: 4.8vw
        line-height: 6.67vw
        margin-left: 11.36vw
        width: 64.27vw
    .FaqForm
      margin-top: 22.67vw
      .FormTitle
        font-size: 9.6vw
        letter-spacing: 0.53vw
        line-height: 12.67vw
        margin-bottom: 21.33vw
        width: 70.67vw
        span
          font-size: 12.8vw
          letter-spacing: 1.6vw
          line-height: 11.47vw
