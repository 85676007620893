@import ../../../scss/variables

// desktop version
.company
  color: $main-black
  white-space: pre-line
  .h2
    font-family: $montserrat
    font-weight: 900
    font-size: 3.75vw
    line-height: 3.75vw
    letter-spacing: 0.10vw
    color: $main-black
    text-shadow: $borderTextWhite
    span
      color: $main-white
      text-shadow: none
  p
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.46vw
    line-height: 1.77vw
    letter-spacing: 0.05vw
    color: $gray5
    margin-left: 64.61vw
    margin-top: 3.12vw

  .companyHeader
    margin-top: 11.15vw
    padding: 0 11.17vw 0 9.37vw
    .h1
      position: relative
      z-index: 2
      text-align: left
      margin: 0 0 -19% 12%
      font-family: $montserrat
      font-weight: 900
      font-size: 4.69vw
      line-height: 4.22vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      color: $main-white
      span
        color: $main-black
        text-shadow: $borderTextWhite
    img
      width: 79.43vw
      height: 45.83vw
    .cardsCompany
      display: flex
      justify-content: space-around
      margin-top: -34%
      .cardsText
        &:first-child
          margin-top: 2.5%
        .cardsTitle
          font-family: $montserrat
          font-weight: 900
          font-size: 8.33vw
          line-height: 7.5vw
          text-transform: uppercase
          color: $main-blue
          margin: 0
          margin-bottom: 0.42vw
        p
          font-weight: 500
          font-size: 1.46vw
          line-height: 1.77vw
          letter-spacing: 0.05vw
          color: $main-blue
          margin: 0
          margin-left: 1.85vw
      .cardsTextRu
        @extend .cardsText
        p
          margin-left: 0

  .boxMission
    position: relative
    margin: 16.08vw 10.52vw 0 18.75vw
    .h2
      position: absolute
      top: 20%
      margin: 0
    .textMission
      display: flex
      justify-content: space-between
      p
        color: $gray6
        margin: 0
        padding-top: 12%
      img
        width: 39.06vw
        height: 25.52vw

  .boxVision
    margin: 24.76vw 10.52vw 13.7vw 18.54vw
    position: relative
    .h2
      text-align: right
      margin: 0
      position: absolute
      right: 0%
      top: 8%
    img
      width: 45.62vw
      height: 15.62vw
      margin-left: 17.24vw
    p
      margin: 0
      width: 30.83vw
      margin-top: -9%

  .boxValues
    margin: 0 8.12vw 10.42vw 18.54vw
    position: relative
    .h2
      position: absolute
      margin: 0
      top: 49%
    img
      width: 55.73vw
      height: 42.02vw
      margin-left: 9.48vw
    ul
      position: absolute
      display: flex
      justify-content: space-between
      transform: rotate(-14.7deg)
      li
        font-family: $jetBrains
        font-weight: 300
        font-size: 1.46vw
        line-height: 1.77vw
        letter-spacing: 0.05vw
        color: $gray5

    .valuesListFirst
      top: 27%
      left: 30%
      li
        width: 11.14vw
        &:nth-child(1)
          margin-left: -26%
          margin-top: -12%
          margin-right: 19%
          transform: rotate(-5.7deg)
        &:nth-child(2)
          margin-top: -2%
        &:nth-child(3)
          margin-right: -11%
    .valuesListSecond
      left: 33%
      bottom: 23%
      li
        width: 14.47vw
        &:nth-child(2)
          margin-top: -12%
        &:nth-child(3)
          margin-top: 5%
    .valuesListSecondRu
      @extend .valuesListSecond
      li
        &:nth-child(2)
          width: 12.69vw

  .boxLeadership
    margin: 0 26.51vw 13.23vw 18.54vw
    .boxPhoto
      display: flex
      img
        width: 22.8vw
        height: 26.98vw
      ul
        position: absolute
        top: 5%
        right: 11.5%
        z-index: 1
      li
        font-family: $jetBrains
        font-weight: 400
        font-size: 0.73vw
        letter-spacing: 0.03vw
        color: $main-blue
        cursor: pointer
        line-height: 1.04vw
        &:hover
          border-bottom: 0.05vw solid $main-blue
      .h3
        font-family: $montserrat
        font-style: normal
        font-weight: 900
        font-size: 1.88vw
        line-height: 2.08vw
        letter-spacing: 0.10vw
        text-transform: uppercase
        color: $main-white
        margin: 0
        margin-top: 1.04vw
        margin-bottom: 0.36vw
      p
        font-family: $jetBrains
        font-weight: 300
        font-size: 1.04vw
        line-height: 1.25vw
        letter-spacing: 0.05vw
        color: $gray4
        opacity: 0.75
        margin: 0
      .card
        visibility: hidden
        opacity: 0
        position: absolute
        top: 30%
        left: 58.5%
        img
          width: 3.5vw
          height: 2.67vw

      .photo1
        position: relative
        margin-right: 11.96vw
        &:hover .card
          visibility: visible
          opacity: 1

      .photo2
        position: relative
        &:hover .card
          visibility: visible
          opacity: 1

  .boxLets
    background-color: $main-blue
    background-image: url('/images/imgs/bgLets.svg')
    background-repeat: no-repeat
    background-position: center
    background-size: 115.78vw 42.71vw
    height: 56.25vw
    margin: 0
    display: flex
    flex-direction: column
    justify-content: center
    p
      font-family: $jetBrains
      font-weight: 100
      font-size: 2.19vw
      line-height: 2.6vw
      letter-spacing: 0.05vw
      color: $main-white
      margin: 0
      margin-bottom: 5.94vw
      margin-left: 18.54vw
    .companyBtn
      font-family: $montserrat
      font-weight: 900
      font-size: 5.73vw
      line-height: 5.73vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      color: $main-white
      background-color: rgba(0,0,0,0)
      cursor: pointer
      border: none
      border-top: 0.05vw solid $main-white
      border-bottom: 0.05vw solid $main-white
      width: 100%
      transition: all ease .3s
      padding: 5px 0
      img
        width: 4.17vw
        height: 4.17vw
        margin-left: 1.25vw
      &:hover
        background-color: $main-white
        color: $main-blue
    .modal
      p
        font-family: $jetBrains
        font-weight: 400
        font-size: 0.73vw
        line-height: 0.73vw
        letter-spacing: 0.02vw
        color: $gray3
        margin: 0
  .boxLetsRu
    @extend .boxLets
    background-image: url('/images/imgs/bgLetsRu.png')
    background-size: contain
    .companyBtn
      text-align: left
      padding-left: 18.49vw

// tablet version
@media (max-width: $desktop)
  .company
    .h2
      font-size: 5.75vw
      line-height: 6.35vw
      letter-spacing: 0.12vw
    p
      font-size: 2.4vw
      line-height: 2.88vw
      letter-spacing: 0.12vw
      margin-left: 68.7vw
      margin-top: 9.66vw
    .companyHeader
      margin-top: 14.63vw
      padding: 0
      .h1
        font-size: 7.67vw
        line-height: 9.23vw
        letter-spacing: 0.36vw
        position: relative
        z-index: 2
        text-align: left
        margin: 0 0 -21.25vw 5.75vw
      img
        width: 100vw
        height: 57.67vw
      .cardsCompany
        justify-content: stretch
        margin-top: -35%
        margin-left: 11.51vw
        .cardsText
          &:first-child
            margin-top: 2.5%
            margin-right: 22.6vw
          .cardsTitle
            font-size: 10.43vw
            line-height: 9.35vw
            margin-bottom: 0
          p
            font-size: 1.92vw
            line-height: 2.28vw
            letter-spacing: 0.12vw

    .boxMission
      margin: 13.91vw 5.75vw 0
      .h2
        top: 8%
      .textMission
        p
          margin-left: 5.63vw
          padding-top: 3%
        img
          width: 51.92vw
          height: 33.93vw

    .boxVision
      margin: 23.62vw 5.75vw 20.98vw
      .h2
        top: -6%
      img
        width: 67.39vw
        height: 23.08vw
        margin-left: 12.54vw
      p
        width: 49.04vw
        margin-top: 4%

    .boxValues
      margin: 0 5.75vw 20.98vw 6.17vw
      .h2
        top: 67%
      img
        width: 75.63vw
        height: 57.77vw
        margin-left: 13.43vw
      ul
        justify-content: stretch
        li
          font-size: 1.92vw
          line-height: 2.28vw
          letter-spacing: 0.06vw
      .valuesListFirst
        top: 33%
        left: 30%
        li
          &:nth-child(1)
            margin-left: -23%
            margin-top: -19%
            margin-right: 53%
          &:nth-child(2)
            margin-top: -3%
          &:nth-child(3)
            margin-right: 0
            margin-left: 23%
      .valuesListSecond
        left: 38%
        bottom: 19%
        li
          width: 15.47vw
          &:nth-child(2)
            margin-left: 12%
          &:nth-child(3)
            margin-top: 9%
            margin-left: 16%

    .boxLeadership
      margin: 0 6.23vw 9.66vw 5.75vw
      .boxPhoto
        img
          width: 31.37vw
          height: 37.1vw
        ul
          right: 10%
        li
          font-size: 1.44vw
          line-height: 2.04vw
        .h3
          font-size: 2.76vw
          line-height: 3.36vw
          letter-spacing: 0.14vw
          margin-top: 1.44vw
          margin-bottom: 0.48vw
        p
          font-size: 1.44vw
          line-height: 1.68vw
          letter-spacing: 0.07vw
        .card
          visibility: visible
          opacity: 1
          top: 14%
          left: 54%
          img
            width: 3.36vw
            height: 2.28vw
        .photo1
          margin-right: 15.39vw

    .boxLets
      height: 71.94vw
      background-size: contain
      p
        font-weight: 300
        font-size: 2.4vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        margin-bottom: 7.64vw
        margin-left: 17.54vw
      .companyBtn
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
        padding: 0
        img
          width: 4.2vw
          height: 4.2vw
          margin-left: 1.27vw
      .modal
        p
          font-size: 1.68vw
          line-height: 2.4vw
          letter-spacing: 0.04vw

// mobile version
@media (max-width: $tablet)
  .company
    .h2
      font-size: 9.07vw
      line-height: 9.87vw
      letter-spacing: 0.27vw
    p
      font-size: 4.27vw
      line-height: 5.87vw
      letter-spacing: 0.27vw
      margin: 0 4.27vw
    .companyHeader
      margin-top: 28.8vw
      margin-bottom: 21.5vw
      overflow: hidden
      .h1
        font-size: 11.2vw
        line-height: 11.55vw
        letter-spacing: 1.6vw
        position: relative
        z-index: 2
        text-align: left
        margin: 0 0 3.9vw 5.75vw
      img
        width: 182.8vw
        height: 100vw
        margin-left: -40vw
        transform: rotate(270deg)
      .cardsCompany
        flex-direction: column
        justify-content: stretch
        margin-top: -95%
        margin-bottom: 35.87vw
        margin-left: 24.87vw
        .cardsText
          &:first-child
            margin: 0
            margin-bottom: 51.67vw
          &:last-child
            margin-left: 10.3vw
          .cardsTitle
            font-size: 16.27vw
            line-height: 14.67vw
            margin-bottom: 0
          p
            font-size: 3.47vw
            line-height: 4.27vw
            letter-spacing: 0.12vw

    .boxMission
      margin: 0 4.27vw
      .h2
        top: 10%
      .textMission
        flex-direction: column
        p
          margin-left: 0
          padding-top: 6.67vw
        img
          width: 89.07vw
          height: 60vw

    .boxVision
      margin: 34.62vw 4.27vw 20.98vw
      .h2
        top: -21%
      img
        width: 89.07vw
        height: 31.2vw
        margin-left: 0
      p
        width: 89.07vw
        margin-top: 6.67vw

    .boxValues
      margin: 0 4.27vw 20.98vw
      .h2
        top: 0
      img
        margin-top: 3vw
        width: 100vw
        height: 82.6vw
        margin-left: -4.4vw
      ul
        justify-content: stretch
        li
          font-size: 2.13vw
          line-height: 2.67vw
      .valuesListFirst
        top: 39%
        left: 15%
        li
          &:nth-child(1)
            margin-top: -30%
            margin-right: 68%
          &:nth-child(3)
            margin-left: 42%
      .valuesListSecond
        left: 26%
        li
          width: 16.5vw
          &:nth-child(2)
            margin-left: 22%
          &:nth-child(3)
            margin-top: 17%
            margin-left: 29%

    .boxLeadership
      margin: 0 4.27vw 22.6vw
      .boxPhoto
        flex-direction: column
        img
          width: 90.53vw
          height: 78vw
          margin-left: -12%
        ul
          right: 28.8vw
        li
          font-size: 5.33vw
          line-height: 6.4vw
        .h3
          font-size: 5.33vw
          line-height: 5.33vw
          letter-spacing: 0.27vw
          margin-top: 6.4vw
          margin-bottom: 1.07vw
        p
          font-size: 3.73vw
          line-height: 5.33vw
          letter-spacing: 0.07vw
        .card
          visibility: visible
          opacity: 1
          top: 26%
          left: 51%
          img
            height: 5.87vw
          p
            font-size: 2.67vw
            line-height: 3.47vw
            letter-spacing: 0.16vw
            padding: 5.6vw 3.73vw 5.6vw 5.6vw
        .photo1
          margin-right: 0
          margin-bottom: 12.8vw

    .boxLets
      height: 169.33vw
      background-image: url('/images/imgs/bgFormLets.svg'), url('/images/imgs/bgLetsMobile.svg')
      background-size: 71.73vw 44.53vw,494.67vw 111.2vw
      background-position: bottom 52.53vw right 15.6vw, center center
      p
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.27vw
        margin-bottom: 21.87vw
        margin-left: 4.27vw
        margin-top: -13.3vw
      .companyBtn
        font-size: 9.07vw
        line-height: 9.07vw
        letter-spacing: 0.48vw
        padding: 0
        text-align: left
        padding-left: 13.5vw
        img
          width: 6.67vw
          height: 6.67vw
          margin-left: 2.13vw
      .modal
        p
          font-size: 3.73vw
          line-height: 5.33vw
          letter-spacing: 0.13vw
