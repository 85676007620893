@import ../../scss/variables

// desktop version
.blog
  padding: 6.67vw 10.52vw 4.48vw 18.54vw
  .h1
    font-family: $montserrat
    font-weight: 900
    font-size: 5.73vw
    line-height: 5.73vw
    letter-spacing: 0.31vw
    text-transform: uppercase
    color: $main-black
    text-shadow: $borderTextWhite
    margin: 0
    margin-bottom: 2.5vw

// tablet version
@media(max-width: $desktop)
  .blog
    padding: 15.35vw 6.95vw 0 5.87vw
    .h1
      font-size: 8.63vw
      line-height: 10.31vw
      letter-spacing: 0.24vw
      margin-bottom: 1.92vw

// mobile version  
@media(max-width: $tablet)
  .blog
    padding: 21.33vw 0 0 3.73vw
    .h1
      font-size: 9.07vw
      line-height: 10.93vw
      letter-spacing: 0.53vw
      margin-bottom: 4.27vw
