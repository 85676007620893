@import ../../../scss/variables

// desktop version  
.jobsItem
  display: flex
  flex-direction: row
  align-items: baseline
  justify-content: space-between
  margin-bottom: 2.5vw
  &:hover
    .jobsDescription
      h4
        color: $main-blue

  &:hover
    .arrow
      svg
        path
          fill: $main-white
  .jobsDescription
    display: flex
    flex-direction: column
    h4
      color: $main-white
      font-size: 1.46vw
      font-weight: 500
      font-family: $jetBrains
      line-height: 1.77vw
      letter-spacing: 0.05vw
      margin: 0 0 0.83vw 0
  .arrow
    align-self: center
    svg
      width: 1.25vw
      height: 1.25vw

// tablet version
@media (max-width: $desktop)
  .jobsItem
    margin-bottom: 5.75vw
    .jobsDescription
      h4
        font-size: 3.36vw
        line-height: 4.07vw
        letter-spacing: 0.12vw
        margin: 0 0 1.92vw 0
    .arrow
      svg
        width: 4.07vw
        height: 4.07vw

// mobile version
@media (max-width: $tablet )
  .jobsItem
    margin-bottom: 6.4vw
    .jobsDescription
      h4
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.27vw
        margin: 0 0 4.27vw 0
    .arrow
      align-self: flex-end
      svg
        width: 6.4vw
        height: 6.4vw
