@import ../../../scss/variables

// desktop version  
.relyBox
  display: flex
  justify-content: center
  margin: 6.98vw 0
  .relyContentBox
    display: flex
    justify-content: center
    position: relative
    img
      width: 78.96vw
      height: 42.29vw
    .relyContent
      display: flex
      justify-content: center
      .relyTitle
        display: flex
        align-items: center
        position: absolute
        top: 33%
        right: 7.6%
        cursor: pointer
        text-transform: uppercase
        color: $main-blue
        .h2
          font-size: 5.73vw
          font-weight: 900
          line-height: 5.73vw
          letter-spacing: 0.31vw
          width: fit-content
          transition: all ease .3s
        svg
          width: 4.17vw
          height: 4.22vw
          margin-left: 1.25vw
          path
            transition: all ease .3s
        &:hover
          color: $main-white
          svg
            path
              fill: $main-blue
      .relyText
        position: absolute
        bottom: 5%
        right: 3%
        color: $main-white
        text-align: right
        width: 13.24vw
        font-size: 1.67vw
        line-height: 1.88vw
        letter-spacing: 0.05vw
        font-weight: 100
        font-family: $jetBrains

// tablet version
@media (max-width: $desktop)
  .relyBox
    margin: 12.23vw 0
    .relyContentBox
      img
        width: 88.49vw
        height: 47.36vw
      .relyContent
        .relyTitle
          right: 14.1%
          .h2
            font-size: 5.87vw
            line-height: 5.87vw
            letter-spacing: 0.32vw
          svg
            width: 4.25vw
            height: 4.25vw
            margin-left: 1.28vw
        .relyText
          width: 15.03vw
          font-size: 2.4vw
          line-height: 2.88vw
          letter-spacing: 0.12vw
          font-weight: 300

// mobile version
@media (max-width: $tablet)
  .relyBox
    margin: 50.67vw 0
    .relyContentBox
      img
        width: 91.47vw
        height: 49.07vw
      .relyContent
        .relyTitle
          right: 14.1%
          .h2
            font-size: 9.07vw
            line-height: 9.07vw
            letter-spacing: 0.5vw
            right: 19.1%
            text-align: left
            margin: 0
            width: 48.8vw
          svg
            width: 6.67vw
            height: 6.67vw
            margin-left: 1.28vw
            align-self: flex-end
        .relyText
          width: 55.03vw
          font-size: 5.33vw
          line-height: 6.4vw
          letter-spacing: 0.27vw
          right: 0
          bottom: -40%
