@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@300;500&display=swap')
@import ./variables

html,
body
  padding: 0
  margin: 0
  font-family: "Montserrat", sans-serif
  overflow: auto
  background-color: $main-black
  &::-webkit-scrollbar
    width: 0
    height: 0
a
  color: inherit
  text-decoration: none

p
  margin: 0

ul
  margin: 0
  padding: 0
  list-style: none

*
  box-sizing: border-box

.customBtnClass
  top: 44%

.customBtnContactsClass
  top: 44%

.grecaptcha-badge
  visibility: hidden
