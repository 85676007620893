@import ../../../scss/variables

// tablet version
.mainMobileBox
  padding: 15.35vw 0 5.87vw
  p
    font-weight: 300
    font-size: 3.84vw
    line-height: 4.56vw
    letter-spacing: 0.19vw
    color: $main-white
    font-family: $jetBrains
    max-width: 65.59vw
    margin: 0 17.21vw 5.35vw
  ul
    margin-top: 5.99vw
    margin-left: 5.75vw
    margin-bottom: 5.75vw
    li
      font-weight: 300
      font-size: 1.92vw
      line-height: 2.64vw
      letter-spacing: 0.12vw
      color: $main-white
      font-family: $jetBrains
      max-width: 72.3vw
      margin-bottom: 2.88vw
  :global(.swiper-pagination-bullet)
    width: 0.96vw
    height: 0.96vw
  .processPaginationActive
    color: $main-blue
    background-color: $main-blue
    opacity: 1
    width: 1.44vw
    height: 1.44vw
  .slide
    display: flex
    flex-direction: column
    .logo
      position: relative
      margin-left: 22.78vw
      img
        width: 54.44vw
        height: 47.36vw
      a
        display: flex
        position: absolute
        z-index: 2
        top: 27%
        margin: 0
        font-size: 7.67vw
        font-weight: 900
        line-height: 9.23vw
        text-transform: uppercase
        letter-spacing: 0.36vw
        color: $main-white
        &::after
          position: absolute
          display: block
          content: url('/images/icons/bigArrowWhite.svg')
          width: 7.31vw
          height: 7.31vw
          right: 65%
          bottom: -47%
    .list
      display: flex
      p
        margin: 0
        margin-right: 2.88vw
        font-size: 1.92vw
        line-height: 2.64vw
  .mybtn
    display: flex
    justify-content: center
    padding-top: 12.59vw

// mobile version
@media (max-width: $tablet)
  .mainMobileBox
    padding: 23.07vw 0 21.33vw
    p
      font-size: 5.33vw
      line-height: 6.4vw
      letter-spacing: 0.27vw
      max-width: 65.59vw
      margin: 0 4.27vw 6.4vw 25.6vw
    ul
      margin-top: 6.67vw
      margin-left: 4.27vw
      li
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
        max-width: 91.47vw
        margin-bottom: 6.4vw
    :global(.swiper-pagination-bullet)
      width: 2.13vw
      height: 2.13vw
    .processPaginationActive
      width: 3.2vw
      height: 3.2vw
    .slide
      .logo
        margin-left: 5.8vw
        img
          width: 67.7vw
          height: 62.21vw
          margin-left: 5.4vw
        .shadow
          position: absolute
          width: 81.33vw
          height: 81.33vw
          right: 14%
          bottom: -15%
        a
          font-size: 10.67vw
          line-height: 13.87vw
          letter-spacing: 0.53vw
          &::after
            width: 10.13vw
            height: 10.13vw
            right: 63%
            bottom: -43%
        .manualLink
          margin-left: 6.4vw
      .list
        p
          margin: 0
          margin-right: 6.4vw
          font-size: 4.27vw
          line-height: 5.87vw
