@import ../../../scss/variables

// desktop version  
.contactsModal
  margin-right: 10.78vw
  #contactsPhones
    margin-bottom: 1.46vw
    p
      font-family: $jetBrains
      font-weight: 200
      font-size: 0.94vw
      line-height: 1.30vw
      letter-spacing: 0.01em
      color: $main-white
      margin: 0.83vw 0 0 0
      transition: all ease .3s
      width: fit-content
      cursor: pointer
      &:hover
        color: $main-blue
      span
        color: $gray3
        margin-right: 0.83vw
  .links
    display: flex
    margin-bottom: 1.87vw
    a
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $main-blue
      margin-right: 1.25vw
      border-bottom: 0.05vw solid transparent
      transition: all ease .3s
      &:hover
        border-bottom: 0.05vw solid $main-blue
  .mail
    margin-bottom: 1.67vw
    a
      transition: all ease .2s
      font-family: $jetBrains
      font-weight: 500
      font-size: 1.46vw
      line-height: 1.77vw
      letter-spacing: 0.05vw
      color: $main-blue
      border-bottom: 0.05vw solid transparent
      &:hover
        border-bottom: 0.05vw solid $main-blue
  #contactsAdress
    cursor: default
    font-family: $jetBrains
    font-weight: 300
    font-size: 0.83vw
    line-height: 1.15vw
    letter-spacing: 0.05vw
    color: $main-white
    margin: 0

// tablet version
@media (max-width: $desktop)
  .contactsModal
    margin-right: 0
    #contactsPhones
      margin-bottom: 3.36vw
      p
        font-size: 2.64vw
        line-height: 3vw
        margin: 1.92vw 0 0 0
        span
          margin-right: 1.92vw
    .links
      margin-bottom: 4.32vw
      a
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw
        margin-right: 2.88vw

    .mail
      margin-bottom: 3.84vw
      a
        font-weight: 400
        font-size: 2.64vw
        line-height: 3.12vw
        letter-spacing: 0.12vw

    #contactsAdress
      font-size: 1.92vw
      line-height: 2.64vw
      letter-spacing: 0.12vw
