@import scss/variables

// desktop version
.modalBox
  position: fixed
  overflow-y: scroll
  width: 100%
  height: 100vh
  z-index: 10
  top: 0
  background-color: $main-black
  padding: 3.85vw 3.85vw 10.21vw 10.52vw
  transform: translateX(100%)
  visibility: hidden
  transition: all ease .3s
  background-image: url('/images/imgs/modals/bgSubscribe.png'),url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: left bottom, center center
  background-size: 61.08vw 53.57vw,34.89vw 34.89vw
  &.contact
    background-image: url('/images/imgs/camera.png'),url('/images/imgs/bg.svg')
    background-size: 40.28vw 49.57vw, 34.89vw 34.89vw
  &.download
    background-image: url('/images/imgs/modals/bgDownload.png'),url('/images/imgs/bg.svg')
    background-size: 61.08vw 53.57vw,34.89vw 34.89vw
  &.question
    background-image: url('/images/imgs/camera.png'),url('/images/imgs/bg.svg')
    background-size: 40.28vw 49.57vw, 34.89vw 34.89vw
  &.response
    background-image: url('/images/imgs/camera.png'),url('/images/imgs/bg.svg')
    background-size: 40.28vw 49.57vw, 34.89vw 34.89vw
  &::-webkit-scrollbar
    width: 0
    height: 0
  .buttonClose
    background: none
    border: none
    cursor: pointer
    margin-left: 82.60vw
    rect
      transition: all ease .2s
      width: 2px
      height: 80px
    svg
      width: 3.02vw
      height: 3.07vw
      &:hover
        rect
          fill: $main-blue
  .modalText
    width: 64.79vw
    margin-left: 5.3vw
    .modalTitle
      font-family: $montserrat
      font-weight: 900
      font-size: 5.73vw
      line-height: 5.73vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      color: $main-white
      display: flex
      flex-direction: column
      margin: 8.68vw 0 2.5vw 11.35vw
      span
        color: $main-black
        text-shadow: $borderTextWhite
    .modalBody
      font-family: $jetBrains
      font-weight: 300
      font-size: 1.46vw
      line-height: 1.77vw
      letter-spacing: 0.05vw
      color: $gray4
      margin: 0
      margin-left: 11.35vw

  .modalBlock
    display: flex

.modalActive
  visibility: visible
  transform: translateX(0)

// tablet version
@media (max-width: $desktop)
  .modalBox
    padding: 7.79vw 5.75vw 0 0
    background-position: left bottom, right bottom
    background-size: 83.62vw 78.3vw, 84.89vw 84.89vw
    &.contact
      background-position: left bottom -18vw, right bottom
      background-size: 86.91vw 98.99vw, 84.89vw 84.89vw
    &.download
      background-position: left bottom, right bottom
      background-size: 112.91vw 94.99vw, 84.89vw 84.89vw
    &.question
      background-position: left bottom -18vw, right bottom
      background-size: 86.91vw 98.99vw, 84.89vw 84.89vw
    &.response
      background-position: left bottom -18vw, right bottom
      background-size: 86.91vw 98.99vw, 84.89vw 84.89vw
    .buttonClose
      svg
        width: 4.56vw
        height: 4.56vw
    .modalText
      margin-bottom: 9.75vw
      margin-left: 5.75vw
      width: 89.79vw
      .modalTitle
        font-size: 7.67vw
        line-height: 7.67vw
        letter-spacing: 0.72vw
        margin: 11.51vw 0 2.88vw
      .modalBody
        font-size: 3.36vw
        line-height: 4.07vw
        letter-spacing: 0.12vw
        margin: 0

// mobile version
@media (max-width: $tablet)
  .modalBox
    padding: 17.79vw 5.75vw 0 0
    background-size: 88.53vw 87.2vw, 94.89vw 94.89vw
    &.contact
      background-position: left bottom -24vw, right bottom
      background-size: 74.91vw 86.99vw, 94.89vw 94.89vw
    &.download
      background-size: 117.91vw 89.99vw, 94.89vw 94.89vw
      background-position: left -11.43vw bottom 12.7vw, right bottom
    &.question
      background-position: left bottom -24vw, right bottom
      background-size: 74.91vw 86.99vw, 94.89vw 94.89vw
    &.response
      background-position: left bottom -24vw, right bottom
      background-size: 74.91vw 86.99vw, 94.89vw 94.89vw
    .buttonClose
      margin-left: 41.47vw
      svg
        width: 8.53vw
        height: 8.53vw
    .modalText
      margin-bottom: 9.75vw
      margin-left: 5.75vw
      .modalTitle
        font-size: 9.6vw
        line-height: 9.6vw
        letter-spacing: 0.53vw
        margin: 23.47vw 0 12.8vw
      .modalBody
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
