@import ../../../scss/variables

// desktop version
.choosenTag
  font-family: $jetBrains
  font-weight: 400
  font-size: 0.73vw
  line-height: 1.04vw
  letter-spacing: 0.03vw
  color: $main-black
  background-color: $main-white
  cursor: pointer
  margin-bottom: 0.83vw
  margin-right: 0.83vw
  border-radius: 5px
  display: flex
  align-items: center
  border: none
  &:hover
    color: $main-black
    background-color: $main-white
  .resetIcon
    stroke: $main-black
    margin-left: 0.78vw
    &:hover
      stroke: $main-blue
.unchoosenTag
  display: none

.posts
  padding-bottom: 8.33vw
  .postCard
    display: flex
    justify-content: space-between
    cursor: pointer
    &:hover
      .h2
        color: $gray3
      .postImgs
        transform: scale(1.07)
  .postsDate
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 1.04vw
    letter-spacing: 0.03vw
    color: $gray4
    margin-bottom: 0.83vw
  .postsTime
    display: flex
    align-items: center
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 1.04vw
    letter-spacing: 0.03vw
    color: $gray4
    .time
      margin-right: 0.42vw
      margin-left: 0.42vw
    img
      width: 0.83vw
      height: 0.83vw
  .h2
    font-family: $montserrat
    font-style: normal
    font-weight: 900
    font-size: 2.5vw
    line-height: 2.76vw
    letter-spacing: 0.05vw
    text-transform: uppercase
    color: $main-white
    max-width: 35.94vw
    cursor: pointer
  .imgs
    align-self: flex-end
    .postImgs
      width: 28.02vw
      height: 14.69vw
      border-radius: 25px
      transition: 0.5s

  .tags
    max-width: 42.71vw
    display: flex
    flex-flow: wrap
    .postTags
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $gray6
      background-color: $gray2
      cursor: pointer
      margin-right: 0.83vw
      margin-bottom: 0.83vw
      border-radius: 5px
      padding: 0.26vw 0.42vw
      border: none
      &:hover
        color: $main-black
        background-color: $main-white
.pagination
  display: flex
  height: 22.47vw
  margin-top: 4.24vw
  background-image: url('/images/imgs/circleBg.svg')
  background-repeat: no-repeat
  background-position: left 25.32vw center
  background-size: 11.87vw 14.37vw
  .paginationButtons
    display: flex
    padding-left: 18.45vw
    padding-top: 9.67vw
    align-items: flex-start
    .prevPageButton
      display: flex
      flex-direction: column
      background: none
      border: none
      cursor: pointer
      font-family: $jetBrains
      font-weight: 500
      font-size: 0.94vw
      line-height: 1.15vw
      padding-bottom: 0.82vw
      color: $main-white
      background-image: url("/images/icons/ServicesArrowPrev.svg")
      background-repeat: no-repeat
      background-size: 10.62vw
      background-position-y: bottom
      &:hover
        color: $main-blue
      &:disabled
        color: $gray2
        background-image: url("/images/icons/GrayArrowPrev.svg")
        background-repeat: no-repeat
        background-size: 10.62vw
        background-position-y: bottom
      span
        padding-left: 2.5vw
    .nextPageButton
      background: none
      border: none
      cursor: pointer
      font-family: $jetBrains
      font-weight: 500
      font-size: 0.94vw
      line-height: 1.15vw
      color: $main-white
      padding-right: 3.55vw
      padding-bottom: 0.82vw
      background-image: url("/images/icons/ServicesArrowNext.svg")
      background-repeat: no-repeat
      background-size: 10.62vw
      background-position-y: bottom
      margin-left: 4.47vw
      &:hover
        color: $main-blue
      &:disabled
        color: $gray2
        background-image: url("/images/icons/GrayArrowNext.svg")
        background-repeat: no-repeat
        background-size: 10.62vw
        background-position-y: bottom
      span
        padding: 0
        padding-right: 2.9vw
        margin-left: -0.18vw
  .pages
    display: flex
    margin-left: 12.27vw
    .currentPage
      font-family: $jetBrains
      font-weight: 300
      font-size: 1.04vw
      line-height: 1.25vw
      letter-spacing: 0.05vw
      color: $gray3
      padding: 0.47vw 1.77vw
      border: 1px solid $gray3
      border-radius: 30px
      margin-right: 0.94vw
      align-self: center
    .totalPages
      align-self: center
      font-family: $jetBrains
      font-weight: 300
      font-size: 1.04vw
      line-height: 1.25vw
      letter-spacing: 0.05vw
      color: $main-white

// tablet version
@media( max-width: $desktop)
  .choosenTag
    font-size: 1.68vw
    line-height: 2.4vw
    letter-spacing: 0.06vw
    margin-bottom: 0.54vw
    margin-right: 1.92vw
    padding: 0.54vw 0.96vw
    max-height: 3.48vw
    .resetIcon
      margin-left: 1.8vw
      width: 2.16vw
      height: 2.16vw
  .posts
    padding-bottom: 11.99vw
    .postsDate
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw
      margin-bottom: 1.92vw
      padding-top: 3.24vw
    .postsTime
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw
      .time
        margin-right: 1.08vw
        margin-left: 1.08vw
      img
        width: 1.92vw
        height: 1.92vw
    .h2
      font-size: 4.08vw
      line-height: 4.92vw
      letter-spacing: 0.24vw
      max-width: 44.97vw
    .imgs
      .postImgs
        width: 43.21vw
        height: 22.66vw
    .tags
      max-width: 44.97vw
      .postTags
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw
        margin-right: 1.92vw
        margin-bottom: 1.92vw
        padding: 0.54vw 0.96vw
  .pagination
    flex-direction: column
    height: 39.8vw
    margin-top: 0
    background-position: center center
    background-size: 22.66vw 22.66vw
    .paginationButtons
      padding-left: 22.15vw
      padding-top: 18.17vw
      padding-bottom: 17.75vw
      .prevPageButton
        font-size: 1.68vw
        line-height: 2.04vw
        background-size: 19.66vw
        width: 19.66vw
        &:disabled
          background-size: 19.66vw
        span
          padding-left: 5.1vw
      .nextPageButton
        font-size: 1.68vw
        line-height: 2.04vw
        margin-left: 3.47vw
        background-size: 19.66vw
        width: 19.66vw
        &:disabled
          background-size: 19.66vw
        span
          padding: 0
          padding-right: 6.5vw
    .pages
      margin-left: 34.17vw
      .currentPage
        font-size: 1.68vw
        line-height: 2.04vw
        letter-spacing: 0.08vw
        padding: 0.78vw 2.76vw
        border-radius: 21px
        margin-right: 1.51vw
      .totalPages
        font-size: 1.68vw
        line-height: 2.04vw
        letter-spacing: 0.08vw

// mobile version
@media (max-width: $tablet)
  .choosenTag
    font-size: 3.73vw
    line-height: 5.33vw
    letter-spacing: 0.13vw
    margin-bottom: 4.27vw
    margin-right: 4.27vw
    padding: 1.2vw 2.13vw
    max-height: 7.73vw
    .resetIcon
      margin-left: 4vw
      width: 4.8vw
      height: 4.8vw
  .posts
    padding-bottom: 21.33vw
    .postCard
      flex-direction: column
      .postsDate
        font-size: 3.73vw
        line-height: 5.33vw
        letter-spacing: 0.13vw
        margin-bottom: 4.27vw
        padding-top: 6.4vw
      .postsTime
        font-size: 3.73vw
        line-height: 5.33vw
        letter-spacing: 0.13vw
        .time
          margin-right: 2.13vw
          margin-left: 2.13vw
        img
          width: 4.27vw
          height: 4.27vw
      .h2
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
        max-width: 91.47vw
      .imgs
        align-self: flex-start
        margin-bottom: 6.13vw
        .postImgs
          width: 92.33vw
          height: 48.39vw
          border-radius: 13px
    .tags
      max-width: 92.8vw
      .postTags
        font-size: 3.73vw
        line-height: 5.33vw
        letter-spacing: 0.13vw
        margin-bottom: 4.27vw
        margin-right: 4.27vw
        padding: 1.2vw 2.13vw
  .pagination
    height: 93.33vw
    background-size: 63.67vw 63.67vw
    background-position: left 14.5vw center
    padding-right: 3.73vw
    .paginationButtons
      justify-content: space-between
      padding-left: 0.32vw
      padding-top: 41.17vw
      padding-bottom: 38.13vw
      .prevPageButton
        font-size: 3.36vw
        line-height: 4vw
        background-size: 41.08vw
        width: 41.08vw
        padding-bottom: 2.9vw
        &:disabled
          background-size: 41.08vw
        span
          padding-left: 11.6vw
      .nextPageButton
        font-size: 3.36vw
        line-height: 4vw
        background-size: 41.08vw
        width: 41.08vw
        margin-left: 0
        padding-bottom: 2.9vw
        &:disabled
          background-size: 41.08vw
        span
          padding-right: 17vw
    .pages
      margin-left: 26.1vw
      .currentPage
        font-size: 3.73vw
        line-height: 4.48vw
        letter-spacing: 0.19vw
        padding: 1.73vw 6.13vw
        margin-right: 3.36vw
      .totalPages
        font-size: 3.73vw
        line-height: 4.48vw
        letter-spacing: 0.19vw
