@import ../../../scss/variables

// desktop version
.footer
  display: flex
  flex-direction: column
  color: $main-white
  background-color: $main-blackDark

.footerBox
  display: flex
  align-items: flex-end
  flex-direction: column
  margin: 3.29vw 10.52vw 0 0
  .footerDocuments
    &:last-child
      margin: 0 auto 2.5vw 0

.footerTitle
  display: flex
  flex-direction: row
  align-items: center
  margin-bottom: 9.60vw
.title
  display: flex
  flex-direction: row
  a
    display: flex
    margin-right: 4.63vw
    align-self: center
    cursor: pointer
  img
    width: 1.67vw
    height: 1.67vw
  .h2
    font-size: 3.75vw
    font-weight: 900
    line-height: 3.75vw
    letter-spacing: 0.1vw
    margin: 0

.footerMenu
  display: flex
  flex-direction: column
  li
    transition: all ease .3s
    &:hover
      color: $main-blue

.boxMenu
  display: flex
  flex-direction: row
  margin-bottom: 5.46vw
  .h3
    font-size: 1.87vw
    font-weight: 900
    line-height: 2.24vw
    margin: 0 0 1.46vw 0
    color: $main-blackDark
    text-shadow: $borderTextWhite
    letter-spacing: 0.1vw
  li
    margin-bottom: 1.25vw
    font-size: 0.83vw
    font-weight: 400
    font-family: $jetBrains
    line-height: 0.83vw
    letter-spacing: 0.05vw

.menuAboutUs
  margin-right: 7.22vw
  ul
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 3.33vw

.menuServices
  margin-right: 7.22vw

.menuGetInTouch
  margin-right: 6.85vw
  li
    color: $gray3
    font-size: 0.94vw
    font-weight: 400
    line-height: 1.30vw
    letter-spacing: 0.01em
    &:hover
      color: $gray3
    span
      color: $main-white
      margin-left: 0.83vw
      cursor: pointer
      &:hover
        color: $main-blue

.footerForm
  width: 63.92vw
  margin-bottom: 10.36vw
  position: relative
  label
    &:nth-child(1)
      font-size: 1.45vw
      font-weight: 500
      font-family: $jetBrains
      line-height: 1.77vw
      letter-spacing: 0.05vw
      color: $gray2
      margin-bottom: 1.25vw
    a
      color: $main-blue
      margin-left: 0.46vw
      transition: all ease .2s
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 0.73vw
      letter-spacing: 0.02vw

      &:hover
        text-decoration: underline
    span
      color: $main-white

.footerContacts
  display: flex
  flex-direction: column
  position: relative
  .footerLinks
  .h2
    margin: 0 0 0.83vw 5.2vw
    font-size: 3.33vw
    font-weight: 900
    line-height: 4.01vw
    color: $main-blue
    letter-spacing: 0.15vw
    text-transform: uppercase
    transition: all ease .3s
    border-bottom: 0.05vw solid transparent
    text-align: right
    &:hover
      border-bottom: 0.05vw solid $main-blue
  p
    text-align: right
    font-family: $jetBrains
    color: $gray3
    font-size: 0.94vw
    font-weight: 200
    line-height: 1.30vw
    letter-spacing: 0.01em

.footerDocuments
  display: flex
  justify-content: flex-start
  ul
    text-align: left
    li
      display: inline-block
      font-size: 0.62vw
      font-weight: 500
      font-family: $jetBrains
      line-height: 0.83vw
      color: $gray2
      letter-spacing: 0.05vw
      a
        cursor: pointer
      &:hover
        color: $main-blue
      &:nth-child(1)
        margin: 0 3.75vw 0 2.5vw

// tablet version
@media (max-width: $desktop)
  .footerBox
    margin: 7.13vw 5.69vw 0 0
    .footerDocuments
      &:last-child
        margin: 0 auto 5.74vw 0
  .footerTitle
    margin-bottom: 20.56vw
    .title
      font-size: 3.96vw
      line-height: 3.96vw
      letter-spacing: 0.12vw
      align-items: center
      a
        margin-right: 4.80vw
        img
          width: 3.84vw
          height: 3.84vw
  .footerMenu
    margin-left: 5.69vw
    .boxMenu
      margin-bottom: 18.22vw
      flex-wrap: wrap
      .h3
        font-size: 2.64vw
        line-height: 3.12vw
        margin: 0 0 2.88vw 0
        letter-spacing: 0.24vw
      ul
        li
          margin-bottom: 2.88vw
          font-size: 1.92vw
          line-height: 1.92vw
          letter-spacing: 0.12vw
      .menuAboutUs
        margin-right: 23.62vw
        margin-bottom: 10.07vw
      .menuServices
        margin-right: 0

      .menuGetInTouch
        ul
          li
            font-size: 2.16vw
            font-weight: 200
            line-height: 2.99vw
            span
              margin-left: 1.92vw

  .footerForm
    width: 88.49vw
    margin-bottom: 19.18vw
    height: 13.5vw
    label
      &:nth-child(1)
        font-size: 2.16vw
        line-height: 2.52vw
        letter-spacing: 0.07vw
        margin-bottom: 2.79vw
      a
        margin-left: 0.96vw
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw

  .footerContacts
    .h2
      margin: 0 0 1.19vw 0
      font-size: 5.04vw
      line-height: 6.0vw
      letter-spacing: 0.24vw
    p
      font-size: 1.44vw
      line-height: 1.92vw

  .footerDocuments
    ul
      li
        font-size: 0.89vw
        line-height: 1.2vw
        letter-spacing: 0.07vw
      &:nth-child(1)
        margin-right: 5.39vw

// mobile version
@media (max-width: $tablet)
  .footerBox
    margin: 12.8vw 4.27vw 39.33vw
    align-items: baseline
    .footerTitle
      display: inline-block
      margin-bottom: 22.4vw
      .title
        margin-bottom: 1.07vw
        .h2
          font-size: 9.6vw
          line-height: 11.47vw
          letter-spacing: 0.53vw
        a
          margin-right: 5.80vw
          img
            width: 8.53vw
            height: 8.53vw
  .footerMenu
    margin-left: 0
    .boxMenuMobile
      margin-bottom: 12.8vw
      .menuGetInTouchMobile
        li
          color: $gray3
          span
            color: $main-white
            margin-left: 4.27vw
  .footerFeedback
    display: flex
    flex-direction: column-reverse
    .footerForm
      width: 89.49vw
      height: 21vw
      label
        &:nth-child(1)
          display: none
        a
          margin-left: 2.13vw
          font-size: 3.73vw
          line-height: 5.33vw
          letter-spacing: 0.13vw
    .footerContacts
      display: flex
      flex-direction: column-reverse
      margin-bottom: 0
      .footerLinks
        display: block
      p
        font-size: 4.8vw
        line-height: 6.67vw
        letter-spacing: 0.01em
        margin-bottom: 23.47vw
        text-align: left
      .h2
        font-weight: 700
        font-size: 5.2vw
        line-height: 6.13vw
        letter-spacing: 0.27vw
        margin-bottom: 16vw
  .footerDocuments
    padding-top: 45.7vw
    ul
      li
        font-size: 3.2vw
        line-height: 4.27vw
        letter-spacing: 0.27vw
        &:nth-child(1)
          margin: 0
          margin-right: 19.2vw
