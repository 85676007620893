@import ../../../scss/variables

// desktop version  
.page404
  position: relative
  text-align: center
  margin: 0 auto
  .box404
    padding: 9.53vw 0 10.05vw
    .img404
      width: 78.85vw
      height: 28.9vw
  .btn404
    display: flex
    justify-content: center

// tablet version  
@media (max-width: $desktop)
  .page404
    .box404
      padding: 25.51vw 0 11.99vw
      .img404
        width: 73.92vw
        height: 27.09vw

// mobile version 
@media (max-width: $tablet)
  .page404
    margin: 0
    .box404
      padding: 32vw 0
      .img404
        width: 97.3vw
        height: 40vw
