@import scss/variables

// desktop version  
.preloader
  text-align: center
  display: flex
  justify-content: center
  align-items: center
  min-height: 19vh
  margin-left: -9.5vw
  margin-top: -0.3vw
  .ring
    position: absolute
    width: 10.42vw
    height: 10.42vw
    border-radius: 50%
    animation: ring 3s linear infinite
    @keyframes ring
      0%
        transform: rotate(0deg)
        box-shadow: 1px 6px 3px $main-blue
      50%
        transform: rotate(180deg)
        box-shadow: 1px 6px 3px $main-blue
      100%
        transform: rotate(360deg)
        box-shadow: 1px 6px 3px $main-blue

    &::before
      position: absolute
      content: ""
      left: 0
      top: 0
      height: 100%
      width: 100%
      border-radius: 50%
      box-shadow: 0 0 5px rgba(255,255,255,.3)
  span
    font-weight: 900
    font-size: 1.04vw
    line-height: 1.04vw
    text-align: center
    letter-spacing: 0.05vw
    text-transform: uppercase
    font-family: $montserrat
    color: $main-blue
    z-index: 1
    animation: text 1s ease-in-out infinite
    @keyframes text
      50%
        color: $main-white

// tablet version
@media (max-width: $desktop)
  .preloader
    margin-left: 4vw
    .ring
      width: 14.87vw
      height: 14.87vw
    span
      font-size: 1.44vw

@media (max-width: $tablet)
  .preloader
    margin-left: 10vw
    .ring
      width: 33.07vw
      height: 33.07vw
    span
      font-size: 3.33vw
