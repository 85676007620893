@import scss/variables

// desktop version  
.modalBox
  position: fixed
  overflow-y: scroll
  height: 100vh
  width: 100%
  z-index: 10
  top: 0
  background-color: $main-black
  padding: 3.85vw 3.85vw 10.21vw 10.52vw
  transform: translateX(100%)
  visibility: hidden
  transition: all ease .3s
  background-image: url('/images/imgs/modals/bgError.png'),url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: left bottom, center center
  background-size: 47.29vw 45.32vw,34.89vw 34.89vw
  .buttonClose
    background: none
    border: none
    cursor: pointer
    margin-left: 82.60vw
    rect
      transition: all ease .2s
      width: 2px
      height: 80px
    svg
      width: 3.02vw
      height: 3.07vw
      &:hover
        rect
          fill: $main-blue
  .modalTitle
    .h1
      color: $main-white
      margin: 0
      font-family: $montserrat
      font-weight: 900
      font-size: 5.73vw
      line-height: 5.73vw
      margin: 12.68vw 0 0 11.35vw
      width: 64.79vw
      span
        text-transform: uppercase
        color: $main-black
        text-shadow: $borderTextWhite
  .modalBody
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.46vw
    line-height: 1.77vw
    letter-spacing: 0.05vw
    color: $gray4
    margin: 0
    margin-left: 11.35vw

  .modalBlock
    display: flex

.modalActive
  visibility: visible
  transform: translateX(0)

// tablet version
@media (max-width: $desktop)
  .modalBox
    padding: 7.79vw 5.75vw 0 5.75vw
    background-position: left bottom, right bottom
    background-size: 78.62vw 80.3vw, 84.89vw 84.89vw
    .buttonClose
      svg
        width: 4.56vw
        height: 4.56vw
    .modalTitle
      .h1
        font-size: 7.67vw
        line-height: 7.67vw
        letter-spacing: 0.72vw
        margin: 11.51vw 0 2.88vw
        width: 86.79vw
    .modalBody
      font-size: 3.36vw
      line-height: 4.07vw
      letter-spacing: 0.12vw
      margin: 0

// mobile version
@media (max-width: $tablet)
  .modalBox
    padding: 6.4vw 4.27vw 0
    background-size: 88.53vw 84.2vw, 94.89vw 94.89vw
    background-position: left bottom 14vw, right bottom
    .buttonClose
      margin-left: 41.47vw
      svg
        width: 8.53vw
        height: 8.53vw
    .modalTitle
      .h1
        font-size: 9.6vw
        line-height: 9.6vw
        letter-spacing: 0.53vw
        margin: 23.47vw 0 12.8vw
    .modalBody
      font-size: 4.27vw
      line-height: 5.87vw
      letter-spacing: 0.27vw
