@import scss/variables

// desktop version
.field
  position: relative
  padding-bottom: 1.8vw

  textarea, input
    width: 100%
    border: none
    outline: none
    border-bottom: 0.05vw solid $gray3
    background: none
    font-size: 1.46vw
    font-weight: 300
    font-family: $jetBrains
    letter-spacing: 0.05vw
    line-height: 1.72vw
    color: $main-white

    &:hover
      border-bottom: 0.05vw solid $main-blue

    &::placeholder
      color: $gray3

    &:focus
      border-bottom: 0.05vw solid $main-blue
      &::placeholder
        color: transparent

  input
    padding: 0.52vw 0

  textarea
    height: 10.42vw
    padding: 0.12vw 0
    resize: none

.message
  display: flex
  font-size: 0.73vw
  height: 1.5vw
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  color: red
  align-items: center
  img
    width: 1.25vw
    height: 1.25vw
    margin-right: 0.83vw

.required
  input, textarea
    border-bottom: 0.05vw solid $gray3

  &::after
    content: "*"
    color: $main-blue
    position: absolute
    right: 0
    top: 0.7vw

.error
  input, textarea
    border-bottom: 0.05vw solid red

.file
  margin-top: 2.08vw
  display: flex
  flex-direction: column
  cursor: pointer
  font-family: $montserrat
  font-style: normal
  font-weight: 900
  font-size: 1.04vw
  text-transform: uppercase
  background-color: rgba(0, 0, 0, 0)
  color: $main-white
  transition: all ease .3s
  &:hover
    color: $main-blue
  img
    margin-right: 1.04vw

  input
    display: none

    &:hover
      color: $main-blue

  .selectedFile
    display: flex
    justify-content: space-between
    width: 20.83vw
    border: solid 1px $main-blue
    padding: 0.47vw
    margin-top: 1.15vw
    border-radius: 10px

    .selected
      text-transform: none
      color: $main-white
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw

    span
      white-space: nowrap
      text-overflow: ellipsis
      overflow: hidden
      max-width: 80%
      align-self: flex-end
      margin-bottom: 1.51vw

    button
      background: none
      border: none
      cursor: pointer
      padding: 0
      .resetIcon
        stroke: $gray3
        &:hover
          stroke: $main-blue

.checkbox
  display: flex
  align-items: center
  margin-top: 1.04vw

  input
    visibility: hidden
    width: 0
    height: 0
    margin: 0
    padding: 0

  input,label
    cursor: pointer
    display: inline-flex
    align-items: center
    user-select: none
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 0.73vw
    letter-spacing: 0.02vw
    color: $gray3
    margin: 0

  input+label::before
    content: ''
    display: inline-block
    width: 1.04vw
    height: 1.04vw
    flex-shrink: 0
    flex-grow: 0
    border: 0.05vw solid $gray3
    border-radius: 0.25em
    margin-right: 0.5em
    background-repeat: no-repeat
    background-position: center center

  input:checked+label::before
    border-color: $main-blue
    background-color: $main-blue
    background-image: url("/images/icons/mark.svg")
    background-size: 0.57vw 0.4vw

// tablet version
@media (max-width: $desktop)
  .field
    padding-bottom: 5.75vw

    textarea, input
      font-size: 3.36vw
      letter-spacing: 0.12vw
      line-height: 4.08vw

    textarea
      height: 23.98vw

  .message
    font-size: 2vw
    height: 3vw
    bottom: 2.3vw
    img
      width: 2.12vw
      height: 2.12vw
      margin-top: 0.2vw
  .file
    margin-top: 0
    font-size: 2.40vw
    line-height: 2.40vw
    letter-spacing: 0.12vw

    .selectedFile
      width: 39.54vw
      padding: 1.08vw
      margin-top: 2.64vw
      .selected
        font-size: 1.82vw
        line-height: 2.04vw
        align-self: flex-start

  .checkbox
    margin-top: 2.4vw
    input, label
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw
    input+label::before
      width: 2.88vw
      height: 2.88vw
    input:checked+label::before
      background-size: 1.32vw 0.91vw

// mobile version
@media (max-width: $tablet)
  .field
    padding-bottom: 12.8vw

    textarea, input
      font-size: 4.27vw
      letter-spacing: 0.27vw
      line-height: 5.87vw

    textarea
      height: 37.6vw

  .message
    font-size: 4vw
    height: 6vw
    bottom: 5.8vw
    img
      width: 4.3vw
      height: 4.3vw
      margin-right: 2.1vw

  .file
    font-size: 4.33vw
    line-height: 4.33vw
    letter-spacing: 0.27vw

    .selectedFile
      width: 72.61vw
      padding: 2.4vw
      margin-top: 5.87vw
      .selected
        font-size: 3.91vw
        line-height: 4.12vw

  .checkbox
    margin-top: 14.4vw
    input,label
      font-size: 3.73vw
      line-height: 5.33vw
      letter-spacing: 0.13vw
    input+label::before
      width: 8.53vw
      height: 8.53vw
    input:checked+label::before
      background-size: 3.94vw 2.71vw
      margin-right: 3.73vw
