@import ../../../scss/variables

// desktop version  
.caseBox
  position: relative
  height: 33.80vw
  margin: 0 0 2.60vw 0
  max-width: 62.97vw
  .caseMainBox
    display: flex
    margin: 0 auto 0 18.54vw
    .caseContent
      z-index: 3
      position: relative
      .caseList
        display: flex
        margin-top: 18.42vw
        gap: 1.35vw
        ul
          display: flex
          flex-direction: column
          gap: 1.35vw
          font-family: $jetBrains
          li
            font-size: 0.83vw
            font-weight: 300
            line-height: 1.15vw
            letter-spacing: 0.05vw
            color: $main-white
            span
              color: $gray4
        .caseListFirst
          max-width: 6.77vw
        .caseListSecond
          max-width: 3.65vw
    img
      position: absolute
      right: -31%
      cursor: pointer
      width: 46.93vw
      height: 33.8vw

.caseTitle
  color: $main-black
  transition: all ease .3s
  font-size: 3.33vw
  font-weight: 900
  line-height: 3.96vw
  letter-spacing: 0.16vw
  text-shadow: $borderTextWhite
  text-transform: uppercase
  position: absolute
  top: 17%
  svg
    position: absolute
    top: 17%
    margin-left: 0.95vw
    width: 2.92vw
    height: 2.92vw
    path
      transition: all ease .3s
      fill: transparent

.caseTitleHover
  color: $main-white
  transition: all ease .3s
  font-size: 3.33vw
  font-weight: 900
  line-height: 3.96vw
  letter-spacing: 0.16vw
  text-transform: uppercase
  position: absolute
  top: 17%
  svg
    position: absolute
    top: 17%
    margin-left: 0.95vw
    width: 2.92vw
    height: 2.92vw
    path
      transition: all ease .3s
      fill: $main-white

// tablet version
@media (max-width: $desktop)
  .caseBox
    height: 47.48vw
    margin: 0 0 3.51vw 0
    max-width: 73.44vw
    .caseMainBox
      margin: 0 auto 0 5.75vw
      .caseContent
        .caseList
          gap: 1.76vw
          margin-top: 24.36vw
          ul
            gap: 1.76vw
            li
              font-size: 1.2vw
              line-height: 1.68vw
              letter-spacing: 0.07vw
          .caseListFirst
            max-width: 7.77vw
      img
        width: 65.95vw
        height: 47.48vw
  .caseTitle
    font-size: 4.68vw
    line-height: 5.63vw
    letter-spacing: 0.22vw

// mobile version
@media (max-width: $tablet)
  .caseBox
    height: 66.1vw
    margin: 0 0 17.21vw 0
    max-width: 73.5vw
    .caseMainBox
      margin: 0 auto 0 4.27vw
      img
        width: 90.1vw
        height: 66.02vw
  .caseTitle
    font-size: 5.33vw
    line-height: 5.33vw
    letter-spacing: 0.1vw
    margin: 0
    margin-top: 13.93vw
    margin-left: 14.3vw
