@import ../../../../scss/variables

// desktop version  
.arrowBox
  .aboveText
    color: $main-white
    font-family: $jetBrains
    font-weight: 500
    font-size: 0.94vw
    line-height: 1.09vw
    &:hover
      color: $main-blue
  .underText
    color: $main-white
    font-weight: 900
    font-size: 1.04vw
    line-height: 1.04vw
    text-transform: uppercase
  .imageArrow
    img
      width: 10.62vw
      height: 0.83vw

// tablet version
@media (max-width: $desktop)
  .arrowBox
    .aboveText
      font-size: 1.68vw
      line-height: 2.04vw
    .underText
      font-size: 1.92vw
      line-height: 1.92vw
    .imageArrow
      img
        width: 19.78vw
        height: 1.91vw

// mobile version
@media (max-width: $tablet)
  .arrowBox
    .aboveText
      font-size: 3.47vw
      line-height: 4.53vw
    .underText
      font-size: 4vw
      line-height: 4vw
    .imageArrow
      img
        width: 42.13vw
        height: 4.25vw
