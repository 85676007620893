@import ../../../scss/variables

// desktop version
.recordBox
  .recordTitle
    display: flex
    justify-content: center
    align-items: center
    border-bottom: 0.03vw solid $gray3
    border-top: 0.03vw solid $gray3
    margin-bottom: 4.17vw
    .recordTitleText
      font-size: 3.75vw
      line-height: 3.75vw
      letter-spacing: 0.1vw
      text-transform: uppercase
      font-weight: 900
      color: $main-black
      text-shadow: $borderTextWhite
      margin: 0
      padding: 0.52vw 0
    span
      color: $main-white
      text-shadow: none
    .recordCasesBtn
      display: flex
      text-shadow: none
  .recordCasesBox
    .recordCasesContent
      padding-bottom: 6vw
      :global(.swiper-pagination-bullet)
        width: 0.42vw
        height: 0.42vw
      .processPaginationActive
        color: $main-blue
        background-color: $main-blue
        opacity: 1
        width: 0.63vw
        height: 0.63vw
      .slide
        padding-bottom: 2.5vw

// tablet version
@media (max-width: $desktop)
  .recordBox
    padding-bottom: 6.95vw
    .recordTitle
      margin-bottom: 5.16vw
      justify-content: stretch
      .recordTitleText
        font-size: 4.32vw
        line-height: 5.16vw
        letter-spacing: 0.24vw
        width: 45.93vw
        padding-left: 5.75vw
        margin-right: 13.19vw
    .recordCasesBox
      .recordCasesContent
        div
        :global(.swiper-pagination-bullet)
          width: 0.96vw
          height: 0.96vw
        .processPaginationActive
          width: 1.44vw
          height: 1.44vw
        .slide
          padding-bottom: 5.16vw

// mobile version
@media (max-width: $tablet)
  .recordBox
    padding-bottom: 53.33vw
    position: relative
    .recordTitle
      margin-bottom: 22.4vw
      .recordTitleText
        font-size: 9.07vw
        line-height: 10.93vw
        letter-spacing: 0.53vw
        width: 89.27vw
        padding-left: 4.27vw
        margin-right: 0
        text-shadow: $borderTextWhiteMobile
      .recordCasesBtn
        position: absolute
        bottom: 40vw
        left: 10.67vw
    .recordCasesBox
      .recordCasesContent
        div
        :global(.swiper-pagination-bullet)
          width: 2.13vw
          height: 2.13vw
        .processPaginationActive
          width: 3.2vw
          height: 3.2vw
        .slide
          padding-bottom: 8.53vw
