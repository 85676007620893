@import ../../scss/variables

// desktop version
.getTrial
  font-family: $jetBrains
  font-weight: 400
  font-size: 0.83vw
  letter-spacing: 0.05vw
  color: $main-blue
  padding: 0.42vw 0.83vw
  border: 1px solid $main-white
  border-radius: 20px
  background: $main-black
  position: fixed
  top: 2.5vw
  right: 2.5vw
  cursor: pointer
  z-index: 2
  &:hover
    border-color: $main-blue

.getTrialBlock
  font-family: $jetBrains
  font-weight: 400
  font-size: 0.83vw
  letter-spacing: 0.05vw
  color: $gray2
  padding: 0.42vw 0.83vw
  border: 1px solid $gray2
  border-radius: 20px
  background: $main-black
  position: fixed
  top: 2.5vw
  right: 2.5vw

// tablet version
@media (max-width: $desktop)
  .getTrial
    font-size: 1.92vw
    line-height: 1.92vw
    padding: 0.96vw 1.92vw
    top: 2.88vw
    right: 5.75vw
  .getTrialBlock
    font-size: 1.92vw
    line-height: 1.92vw
    padding: 0.96vw 1.92vw
    top: 2.88vw
    right: 5.75vw

// mobile version
@media (max-width: $tablet)
  .getTrial
    font-size: 4.27vw
    line-height: 4.27vw
    padding: 2.13vw 4.27vw
    top: 4.27vw
    right: 4.27vw
  .getTrialBlock
    font-size: 4.27vw
    line-height: 4.27vw
    padding: 2.13vw 4.27vw
    top: 4.27vw
    right: 4.27vw
