@import ../../../scss/variables

// desktop version  
.tasksBox
  margin: 5.68vw 0 14.58vw 0
  white-space: pre-line
  .tasksTitleBox
    display: flex
    width: 100%
    border-bottom: 0.05vw solid $gray3
    border-top: 0.05vw solid $gray3
    display: flex
    justify-content: flex-start
    margin-bottom: 5.68vw
    padding-left: 14.5%
    .tasksTitle
      text-transform: uppercase
      color: $main-white
      font-size: 4.69vw
      font-weight: 900
      line-height: 4.22vw
      text-align: center
      margin: 0
      span
        color: $main-black
        text-shadow: $borderTextWhite
  .tasksContentBox
    display: flex
    justify-content: center
    .tasksContent
      display: flex
      max-width: $maxW
      justify-content: space-between
      .tasksProblems
        display: flex
        flex-direction: column
        .problemsTitle
          font-size: 2.5vw
          font-weight: 900
          line-height: 2.71vw
          color: $main-black
          text-shadow: $borderTextWhite
          text-transform: uppercase
          margin: 0 16.67vw 1.25vw 0
        .problemsText
          font-weight: 300
          font-size: 1.46vw
          line-height: 1.72vw
          font-family: $jetBrains
          color: $gray5
          width: 16.04vw
      .tasksRemedies
        .remediesTitle
          font-size: 2.5vw
          font-weight: 900
          line-height: 2.71vw
          color: $main-black
          text-shadow: $borderTextWhite
          text-transform: uppercase
          margin: 0 0 5.21vw 0
        .tasksRemediesList
          display: flex
          flex-wrap: wrap
          gap: 5.21vw
          li
            display: flex
            flex-direction: column
            gap: 0.78vw
            font-family: $jetBrains
            font-size: 0.94vw
            font-weight: 200
            line-height: 1.30vw
            color: $main-white
            width: 14.58vw
            span
              color: $gray4

// tablet version
@media (max-width: $desktop)
  .tasksBox
    margin: 12.11vw 0 11.87vw
    .tasksTitleBox
      margin-bottom: 5.04vw
      padding-left: 5.75vw
      .tasksTitle
        font-size: 5.75vw
        line-height: 6.35vw
    .tasksContentBox
      .tasksContent
        max-width: 88.49vw
        .tasksProblems
          .problemsTitle
            font-size: 3.84vw
            line-height: 4.2vw
            margin: 0 10.67vw 2.01vw 0
          .problemsText
            font-size: 2.4vw
            line-height: 2.88vw
            width: 24.08vw
        .tasksRemedies
          .remediesTitle
            font-size: 3.84vw
            line-height: 4.2vw
            margin: 0 0 2.01vw 0
          .tasksRemediesList
            gap: 7.26vw
            li
              gap: 1.32vw
              font-size: 1.68vw
              line-height: 2.4vw
              width: 23.5vw

// mobile version
@media (max-width: $tablet)
  .tasksBox
    margin: 6.4vw 0 27.73vw
    .tasksTitleBox
      margin-bottom: 12.8vw
      padding-left: 4.27vw
      .tasksTitle
        display: flex
        flex-direction: column
        text-align: left
        font-size: 9.07vw
        line-height: 11.93vw
    .tasksContentBox
      .tasksContent
        flex-direction: column
        max-width: 91.47vw
        .tasksProblems
          .problemsTitle
            font-size: 5.33vw
            line-height: 5.33vw
            margin: 0
            margin-bottom: 6.4vw
          .problemsText
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
            width: 91.47vw
            margin-bottom: 12.8vw
        .tasksRemedies
          .remediesTitle
            font-size: 5.33vw
            line-height: 5.33vw
            margin: 0 0 6.4vw 0
          .tasksRemediesList
            flex-direction: column
            gap: 6.4vw
            li
              gap: 4.27vw
              font-size: 4.27vw
              line-height: 5.87vw
              letter-spacing: 0.27vw
              width: 91.47vw
