@import ../../../scss/variables

// desktop version  
.careerContainer
  margin: 8.54vw 9.02vw 0 17.84vw
  .careerMainContent
    display: flex
    flex-direction: column
    align-items: flex-end
    background-image: url('/images/imgs/bg.svg'), url('/images/imgs/CareerLogo.svg')
    background-repeat: no-repeat
    background-position: bottom 3.5vw center, top center
    padding-bottom: 16.67vw
    background-size: 34.89vw 34.89vw, 23.96vw 32.33vw
    h6
      margin: 8.33vw 12.86vw 0.42vw 0
    p
      font-size: 1.46vw
      font-weight: 300
      line-height: 1.77vw
      letter-spacing: 0.05vw
      font-family: $jetBrains
      color: $main-white
      max-width: 29.69vw
  .careerSubContent
    display: flex
    flex-direction: column
    .h2
      text-transform: uppercase
      padding-bottom: 4.27vw
      font-size: 4.69vw
      font-weight: 900
      line-height: 4.22vw
      letter-spacing: 0.31vw
      color: $main-white
      span
        color: $main-black
        text-shadow: $borderTextWhite
    .subContentList
      display: flex
      flex-wrap: wrap
  .careerJobsContent
    display: flex
    flex-direction: column
    margin: 6.25vw 0 12.08vw 0
    .h3
      text-transform: uppercase
      font-size: 3.75vw
      font-weight: 900
      line-height: 3.75vw
      letter-spacing: 0.10vw
      color: $main-white
      padding-bottom: 3.33vw
    .offers
      li
        border-bottom: 0.05vw solid $gray3
        margin-bottom: 2.5vw
        &:last-child
          border: none
        .jobOfferDescription
          display: flex
          p
            color: $gray3
            font-size: 1.04vw
            line-height: 1.25vw
            font-weight: 300
            letter-spacing: 0.05vw
            font-family: $jetBrains
            span
              margin-right: 0.83vw
    .careerForm
      padding-top: 15.63vw
      display: flex
      .h1
        font-family: $montserrat
        font-weight: 900
        font-size: 3.33vw
        line-height: 4.01vw
        letter-spacing: 0.16vw
        text-transform: uppercase
        color: $main-white
        margin: 0
        padding-top: 4.76vw
        padding-right: 4.59vw
        width: 24.47vw
        span
          font-size: 3.75vw
          line-height: 3.75vw
          letter-spacing: 0.10vw
          color: $main-black
          text-shadow: $borderTextWhite
      .h1Ru
        @extend .h1
        padding-top: 0

// tablet version
@media(max-width: $desktop)
  .careerContainer
    margin: 12.47vw 5.75vw 0
    .careerMainContent
      padding-bottom: 0
      margin-bottom: 9.71vw
      background-size: contain
      background-position: top center, top center
      h6
        margin: 7.43vw 9.83vw 0.95vw 0
      p
        font-size: 2.4vw
        line-height: 2.88vw
        letter-spacing: 0.12vw
        max-width: 31.42vw
    .careerSubContent
      padding-top: 8.63vw
      .h2
        margin: 0
        margin-right: 12.39vw
        padding-bottom: 8.81vw
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
        width: 37.97vw
    .careerJobsContent
      display: flex
      flex-direction: column
      margin: 9.59vw 0
      .h3
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
        padding-bottom: 7.67vw
        margin: 0
      .offers
        li
          margin-bottom: 5.75vw
          .jobOfferDescription
            p
              font-size: 2.4vw
              line-height: 2.88vw
              letter-spacing: 0.12vw
              span
                margin-right: 1.92vw

      .careerForm
        padding-top: 5.28vw
        flex-direction: column
        .h1
          padding: 0
          padding-bottom: 10.19vw
          width: 82.37vw
          font-size: 8.63vw
          line-height: 8.63vw
          letter-spacing: 0.24vw
          span
            font-size: 10.79vw
            line-height: 9.71vw
            letter-spacing: 0.72vw

// mobile version
@media(max-width: $tablet)
  .careerContainer
    margin: 8.39vw 4.27vw 0
    .careerMainContent
      padding-bottom: 0
      margin-bottom: 21.33vw
      h6
        margin: 21.33vw 2.13vw 12.8vw 0
      p
        font-weight: 400
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
        max-width: 91.47vw
    .careerSubContent
      padding-top: 6.4vw
      .h2
        margin: 0
        padding-bottom: 12.8vw
        font-size: 9.07vw
        line-height: 9.87vw
        letter-spacing: 0.27vw
        width: 57.6vw
      .subContentList
        margin-top: 0
        flex-direction: column
    .careerJobsContent
      margin: 6.4vw 0
      .h3
        font-size: 9.07vw
        line-height: 9.87vw
        letter-spacing: 0.27vw
        padding-bottom: 6.4vw
        margin: 0
      .offers
        li
          margin-bottom: 6.4vw
          .jobOfferDescription
            flex-direction: column
            p
              font-size: 4.27vw
              line-height: 5.87vw
              letter-spacing: 0.27vw
              padding-bottom: 2.13vw
              span
                display: none
      .careerForm
        padding-top: 6.4vw
        .h1
          padding-bottom: 12.8vw
          width: 58.67vw
          font-size: 9.07vw
          line-height: 10.93vw
          letter-spacing: 0.53vw
          span
            font-size: 12.8vw
            line-height: 13.8vw
            letter-spacing: 0.53vw
