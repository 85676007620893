@import ../../../scss/variables

// desktop version 
.updates
  padding: 7.76vw 11.5vw 0 18.54vw
  .title
    display: flex
    justify-content: space-between
    align-items: flex-end
    padding-bottom: 6.87vw
    .h2
      display: flex
      flex-direction: column
      font-family: $montserrat
      font-weight: 900
      font-size: 4.69vw
      line-height: 4.22vw
      letter-spacing: 0.31vw
      text-transform: uppercase
      color: $main-white
      margin: 0
      span
        color: $main-black
        text-shadow: $borderTextWhite
  .posts
    margin-bottom: 4.48vw
    padding-bottom: 4.47vw
    .postCard
      display: flex
      justify-content: space-between
      cursor: pointer
      &:hover
        .h3
          color: $gray3
        .postImgs
          transform: scale(1.07)
    .postsDate
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $gray4
      margin-bottom: 0.83vw
    .postsTime
      display: flex
      align-items: center
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $gray4
      .time
        margin-right: 0.42vw
        margin-left: 0.42vw
    .h3
      font-family: $montserrat
      font-style: normal
      font-weight: 900
      font-size: 2.5vw
      line-height: 2.76vw
      letter-spacing: 0.05vw
      text-transform: uppercase
      color: $main-white
      max-width: 35.94vw
      cursor: pointer
    .imgs
      .postImgs
        width: 28.02vw
        height: 14.69vw
        border-radius: 25px
        transition: 0.5s

// tablet version
@media (max-width: $desktop)
  .updates
    padding: 9.11vw 5.53vw 0 5.52vw
    .title
      padding-bottom: 8.63vw
      .h2
        font-size: 7.67vw
        line-height: 7.67vw
        letter-spacing: 0.19vw
    .posts
      margin-bottom: 10.55vw
      padding-bottom: 10.55vw
      .postsDate
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw
        margin-bottom: 1.92vw
      .postsTime
        font-size: 1.68vw
        line-height: 2.4vw
        letter-spacing: 0.06vw
        .time
          margin-right: 0.96vw
          margin-left: 0.96vw
      .h3
        font-size: 4.08vw
        line-height: 4.92vw
        letter-spacing: 0.24vw
        max-width: 42.8vw
      .imgs
        align-self: center
        .postImgs
          width: 43.21vw
          height: 22.65vw

// mobile version
@media (max-width: $tablet)
  .updates
    padding: 22.56vw 0 34.27vw 3.73vw
    position: relative
    .title
      flex-direction: column
      align-items: flex-start
      padding-bottom: 0
      .h2
        font-size: 9.07vw
        line-height: 10.93vw
        letter-spacing: 0.53vw
        padding-bottom: 12.8vw
      .allArticles
        position: absolute
        margin-left: -2.3vw
        bottom: 28.8vw
    .posts
      margin-bottom: 0
      padding-bottom: 21.07vw
      .postCard
        flex-direction: column
      .postsDate
        font-size: 3.73vw
        line-height: 5.33vw
        letter-spacing: 0.13vw
        margin-bottom: 4.27vw
      .postsTime
        font-size: 3.73vw
        line-height: 5.33vw
        letter-spacing: 0.13vw
        .time
          margin-right: 2.13vw
          margin-left: 2.13vw
      .h3
        font-size: 5.33vw
        line-height: 6.4vw
        letter-spacing: 0.53vw
        max-width: 91.47vw
      .imgs
        align-self: flex-start
        .postImgs
          width: 92.33vw
          height: 48.39vw
          border-radius: 15px
