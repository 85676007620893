@import ../../../scss/variables

// desktop version
.servicesSlogan
  font-size: 2.19vw
  font-family: $jetBrains
  font-weight: 100
  line-height: 2.60vw
  color: $main-white
  margin: 0

// tablet version
@media (max-width: $desktop)
  .servicesSlogan
    font-size: 2.4vw
    font-weight: 300
    line-height: 2.88vw

// mobile version
@media (max-width: $tablet)
  .servicesSlogan
    font-size: 5.33vw
    line-height: 6.4vw
    text-align: right
