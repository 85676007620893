@import ../../../../scss/variables

// desktop version
.button
  position: relative
  cursor: pointer
  text-transform: uppercase
  color: $main-white
  font-size: 1.04vw
  line-height: 110%
  font-weight: 900
  padding: 3.96vw 2.19vw
  transition: all ease .3s
  background: none
  border: 0.05vw solid $main-white
  border-radius: 100%
  width: 10.42vw
  height: 10.42vw
  z-index: 2
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  white-space: pre-line
  &::after
    position: absolute
    display: block
    content: url('/images/bigArrow.svg')
    top: 30%
    left: 92%
    width: 4.17vw
    height: 4.17vw
  &:hover
    color: $main-blue
    background-color: $main-white
  &:disabled
    color: $gray2
    background: none
    border: 0.05vw solid $gray2
    &::after
      content: url('/images/icons/bigArrowGray.svg')
      width: 4.17vw
      height: 4.17vw

// desktop version
.backBtn
  display: flex
  align-items: center
  color: #4EB5FF
  font-size: 1.04vw
  font-weight: 900
  line-height: 1.04vw
  cursor: pointer
  background-color: rgba(28,28,28,0)
  border: none
  &:hover
    color: $main-white
    .arrow
      path
        fill: $main-white

  .arrow
    width: 1.15vw
    height: 1.15vw
    transform: rotate(270deg)
    margin-right: 0.83vw

// desktop version
.arrowFooter
  color: $main-blue
  text-transform: uppercase
  font-size: 1.04vw
  font-weight: 900
  line-height: 1.04vw
  padding: 0
  position: absolute
  background: transparent
  border: none
  cursor: pointer
  right: 0
  &:hover
    color: $main-white
  &:disabled
    color: $gray2
    background: none
    path
      fill: currentColor
  svg
    margin-left: 0.83vw
    width: 1.25vw
    height: 1.25vw

// tablet version
@media (max-width: $desktop)
  .button
    font-size: 1.44vw
    padding: 6.71vw 2.03vw
    width: 14.87vw
    height: 14.87vw
    &::after
      width: 5.96vw
      height: 5.96vw
    &:disabled
      &::after
        width: 5.96vw
        height: 5.96vw

  .backBtn
    font-size: 2.40vw
    line-height: 2.40vw
    .arrow
      width: 2.64vw
      height: 2.64vw
      margin-right: 1.92vw

  .arrowFooter
    font-size: 2.4vw
    line-height: 2.4vw
    bottom: 0.4vw
    svg
      margin-left: 1.92vw
      width: 2.88vw
      height: 2.88vw

// mobile version
@media( max-width: $tablet  )
  .button
    font-size: 3.33vw
    padding: 14.93vw 6.53vw
    width: 33.07vw
    height: 33.07vw
    &::after
      width: 13.07vw
      height: 13.07vw
    &:disabled
      &::after
        width: 13.07vw
        height: 13.07vw

  .backBtn
    font-size: 5.33vw
    line-height: 5.33vw
    .arrow
      width: 5.87vw
      height: 5.87vw
      margin-right: 4.27vw

  .arrowFooter
    font-size: 5.33vw
    line-height: 5.87vw
    letter-spacing: 0.27vw
    right: 0vw
    svg
      margin-left: 4.27vw
      width: 6.4vw
      height: 6.4vw

.customClass
  margin-bottom: 600px
