@import ../../scss/variables

// desktop version
.post
  padding: 11.67vw 10.52vw 0 18.54vw
  .article
    width: 62.92vw
    padding-bottom: 5.2vw
    .postTitle
      font-family: $montserrat
      font-weight: 900
      font-size: 3.33vw
      line-height: 4.01vw
      letter-spacing: 0.16vw
      text-transform: uppercase
      color: $main-white
      margin: 0
      padding: 2.4vw 0 1.25vw
    .imgPost
      position: relative
      .postImgs
        width: 46.87vw
        padding-bottom: 2.71vw
      .sharesLinks
        position: absolute
        top: 83%
        right: 50%
        display: flex
        padding: 1.25vw 1.04vw
        border-radius: 1.04vw
        background-color: $main-blackDark
        margin-bottom: 2.5vw
        width: 15.78vw
        margin: 0 auto
        border: 1px solid $gray2
        p
          font-weight: 400
          font-size: 0.73vw
          font-family: $jetBrains
          line-height: 0.99vw
          color: $main-white
          margin: 0
          span
            margin: 0 0.63vw 0 0.42vw
            color: $gray4
        .sharesList
          display: flex
          gap: 1.25vw
          li
            border-bottom: 0.05vw solid transparent
            transition: all ease .3s
            color: $main-blue
            font-weight: 400
            font-size: 0.73vw
            font-family: $jetBrains
            line-height: 0.99vw
            &:hover
              border-bottom: 0.05vw solid $main-blue
    .date
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $gray4
      padding-bottom: 0.83vw
    .articleTime
      display: flex
      font-family: $jetBrains
      font-weight: 400
      font-size: 0.73vw
      line-height: 1.04vw
      letter-spacing: 0.03vw
      color: $gray4
      padding-bottom: 2.19vw
      .time
        margin-right: 0.42vw
        margin-left: 0.42vw
    .postContent
      color: $gray5
      font-family: $roboto
      font-weight: 300
      font-size: 1.04vw
      line-height: 1.46vw
      letter-spacing: 0.05vw
      max-width: 54.9vw
      h2
        font-family: $montserrat
        font-weight: 900
        font-size: 1.04vw
        line-height: 1.15vw
        letter-spacing: 0.05vw
        text-transform: uppercase
        color: $main-blue
        margin: 0
        padding: 1.25vw 0 0.42vw
      h3
        font-family: $roboto
        font-weight: 500
        font-size: 1.04vw
        line-height: 1.46vw
        letter-spacing: 0.05vw
        color: $main-white
      ul
        padding-top: 0.83vw
        padding-bottom: 1.25vw
        li
          padding-left: 0.83vw
          position: relative
          &::before
            content: '*'
            color: $main-blue
            position: absolute
            left: 0
            top: 0.05vw
      ol
        padding-left: 1.79vw
        li
          font-family: $roboto
          font-weight: 300
          font-size: 1.04vw
          line-height: 1.46vw
          letter-spacing: 0.05vw
          color: $gray5
          padding-bottom: 0.42vw
      li::marker
        color: $main-blue
      img
        max-height: 24.53vw
        max-width: 46.87vw
      figure
        margin: 0
        padding-bottom: 1.25vw
        padding-top: 0.72vw
        figcaption
          color: $gray5
          font-family: $roboto
          font-weight: 300
          font-size: 1.04vw
          line-height: 1.46vw
          letter-spacing: 0.05vw
      table
        color: $gray5
        font-family: $roboto
        font-weight: 300
        font-size: 1.04vw
        line-height: 1.46vw
        letter-spacing: 0.05vw
        border-collapse: collapse
        width: 46.87vw
        td
          border: 1px solid $gray5
          padding: 0.34vw 0.42vw

    .articleTags
      display: flex
      flex-flow: wrap
      padding-top: 2.5vw
      p
        font-family: $jetBrains
        font-weight: 500
        font-size: 0.63vw
        line-height: 0.83vw
        letter-spacing: 0.05vw
        color: $main-white
        background-color: $gray2
        margin-right: 0.83vw
        margin-bottom: 0.83vw
        border-radius: 5px
        padding: 0.34vw 0.42vw
        border: none
  .moreArticles
    .h2
      display: flex
      flex-direction: column
      font-family: $montserrat
      font-weight: 900
      font-size: 3.75vw
      line-height: 3.75vw
      letter-spacing: 0.1vw
      text-transform: uppercase
      color: $main-white
      span
        color: $main-black
        text-shadow: $borderTextWhite
    .posts
      margin-bottom: 4.66vw
      padding-bottom: 3.83vw
      .postCard
        display: flex
        justify-content: space-between
        cursor: pointer
        &:hover
          .h2
            color: $gray3
          .postImgs
            transform: scale(1.07)
      .postsDate
        font-family: $jetBrains
        font-weight: 400
        font-size: 0.73vw
        line-height: 1.04vw
        letter-spacing: 0.03vw
        color: $gray4
        margin-bottom: 0.83vw
      .postsTime
        display: flex
        align-items: center
        font-family: $jetBrains
        font-weight: 400
        font-size: 0.73vw
        line-height: 1.04vw
        letter-spacing: 0.03vw
        color: $gray4
        .time
          margin-right: 0.42vw
          margin-left: 0.42vw
      .h2
        font-family: $montserrat
        font-style: normal
        font-weight: 900
        font-size: 2.5vw
        line-height: 2.76vw
        letter-spacing: 0.05vw
        text-transform: uppercase
        color: $main-white
        max-width: 35.94vw
        cursor: pointer
      .imgs
        .postImgs
          width: 28.02vw
          height: 14.69vw
          border-radius: 25px
          transition: 0.5s
      .tags
        max-width: 42.71vw
        display: flex
        flex-flow: wrap
        .postTags
          font-family: $jetBrains
          font-weight: 400
          font-size: 0.73vw
          line-height: 1.04vw
          letter-spacing: 0.03vw
          color: $gray6
          background-color: $gray2
          margin-right: 0.83vw
          margin-bottom: 0.83vw
          border-radius: 5px
          padding: 0.26vw 0.42vw
          border: none
  .articlesButton
    margin-left: -7.18vw
    margin-top: -4.17vw

// tablet version
@media (max-width: $desktop)
  .post
    padding: 16.07vw 5.75vw 0
    .article
      width: 88.49vw
      padding-bottom: 0
      .postTitle
        font-size: 4.32vw
        line-height: 5.16vw
        letter-spacing: 0.19vw
        padding: 6.47vw 0 2.88vw
      .imgPost
        .postImgs
          width: 88.49vw
          padding-bottom: 8.99vw
        .sharesLinks
          top: 76%
          right: 30%
          padding: 2.4vw 2.88vw
          border-radius: 20px
          width: 36.99vw
          p
            font-size: 1.68vw
            line-height: 2.4vw
            span
              margin: 0 1.44vw 0 0.96vw
          .sharesList
            gap: 2.88vw
            li
              font-size: 1.68vw
              line-height: 2.4vw
      .postDate
        display: flex
        margin-bottom: 5.75vw
        .date
          font-size: 1.68vw
          line-height: 2.4vw
          letter-spacing: 0.06vw
          margin-right: 3.84vw
        .articleTime
          font-size: 1.68vw
          line-height: 2.4vw
          letter-spacing: 0.06vw
        .time
          margin-right: 0.96vw
          margin-left: 0.96vw
      .postContent
        font-size: 2.4vw
        line-height: 3.36vw
        letter-spacing: 0.12vw
        max-width: 88.49vw
        h2
          font-size: 2.4vw
          line-height: 2.4vw
          letter-spacing: 0.12vw
          padding: 2.88vw 0 0.96vw
        h3
          font-size: 2.4vw
          line-height: 3.36vw
          letter-spacing: 0.12vw
        ul
          padding-top: 1.92vw
          padding-bottom: 2.88vw
          li
            padding-left: 1.92vw
            &::before
              top: 0.2vw
        ol
          padding-left: 3.99vw
          li
            font-size: 2.4vw
            line-height: 3.36vw
            letter-spacing: 0.12vw
            padding-bottom: 0.96vw
        img
          max-height: 46.28vw
          max-width: 88.44vw
        figure
          padding-bottom: 2.88vw
          padding-top: 0.96vw
          figcaption
            font-size: 2.4vw
            line-height: 3.36vw
            letter-spacing: 0.12vw
        table
          font-size: 2.4vw
          line-height: 3.36vw
          letter-spacing: 0.12vw
          width: 88.49vw
          td
            padding: 0.68vw 0.84vw
      .articleTags
        padding-top: 5.75vw
        p
          font-size: 1.44vw
          line-height: 1.92vw
          letter-spacing: 0.12vw
          margin-right: 1.92vw
          margin-bottom: 1.92vw
          padding: 0.78vw 0.96vw
    .moreArticles
      padding-top: 16.79vw
      .h2
        font-size: 7.67vw
        line-height: 7.67vw
        letter-spacing: 0.19vw
      .posts
        margin-bottom: 8.87vw
        padding-bottom: 10.55vw
        .postsDate
          font-size: 1.68vw
          line-height: 2.4vw
          letter-spacing: 0.06vw
          margin-bottom: 1.92vw
        .postsTime
          font-size: 1.68vw
          line-height: 2.4vw
          letter-spacing: 0.06vw
          .time
            margin-right: 0.96vw
            margin-left: 0.96vw
        .h2
          font-size: 4.08vw
          line-height: 4.92vw
          letter-spacing: 0.24vw
          max-width: 42.81vw
        .imgs
          .postImgs
            width: 43.21vw
            height: 22.65vw
        .tags
          max-width: 42.8vw
          .postTags
            font-size: 1.68vw
            line-height: 2.4vw
            letter-spacing: 0.06vw
            margin-right: 1.92vw
            margin-bottom: 1.92vw
            padding: 0.54vw 0.96vw
    .articlesButton
      margin-top: 0

// mobile version
@media (max-width: $tablet)
  .post
    padding: 25.6vw 4.27vw 0
    .article
      width: 91.47vw
      .postTitle
        font-size: 6.4vw
        line-height: 6.93vw
        letter-spacing: 0.27vw
        padding: 10.93vw 0 4.27vw
      .imgPost
        margin-left: -4.27vw
        .postImgs
          width: 100vw
          padding-bottom: 19.2vw
        .sharesLinks
          top: 60%
          right: 7.5%
          padding: 5.33vw
          width: 76.87vw
          border-radius: 5.33vw
          p
            font-size: 3.2vw
            line-height: 4.27vw
            align-self: center
            span
              margin: 0 3.2vw 0 2.13vw
          .sharesList
            gap: 2.88vw
            li
              font-size: 4.8vw
              line-height: 6.67vw
      .postDate
        margin-bottom: 4.27vw
        .date
          font-size: 3.73vw
          line-height: 5.33vw
          letter-spacing: 0.13vw
          margin-right: 39.73vw
        .articleTime
          font-size: 3.73vw
          line-height: 5.33vw
          letter-spacing: 0.13vw
        .time
          margin-right: 2.13vw
          margin-left: 2.13vw
      .postContent
        font-size: 4.27vw
        line-height: 5.87vw
        letter-spacing: 0.27vw
        max-width: 91.47vw
        h2
          font-size: 5.33vw
          line-height: 5.33vw
          letter-spacing: 0.27vw
          padding: 6.4vw 0 2.13vw
        h3
          font-size: 5.33vw
          line-height: 6.4vw
          letter-spacing: 0.27vw
        ul
          padding-top: 4.27vw
          padding-bottom: 6.4vw
          li
            padding-left: 4.27vw
            &::before
              top: 0.5vw
        ol
          padding-left: 7.79vw
          li
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
            padding-bottom: 2.13vw
        img
          max-height: 47.87vw
          max-width: 91.47vw
        figure
          padding-bottom: 6.4vw
          padding-top: 2.13vw
          figcaption
            font-size: 4.27vw
            line-height: 5.87vw
            letter-spacing: 0.27vw
        table
          font-size: 4.27vw
          line-height: 5.87vw
          letter-spacing: 0.27vw
          width: 91.47vw
      .articleTags
        padding-top: 12.8vw
        p
          font-size: 3.2vw
          line-height: 4.27vw
          letter-spacing: 0.27vw
          margin-right: 4.27vw
          margin-bottom: 4.27vw
          padding: 1.73vw 2.13vw
    .moreArticles
      padding-top: 15.47vw
      .h2
        font-size: 9.07vw
        line-height: 10.93vw
        letter-spacing: 0.53vw
        margin: 0
        margin-bottom: 12.8vw
      .posts
        margin-bottom: 0
        padding-bottom: 21.33vw
        .postCard
          flex-direction: column
          .postsDate
            font-size: 3.73vw
            line-height: 5.33vw
            letter-spacing: 0.13vw
            margin-bottom: 4.27vw
          .postsTime
            font-size: 3.73vw
            line-height: 5.33vw
            letter-spacing: 0.13vw
            margin-bottom: 4.27vw
            .time
              margin-right: 2.13vw
              margin-left: 2.13vw
          .h2
            font-size: 6.4vw
            line-height: 7.73vw
            letter-spacing: 0.53vw
            max-width: 68vw
            margin-bottom: 6.13vw
          .imgs
            margin-bottom: 6.13vw
            .postImgs
              width: 68vw
              height: 35.98vw
              border-radius: 15px
        .tags
          max-width: 85vw
          .postTags
            font-size: 3.2vw
            line-height: 4.27vw
            letter-spacing: 0.27vw
            margin-right: 4.27vw
            margin-bottom: 4.27vw
            padding: 1.73vw 2.13vw
