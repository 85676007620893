@import ../../../scss/variables

// desktop version
.guideBox
  display: flex
  position: relative
  padding-top: 13.65vw
  flex-direction: column
  height: 56.25vw
  background-image: url('/images/services/BookBg.svg'), url('/images/imgs/bg.svg'), url('/images/services/FindOutBg.svg')
  background-repeat: no-repeat, no-repeat, repeat-x
  background-position: center
  background-size: 25.21vw 30.05vw, contain, contain
  .guideText
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    border-top: 0.05vw solid $main-white
    border-bottom: 0.05vw solid $main-white
    width: 100%
    h2
      text-transform: uppercase
      font-size: 5.73vw
      line-height: 5.73vw
      font-weight: 900
      letter-spacing: 0.31vw
      color: $main-blue
      margin: 0
      transition: all ease .2s
    svg
      width: 4.17vw
      height: 4.17vw
      margin-left: 1.25vw
      path
        transition: all ease .2s
    &:hover
      h2
        color: $main-white
      path
        fill: $main-blue
  p
    font-size: 2.13vw
    font-family: $jetBrains
    font-weight: 100
    line-height: 2.60vw
    width: 11.46vw
    color: $main-white
    margin: 0 0 3.54vw 24.43vw
.guideBoxRu
  @extend .guideBox
  background-image: url('/images/imgs/bg.svg'), url('/images/services/FindOutBgRu.svg')
  background-size: 25.21vw 30.05vw, contain, contain
  p
    width: 15.46vw
    margin-bottom: 5.54vw
  .guideText
    h2
      font-size: 5vw

// tablet version
@media (max-width: $desktop)
  .guideBox
    padding-top: 19.68vw
    height: 71.94vw
    background-size: 38.85vw 46.4vw, contain, contain
    p
      font-size: 2.4vw
      font-weight: 300
      line-height: 2.88vw
      width: 12.46vw
      margin: 0 0 4.68vw 21.43vw
    .guideText
      h2
        font-size: 5.87vw
        line-height: 5.87vw
        width: 57.28vw
        padding-left: 4.3vw
      svg
        width: 4.2vw
        height: 4.2vw
        margin-left: 1.32vw
        align-self: flex-end

// mobile version
@media (max-width: $tablet)
  .guideBox
    padding-top: 22.13vw
    height: 131.2vw
    background-size: 69.33vw 82.27vw, cover,250vw 91.2vw
    p
      font-size: 5.33vw
      line-height: 6.4vw
      width: 26.46vw
      margin: 0 0 17.4vw 4.27vw
    .guideText
      h2
        font-size: 9.87vw
        line-height: 9.87vw
        width: 91.28vw
        padding-left: 4.27vw
      svg
        width: 6.4vw
        height: 6.4vw
        margin-left: 1vw
        align-self: flex-end
