@import ../../../scss/variables

// desktop version  
.categories
  margin-bottom: 2.5vw
  .categoriesButton
    display: inline-block
    font-family: $jetBrains
    font-weight: 300
    font-size: 1.04vw
    line-height: 1.25vw
    letter-spacing: 0.05vw
    color: $main-white
    padding: 0.83vw 1.25vw
    border: 0.05vw solid $gray3
    border-radius: 50px
    margin-right: 0.83vw
    background-color: $main-black
    margin-bottom: 1.25vw
    cursor: pointer
    &:hover
      color: $main-black
      background-color: $main-white
  .choosenCategory
    color: $main-black
    background-color: $main-white

// tablet version
@media (max-width: $desktop)
  .categories
    margin-bottom: 2.88vw
    .categoriesButton
      font-size: 2.4vw
      line-height: 2.88vw
      letter-spacing: 0.12vw
      padding: 1.7vw 2vw
      margin-right: 2.88vw
      margin-bottom: 1.92vw

// mobile version
@media (max-width: $tablet)
  .categories
    margin-bottom: 6.4vw
    .categoriesButton
      font-weight: 400
      font-size: 3.73vw
      line-height: 5.33vw
      letter-spacing: 0.13vw
      padding: 2.13vw 4.33vw
      margin-right: 2.13vw
      margin-bottom: 4.27vw
