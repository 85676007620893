@import ../../scss/variables

// desktop version
.trial
  padding: 10.52vw 10.52vw 7.4vw 18.54vw
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-size: 34.9vw 34.9vw
  background-position: left bottom
  .h2
    font-family: $montserrat
    font-weight: 900
    font-size: 4.69vw
    line-height: 4.22vw
    letter-spacing: 0.31vw
    text-transform: uppercase
    color: $main-white
    display: flex
    flex-direction: column
    margin: 0
    margin-bottom: 6.09vw
    span
      color: $main-black
      text-shadow: $borderTextWhite
  .trialCards
    display: flex
    justify-content: space-between
    .trialPrices
      padding: 3.02vw 1.98vw 2.19vw 2.19vw
      border: 1px solid $main-blue
      border-radius: 50px
      width: 22.81vw
      height: fit-content
      background: $main-black
      position: relative
      .trialStar
        width: 4.26vw
        height: 4.46vw
        position: absolute
        top: -2.34vw
        right: 1.6vw
      .h3
        font-family: $montserrat
        font-weight: 900
        font-size: 2.5vw
        line-height: 2.76vw
        letter-spacing: 0.05vw
        text-transform: uppercase
        color: $main-blue
        padding-bottom: 0.83vw
        border-bottom: 1px solid $main-blue
        width: 18.44vw
        text-align: center
        margin: 0
        margin-bottom: 3.33vw
      p
        font-family: $jetBrains
        font-weight: 300
        font-size: 1.46vw
        line-height: 1.77vw
        letter-spacing: 0.05vw
        color: $main-white
        span
          color: $gray3
      .trialPeriod
        display: flex
        margin-bottom: 2.5vw
      img
        width: 1.25vw
        height: 1.25vw
        margin-right: 0.83vw
        align-self: flex-start
        margin-top: 0.23vw
      .trialResult
        display: flex
        margin-bottom: 0.83vw
      ul
        .point
          display: flex
          padding-left: 2.3vw
          font-family: $jetBrains
          font-weight: 300
          font-size: 1.04vw
          line-height: 1.25vw
          letter-spacing: 0.05vw
          white-space: pre-line
          span
            color: $main-blue
            margin-right: 0.42vw
          li
            color: $main-white
            padding-bottom: 0.83vw
            &::last-child
              padding-bottom: 0

// tablet version
@media (max-width: $desktop)
  .trial
    padding: 12.35vw 5.75vw 8.74vw
    background-size: 58.51vw 58.51vw
    background-position: left -18.4vw bottom -21.6vw
    .h2
      font-size: 5.75vw
      line-height: 5.75vw
      letter-spacing: 0.36vw
      margin-bottom: 7.95vw
      margin-left: 11.75vw
    .trialCards
      .trialPrices
        padding: 3.77vw 2.73vw 2.37vw
        border-radius: 27px
        width: 28.42vw
        .trialStar
          width: 5.56vw
          height: 5.56vw
          top: -2.86vw
          right: 2.5vw
          margin: 0
        .h3
          font-size: 3.12vw
          line-height: 3.48vw
          letter-spacing: 0.06vw
          padding-bottom: 1.04vw
          width: 23vw
          margin-bottom: 3.12vw
        p
          font-size: 1.8vw
          line-height: 2.16vw
          letter-spacing: 0.06vw
        .trialPeriod
          margin-bottom: 3.12vw
        img
          width: 1.56vw
          height: 1.56vw
          margin-right: 1.04vw
          padding-top: 0
        .trialResult
          margin-bottom: 1.09vw
        ul
          .point
            padding-left: 2.7vw
            font-size: 1.2vw
            line-height: 1.56vw
            letter-spacing: 0.06vw
            span
              margin-right: 0.52vw
            li
              padding-bottom: 1.04vw
              &::before
                margin-right: 0.52vw

// mobile version
@media (max-width: $tablet)
  .trial
    padding: 25.6vw 4.27vw 14.2vw
    background-size: 139.2vw 139.2vw
    background-position: left -78vw bottom -26.67vw
    .h2
      font-size: 11.2vw
      line-height: 11.2vw
      letter-spacing: 0.8vw
      margin: 0
      margin-bottom: 17.51vw
    .trialCards
      flex-direction: column
      .trialPrices
        padding: 12.11vw 9.48vw 8.48vw 8.77vw
        border-radius: 39px
        width: 91.47vw
        margin-bottom: 20.18vw
        .trialStar
          width: 17.87vw
          height: 17.87vw
          top: -9vw
          right: 9.29vw
        .h3
          font-size: 9.37vw
          line-height: 10.93vw
          letter-spacing: 0.21vw
          padding-bottom: 3.34vw
          width: 73.87vw
          margin-bottom: 10.02vw
        p
          font-size: 6.13vw
          line-height: 6.93vw
          letter-spacing: 0.21vw
        .trialPeriod
          margin-bottom: 10.02vw
        img
          width: 5.01vw
          height: 5.01vw
          margin-right: 3.34vw
          margin-top: 0.7vw
        .trialResult
          margin-bottom: 3.5vw
        ul
          .point
            padding-left: 8.69vw
            font-size: 4vw
            line-height: 5.07vw
            letter-spacing: 0.21vw
            span
              margin-right: 1.67vw
            li
              padding-bottom: 3.34vw
              &::before
                margin-right: 1.67vw
