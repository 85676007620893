@import ../../../scss/variables

// desktop version  
.reviewCardBox
  position: relative
  width: 100%
  .reviewCardContentBox
    width: $maxW
    margin: 4.95vw 0 0
    .reviewCardAuthor
      display: flex
      margin-bottom: 1.25vw
      .authorData
        display: flex
        flex-direction: column
        align-items: flex-start
        color: $main-white
        font-size: 0.73vw
        font-family: $jetBrains
        line-height: 0.99vw
        font-weight: 400
        span
          color: $gray4
    .reviewCustomer
      background-image: url('/images/cases/ReviewArrow.svg')
      background-repeat: no-repeat
      background-position: center
      background-size: 69.37vw 12.66vw
      .reviewCardText
        color: $main-white
        font-size: 1.46vw
        font-weight: 300
        line-height: 1.72vw
        font-family: $jetBrains
        text-align: left
        width: 46.87vw
        letter-spacing: 0.05vw
      .hideContent
        overflow: hidden
        height: 9.52vw
      .showContent
        overflow: visible
    .reviewButton
      display: flex
      margin-top: 2.81vw
      button
        background-color: rgba(28, 28, 28, 0)
        border: none
        font-family: $montserrat
        font-weight: 900
        font-size: 1.04vw
        line-height: 1.04vw
        letter-spacing: 0.05vw
        text-transform: uppercase
        color: $main-blue
        cursor: pointer
      svg
        align-self: center
        margin-left: 0.83vw
        width: 1.25vw
        height: 1.25vw
      path
        transition: all ease .3s
      &:hover
        button
          color: $main-white
        path
          fill: $main-white

// tablet version
@media (max-width: $desktop)
  .reviewCardBox
    .reviewCardContentBox
      width: 88.49vw
      margin: 7.67vw 0 16.3vw
      .reviewCardAuthor
        margin-bottom: 2.88vw
        .authorData
          font-size: 1.68vw
          line-height: 2.4vw
      .reviewCustomer
        background-size: 65.59vw 8.27vw
        .reviewCardText
          font-size: 2.39vw
          line-height: 2.64vw
          width: 78.9vw
          letter-spacing: 0.12vw
        .hideContent
          height: 15.67vw
      .reviewButton
        margin-top: 6.47vw
        button
          font-size: 2.4vw
          line-height: 2.4vw
          letter-spacing: 0.12vw
        svg
          margin-left: 1.92vw
          width: 2.88vw
          height: 2.88vw

// mobile version
@media (max-width: $tablet)
  .reviewCardBox
    .reviewCardContentBox
      width: 91.2vw
      margin: 17.07vw 0 38.93vw
      .reviewCardAuthor
        margin-bottom: 6.4vw
        .authorData
          font-size: 3.73vw
          line-height: 5.33vw
      .reviewCustomer
        background-size: 91.47vw 18.4vw
        .reviewCardText
          font-size: 4.27vw
          line-height: 6.4vw
          width: 91.2vw
          letter-spacing: 0.27vw
        .hideContent
          height: 48.83vw
      .reviewButton
        margin-top: 14.4vw
        button
          font-size: 5.33vw
          line-height: 5.33vw
          letter-spacing: 0.27vw
        svg
          margin-left: 4.27vw
          width: 6.4vw
          height: 6.4vw
