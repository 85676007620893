@import scss/variables

// desktop version  
.preloader
  text-align: right
  position: absolute
  right: 0
  span
    font-weight: 900
    font-size: 1.04vw
    line-height: 1.04vw
    text-align: center
    letter-spacing: 0.05vw
    text-transform: uppercase
    font-family: $montserrat
    color: $main-blue
    animation: text 1s ease-in-out infinite
    @keyframes text
      50%
        color: $main-white

// tablet version
@media (max-width: $desktop)
  .preloader
    bottom: 0.4vw
    span
      font-size: 2.4vw
      line-height: 2.4vw

// mobile version
@media (max-width: $tablet)
  .preloader
    span
      font-size: 5.33vw
      line-height: 5.33vw
