@import scss/variables

// desktop version
.jobOfferForm
  display: flex
  position: relative
  flex-direction: column
  width: 46.87vw
  z-index: 1
  padding-bottom: 7.65vw

  .jobOfferFormGroup
    display: flex
    align-items: flex-start
    justify-content: space-between
    margin-top: 0.9vw

    .textarea
      width: 30.68vw

  a
    color: $main-blue
    margin-left: 0.46vw

    &:hover
      text-decoration: underline

// tablet version 
@media (max-width: $desktop)
  .jobOfferForm
    width: 88.49vw
    padding-bottom: 0

    .jobOfferFormGroup
      margin-top: 0
      justify-content: flex-start

      .textarea
        width: 51.29vw

    a
      margin-left: 0.96vw

// mobile version  
@media (max-width: $tablet)
  .jobOfferForm
    width: 91.47vw
    padding-bottom: 68.87vw
    position: relative

    .jobOfferFormGroup
      display: inline-block

      .textarea
        width: 91.47vw
      .button
        position: absolute
        bottom: 24.56vw
        left: 23.47vw

    a
      margin-left: 2.13vw
