@import ../../../scss/variables

// desktop version
.casesIndustries
  display: flex
  flex-direction: column
  width: 100%
  overflow: hidden

.note
  display: flex
  margin-left: 14.45vw
  font-family: $jetBrains
  color: $gray4
  font-size: 0.83vw
  font-weight: 300
  line-height: 1vw
  letter-spacing: 0.05vw
  white-space: pre-line
  margin-top: -4.3vw
  span
    margin-right: 0.42vw

.pagination
  display: flex
  height: 22.47vw
  margin-top: 4.24vw
  margin-left: 19.7vw
  background-image: url('/images/imgs/circleBg.svg')
  background-repeat: no-repeat
  background-position: left 25.32vw center
  background-size: 11.87vw 14.37vw
  .paginationButtons
    display: flex
    padding-left: 18.45vw
    padding-top: 9.67vw
    align-items: flex-start
    .prevPageButton
      display: flex
      flex-direction: column
      background: none
      border: none
      cursor: pointer
      font-family: $jetBrains
      font-weight: 500
      font-size: 0.94vw
      line-height: 1.15vw
      padding-bottom: 0.82vw
      color: $main-white
      background-image: url("/images/icons/ServicesArrowPrev.svg")
      background-repeat: no-repeat
      background-size: 10.62vw
      background-position-y: bottom
      &:hover
        color: $main-blue
      &:disabled
        color: $gray2
        background-image: url("/images/icons/GrayArrowPrev.svg")
        background-repeat: no-repeat
        background-size: 10.62vw
        background-position-y: bottom
      span
        padding-left: 2.5vw
    .nextPageButton
      background: none
      border: none
      cursor: pointer
      font-family: $jetBrains
      font-weight: 500
      font-size: 0.94vw
      line-height: 1.15vw
      color: $main-white
      padding-right: 3.55vw
      padding-bottom: 0.82vw
      background-image: url("/images/icons/ServicesArrowNext.svg")
      background-repeat: no-repeat
      background-size: 10.62vw
      background-position-y: bottom
      margin-left: 4.47vw
      &:hover
        color: $main-blue
      &:disabled
        color: $gray2
        background-image: url("/images/icons/GrayArrowNext.svg")
        background-repeat: no-repeat
        background-size: 10.62vw
        background-position-y: bottom
      span
        padding: 0
        padding-right: 2.9vw
        margin-left: -0.18vw
  .pages
    display: flex
    margin-left: 12.27vw
    .currentPage
      font-family: $jetBrains
      font-weight: 300
      font-size: 1.04vw
      line-height: 1.25vw
      letter-spacing: 0.05vw
      color: $gray3
      padding: 0.47vw 1.77vw
      border: 1px solid $gray3
      border-radius: 30px
      margin-right: 0.94vw
      align-self: center
    .totalPages
      align-self: center
      font-family: $jetBrains
      font-weight: 300
      font-size: 1.04vw
      line-height: 1.25vw
      letter-spacing: 0.05vw
      color: $main-white

.free2moveOutcomesBox
  display: flex
  justify-content: center
  margin-bottom: 14.53vw
  .free2moveOutcomesBoxContent
    display: flex
    justify-content: flex-end
    max-width: 75.94vw
    flex-wrap: wrap
    .free2moveOutcomesTitle
      text-transform: uppercase
      color: $main-white
      font-weight: 900
      font-size: 2.5vw
      line-height: 2.71vw
      margin-right: 8.28vw

.reviewsBox
  max-width: $maxW
  margin: 5.57vw auto 6.25vw auto
  .reviewsContentBox
    display: flex
    flex-direction: column
    justify-content: center
    max-width: $maxW
    .reviewTitle
      text-shadow: $borderTextWhite
      color: $main-black
      font-size: 2.5vw
      line-height: 2.71vw
      letter-spacing: 0.05vw
      font-weight: 900
      text-transform: uppercase
      margin: 0
    .reviews
      p
        margin-bottom: 2.5vw
      .reviewDetails
        color: $gray4

.sharesLinks
  display: flex
  padding: 1.25vw 1.04vw
  border-radius: 1.04vw
  background-color: $main-blackDark
  margin-bottom: 2.5vw
  width: 15.78vw
  margin: 0 auto
  p
    font-weight: 400
    font-size: 0.73vw
    font-family: $jetBrains
    line-height: 0.99vw
    color: $main-white
    margin: 0
    span
      margin: 0 0.63vw 0 0.42vw
      color: $gray4
  .sharesList
    display: flex
    gap: 1.25vw
    li
      border-bottom: 0.05vw solid transparent
      transition: all ease .3s
      color: $main-blue
      font-weight: 400
      font-size: 0.73vw
      font-family: $jetBrains
      line-height: 0.99vw
      &:hover
        border-bottom: 0.05vw solid $main-blue
.sharesLinksRu
  @extend .sharesLinks
  width: 17.38vw

// tablet version
@media (max-width: $desktop)
  .note
    font-size: 1.92vw
    line-height: 2.3vw
    letter-spacing: 0.12vw
    margin-left: 5.75vw
    margin-top: -12.78vw
    span
      margin-right: 0.96vw

  .pagination
    flex-direction: column
    height: 39.8vw
    margin-top: 0
    background-position: center center
    background-size: 22.66vw 22.66vw
    padding-left: 6.95vw
    padding-right: 6.95vw
    margin-left: 0
    margin-bottom: 5.4vw
    .paginationButtons
      padding-left: 22.15vw
      padding-top: 18.17vw
      padding-bottom: 17.75vw
      .prevPageButton
        font-size: 1.68vw
        line-height: 2.04vw
        background-size: 19.66vw
        width: 19.66vw
        &:disabled
          background-size: 19.66vw
        span
          padding-left: 5.1vw
      .nextPageButton
        font-size: 1.68vw
        line-height: 2.04vw
        margin-left: 3.47vw
        background-size: 19.66vw
        width: 19.66vw
        &:disabled
          background-size: 19.66vw
        span
          padding: 0
          padding-right: 6.5vw
    .pages
      margin-left: 34.17vw
      .currentPage
        font-size: 1.68vw
        line-height: 2.04vw
        letter-spacing: 0.08vw
        padding: 0.78vw 2.76vw
        border-radius: 21px
        margin-right: 1.51vw
      .totalPages
        font-size: 1.68vw
        line-height: 2.04vw
        letter-spacing: 0.08vw

  .free2moveOutcomesBox
    margin: 0 5.75vw 0
    .free2moveOutcomesBoxContent
      max-width: 100vw
      .free2moveOutcomesTitle
        font-size: 5.75vw
        line-height: 6.35vw
        margin: 0
        margin-right: 14.02vw

  .reviewsBox
    max-width: 88.49vw
    margin: 7.79vw auto 0
    .reviewsContentBox
      max-width: 88.49vw
      .reviewTitle
        font-size: 5.75vw
        line-height: 6.35vw
        letter-spacing: 0.12vw
      .reviews
        p
          margin-bottom: 2.64vw

  .sharesLinks
    padding: 2.64vw 2.88vw
    border-radius: 2.4vw
    margin-bottom: 4.07vw
    width: 42.45vw
    p
      font-size: 1.68vw
      line-height: 2.4vw
      span
        margin: 0 1.44vw 0 0.96vw
    .sharesList
      gap: 2.88vw
      li
        font-size: 2.4vw
        line-height: 2.88vw

// mobile version
@media (max-width: $tablet)
  .note
    font-size: 4.27vw
    line-height: 5.12vw
    letter-spacing: 0.27vw
    margin-left: 4.27vw
    margin-top: -12.78vw
    span
      margin-right: 2.13vw

  .pagination
    height: 93.33vw
    background-size: 63.67vw 63.67vw
    // background-position: left 14.5vw center
    padding-right: 3.73vw
    padding-left: 3.73vw
    .paginationButtons
      justify-content: space-between
      padding-left: 0.32vw
      padding-top: 41.17vw
      padding-bottom: 38.13vw
      .prevPageButton
        font-size: 3.36vw
        line-height: 4vw
        background-size: 41.08vw
        width: 41.08vw
        padding-bottom: 2.9vw
        &:disabled
          background-size: 41.08vw
        span
          padding-left: 11.6vw
      .nextPageButton
        font-size: 3.36vw
        line-height: 4vw
        background-size: 41.08vw
        width: 41.08vw
        margin-left: 0
        padding-bottom: 2.9vw
        &:disabled
          background-size: 41.08vw
        span
          padding-right: 17vw
    .pages
      margin-left: 26.1vw
      .currentPage
        font-size: 3.73vw
        line-height: 4.48vw
        letter-spacing: 0.19vw
        padding: 1.73vw 6.13vw
        margin-right: 3.36vw
      .totalPages
        font-size: 3.73vw
        line-height: 4.48vw
        letter-spacing: 0.19vw

  .free2moveOutcomesBox
    margin: 0 4.27vw 0
    .free2moveOutcomesBoxContent
      justify-content: flex-start
      .free2moveOutcomesTitle
        font-size: 9.07vw
        line-height: 9.87vw
        margin: 0
        margin-bottom: 13.33vw

  .reviewsBox
    max-width: 91.47vw
    margin: 6.4vw auto 0
    .reviewsContentBox
      max-width: 91.47vw
      .reviewTitle
        font-size: 9.07vw
        line-height: 10.93vw
        letter-spacing: 0.53vw
      .reviews
        p
          margin-bottom: 6.4vw

  .sharesLinks
    padding: 5.33vw
    width: 76.87vw
    border-radius: 5.33vw
    p
      font-size: 3.2vw
      line-height: 4.27vw
      align-self: center
      span
        margin: 0 3.2vw 0 2.13vw
    .sharesList
      gap: 2.88vw
      li
        font-size: 4.8vw
        line-height: 6.67vw
