@import ../../../scss/variables

// desktop version
.scrollBox
  display: flex
  justify-content: center
  position: relative
  .scrollItems
    min-width: 41.15vw
    max-width: 51.15vw
    position: relative
    display: flex
    flex-direction: column
    text-transform: uppercase
    text-align: right
    font-weight: 900
    line-height: 4.22vw
    height: 17.98vw
    overflow: hidden
    &::-webkit-scrollbar
      width: 0
      height: 0
    ul
      margin-right: 1.25vw
      transition: all ease .3s
      li
        margin: 0 0 0.42vw 0
        color: $gray2
        transition: all ease .3s
        font-size: 4.69vw
        &:last-child
          margin-bottom: 12.5vw
    .shadow
      position: absolute
      top: 48%
      width: 55.57vw
      height: 22.92vw
  .scrollItemsRu
    @extend .scrollItems
    ul
      li
        font-size: 3.62vw
  .scrollText
    display: flex
    flex-direction: column
    h2
      text-transform: uppercase
      text-shadow: $borderTextWhite
      color: $main-black
      font-weight: 900
      line-height: 3.75vw
      max-width: 28.8vw
      margin: 0
      font-size: 4.69vw
    .scrollBtn
      width: fit-content
      margin: 7.29vw 0 0 7.81vw

// tablet version
@media (max-width: $desktop)
  .scrollBox
    .scrollItems
      max-width: 64.15vw
      line-height: 5.16vw
      height: 22.28vw
      ul
        margin-right: 2.62vw
        li
          margin: 0 0 0.72vw 0
          font-size: 5.75vw
      .shadow
        width: 89.57vw
        height: 42.56vw
        top: 34%
    .scrollText
      h2
        line-height: 5.16vw
        font-size: 5.75vw
      .scrollBtn
        margin-top: 13.09vw

// mobile version
@media (max-width: $tablet)
  .scrollBox
    flex-direction: column
    .scrollItems
      max-width: 74.15vw
      line-height: 6.4vw
      height: 27.58vw
      margin-left: 4.27vw
      ul
        margin-right: 0
        li
          margin: 0 0 0.8vw 0
          font-size: 7.2vw
      .shadow
        width: 83.57vw
        height: 55.56vw
        top: 14.5%
    .scrollText
      .scrollBtn
        margin-top: 19.09vw
        margin-left: 27.73vw
