@import scss/variables

// desktop version  
.feedBackForm
  display: flex
  position: relative
  flex-direction: column
  width: 46.87vw
  z-index: 1
  padding-bottom: 7.65vw

  .textarea
    width: 30.68vw
    max-width: 30.68vw

  a
    color: $main-blue
    margin-left: 0.46vw
    transition: all ease .2s
    font-family: $jetBrains
    font-weight: 400
    font-size: 0.73vw
    line-height: 0.73vw
    letter-spacing: 0.02vw

    &:hover
      text-decoration: underline

  .button
    padding-left: 27.08vw
    margin-top: 0.4vw
    z-index: -1

// tablet version
@media (max-width: $desktop)
  .feedBackForm
    width: 88.49vw
    padding-bottom: 11.99vw

    .textarea
      width: 51.2vw
      max-width: 51.2vw

    a
      margin-left: 0.96vw
      font-size: 1.68vw
      line-height: 2.4vw
      letter-spacing: 0.06vw

    .button
      padding-left: 68.98vw
      margin-top: -2.04vw

// mobile version  
@media (max-width: $tablet)
  .feedBackForm
    width: 91.47vw
    padding-bottom: 45.87vw
    position: relative

    .textarea
      width: 91.47vw
      max-width: 91.47vw

    a
      margin-left: 2.13vw
      font-size: 3.73vw
      line-height: 5.33vw
      letter-spacing: 0.13vw

    .button
      position: absolute
      padding: 0
      bottom: 1.56vw
      left: 23.47vw
