@import ../../scss/variables

.mobileNav
  display: flex
  position: fixed
  bottom: 0
  width: 100%
  z-index: 100
  background-color: $main-blackDark
  justify-content: space-between
  padding: 4vw 5.73vw
  button
    padding: 0
    margin: 0 
    background: none
    border: none
    color: $gray5
    font-family: $montserrat
    font-size: 2.66vw
    img
      height: 8.53vw
      width: 8.53vw
    span
      display: block
    &.selected
      color: $main-blue
  ul
    position: absolute
    bottom: 100%
    display: flex
    left: 0
    padding: 4vw 0
    justify-content: space-around
    width: 100%
    background-color: $main-blackDark
    border-bottom: 1px solid $main-blue
