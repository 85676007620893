@import ../../../scss/variables

// tablet version
.banner
  display: flex
  background-color: #FE3C87
  background-image: url('/images/icons/Switch.svg')
  background-repeat: no-repeat
  background-size: 13.51vw 6.52vw
  background-position: center center
  margin-bottom: 8.75vw
  white-space: pre-line
  .bannerAdvt
    display: flex
    .bannerText
      font-family: $jetBrains
      font-size: 2.16vw
      font-weight: 600
      line-height: 2.37vw
      letter-spacing: 0.1vw
      color: $main-blackDark
      margin: 4.41vw 5.03vw 3.96vw 17.14vw
    .bannerTitle
      font-family: $montserrat
      font-size: 4.2vw
      font-weight: 800
      line-height: 4.62vw
      letter-spacing: 0.14vw
      text-transform: uppercase
      color: $main-blackDark
      margin: 5.09vw 11.47vw 3.11vw 0
  .bannerSubTitle
    font-family: $montserrat
    color: $main-white
    font-size: 1.92vw
    font-weight: 800
    line-height: 2.11vw
    letter-spacing: 0.1vw
    text-transform: uppercase
    margin: 3.6vw 0 3.2vw 0
    display: flex
    img
      width: 3.47vw
      height: 3.47vw
      margin-left: 1.12vw
      align-self: flex-end
      margin-bottom: -0.9vw

// mobile version
@media (max-width: $tablet)
  .banner
    background-size: 30.05vw 13.98vw
    margin-bottom: 13.07vw
    background-position: left 22.13vw top 11.47vw
    .bannerAdvt
      flex-direction: column
      margin: 5.07vw 26.13vw 4.8vw 4.27vw
      .bannerText
        font-size: 4.27vw
        line-height: 4.69vw
        letter-spacing: 0.22vw
        margin: 0
      .bannerTitle
        font-size: 9.33vw
        line-height: 10.27vw
        letter-spacing: 0.32vw
        margin: 0
    .bannerSubTitle
      font-size: 4.27vw
      line-height: 4.69vw
      letter-spacing: 0.22vw
      margin: 9.63vw 0 6.17vw 0
      img
        width: 7.47vw
        height: 7.17vw
        margin-left: 1.07vw
        margin-bottom: -1.7vw
