@import ../../../scss/variables

// desktop version  
.mainCaseBox
  display: flex
  margin: 18.87vw 10.88vw 13.02vw 10.52vw
  position: relative
  background-image: url('/images/imgs/bg.svg')
  background-repeat: no-repeat
  background-position: left
  background-size: 44.27vw
  .mainCaseContent
    display: flex
    flex-direction: column
    .h1
      font-weight: 900
      font-size: 8.33vw
      line-height: 7.5vw
      color: $main-blue
      text-transform: uppercase
      position: relative
      z-index: 2
  .mainCaseImg
    position: absolute
    right: 20%
    top: -20%
    img
      width: 43.75vw
      height: 31.25vw
  .mainCaseReq
    display: flex
    gap: 7.58vw
    align-items: flex-end
    margin: 13.54vw 0 0 3.81vw
    .h2
      color: $main-black
      text-shadow: $borderTextWhite
      font-size: 2.5vw
      font-weight: 900
      line-height: 2.71vw
      letter-spacing: 0.05vw
      text-transform: uppercase
      margin: 0
    .reqNotes
      display: flex
      gap: 7.58vw
      p
        color: $main-white
        font-family: $jetBrains
        font-size: 1.04vw
        line-height: 1.25vw
        letter-spacing: 0.05vw
        font-weight: 300
        width: 28.81vw
      .extension
        width: 10.89vw

.mainCaseBtn
  position: absolute
  right: 3%
  bottom: 36%

.mainCaseBack
  position: absolute
  top: -28%
  left: 4.5%
.nextCaseArr
  position: absolute
  right: 0
  top: -20%
.prevCaseArr
  position: absolute
  left: 0
  bottom: 36.4%

// tablet version
@media (max-width: $desktop)
  .mainCaseBox
    margin: 33.09vw 5.75vw 21.46vw 5.99vw
    background-size: 47vw
    .mainCaseContent
      .h1
        font-size: 8.63vw
        line-height: 7.79vw
        margin: 0
      .mainCaseImg
        right: 25%
        top: -34%
        img
          width: 45.44vw
          height: 32.37vw
      .mainCaseReq
        flex-direction: column
        align-items: flex-start
        gap: 5.75vw
        margin: 20.62vw 0 0 0
        .h2
          font-size: 4.31vw
          line-height: 5.16vw
          letter-spacing: 0.24vw
        .reqNotes
          p
            font-size: 2.4vw
            line-height: 2.88vw
            letter-spacing: 0.12vw
            width: 41.97vw

  .mainCaseBtn
    right: 4%
    bottom: 50%

  .mainCaseBack
    top: -52%
    left: 0

// mobile version
@media (max-width: $tablet)
  .mainCaseBox
    margin: 51.2vw 4.27vw 18.13vw
    background-size: 115.73vw
    background-position: center top
    .mainCaseContent
      .h1
        font-size: 11.73vw
        line-height: 10.67vw
      .mainCaseImg
        right: 0
        top: -16.5%
        img
          width: 68.53vw
          height: 49.07vw
      .mainCaseReq
        flex-direction: column
        align-items: flex-start
        margin: 78.13vw 0 0 0
        .h2
          font-size: 5.33vw
          line-height: 5.87vw
          letter-spacing: 0.27vw
        .reqNotes
          flex-direction: column
          p
            font-size: 4.27vw
            line-height: 5.07vw
            letter-spacing: 0.27vw
            width: 91.47vw

  .mainCaseBtn
    right: 34%
    bottom: 38%

  .mainCaseBack
    top: -27%
